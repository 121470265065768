import { useContext } from 'react';
import { AudioContext } from '../../context/AudioProvider/AudioProvider';
import { AudioContextType } from '../../context/AudioProvider/AudioProviderType';

export interface UseParticipantsResult {
  participants: AudioContextType['participants'];
  participantsStatus: AudioContextType['participantsStatus'];
  addIsSpeakingListener: AudioContextType['addIsSpeakingListener'];
  kickParticipantHandler: AudioContextType['kickParticipantHandler'];
}

const useParticipants = (): UseParticipantsResult => {
  const {
    participants,
    participantsStatus,
    addIsSpeakingListener,
    kickParticipantHandler,
  } = useContext(AudioContext);

  return {
    participants,
    participantsStatus,
    kickParticipantHandler,
    addIsSpeakingListener,
  };
};

export default useParticipants;
