import EventEmitter from 'eventemitter3';
import { v4 as uuidv4 } from 'uuid';
import NetworkObject from './NetworkObject';
import { NetworkId } from './types';

export type SyncVariableEventTypes = {
  changed: (payload: { variable: SyncVariable<any> }) => void;
};

export type SyncVariableSerialized<T> = {
  type: string;
  value: T | null;
  uuid: string;
  name: string;
  netObjectUuid: string;
  interpolated: boolean;
  writerId: NetworkId;
};

export enum SyncVariableUpdateStatus {
  Default = 'Default',
  NeedUpdateFromLocal = 'NeedUpdateFromLocal',
  NeedUpdateFromNetwork = 'NeedUpdateFromNetwork',
}

export class SyncVariable<T = any> {
  public uuid = uuidv4();

  public netObjectUuid = '';

  public autoSync = false;

  public required = false;

  public needUpdateStatus = SyncVariableUpdateStatus.Default;

  public writerId: NetworkId = '';

  public value: T | null = null;

  public sendTime = 0;

  public receiveTime = 0;

  public name: string;

  public locked = false;

  public interpolated = false;

  public static type = 'any';

  protected _events: EventEmitter<SyncVariableEventTypes> = new EventEmitter<SyncVariableEventTypes>();

  constructor(name: string) {
    this.name = name;
  }

  public reset() {
    // this.value = null;
  }

  public lock() {
    this.locked = true;
  }

  public unlock() {
    this.locked = false;
  }

  public get events(): EventEmitter<SyncVariableEventTypes> {
    return this._events;
  }

  public setOwnerNetObject(netObject: NetworkObject) {
    this.netObjectUuid = netObject.uuid;
  }

  public set(value: T, force = false): boolean {
    if (this.locked) return false;
    const isChanged = this.isChanged(this.value, value);
    if (isChanged || force) {
      this.value = value;
      this.events.emit('changed', { variable: this });
      return true;
    }
    return false;
  }

  public isChanged(prevValue: T | null, newValue: T) {
    const prevHash = prevValue ? this.getValueHash(prevValue) : '';
    const newHash = this.getValueHash(newValue);
    return prevHash !== newHash;
  }

  public getValueHash(value: T): string {
    return JSON.stringify(value);
  }

  public serialize(): SyncVariableSerialized<T> {
    return {
      type: (this.constructor as any).type,
      value: this.value,
      uuid: this.uuid,
      name: this.name,
      writerId: this.writerId,
      interpolated: this.interpolated,
      netObjectUuid: this.netObjectUuid,
    };
  }

  saveValueFromNetwork(data: SyncVariableSerialized<T>, sendTime: number, receiveTime: number) {
    this.deserialize(data);
    this.sendTime = sendTime;
    this.receiveTime = receiveTime;
  }

  public deserialize(data: SyncVariableSerialized<T>) {
    this.uuid = data.uuid;
    this.writerId = data.writerId;
    this.name = data.name;
    this.netObjectUuid = data.netObjectUuid;
    this.interpolated = data.interpolated;
    this.deserializeValue(data.value);
  }

  public deserializeValue(value: T | null) {
    this.value = value;
  }

  public setNeedUpdateFromLocal() {
    this.needUpdateStatus = SyncVariableUpdateStatus.NeedUpdateFromLocal;
  }

  public setNeedUpdateFromNetwork() {
    this.needUpdateStatus = SyncVariableUpdateStatus.NeedUpdateFromNetwork;
  }

  public resetNeedUpdate() {
    this.needUpdateStatus = SyncVariableUpdateStatus.Default;
  }

  // then local changed --> update variable == send state to network
  // use events or override method
  public updateFromLocal(): boolean {
    // need implement in child class
    return true;
  }

  // then receive changed from network -> get variable state & update local values
  // use events or override method
  public updateFromNetwork(): boolean {
    // need implement in child class
    return true;
  }
}
