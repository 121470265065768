export type VideoSourceType = {
  mp4: string;
  ogg: string;
};

export default class VideoController {
  videoElement: HTMLVideoElement | null = null;

  public createElement(appendTo: HTMLElement = document.body): HTMLVideoElement {
    this.videoElement = document.createElement('video');

    this.videoElement.autoplay = false;
    this.videoElement.controls = false;
    this.videoElement.muted = true;
    this.videoElement.loop = true;
    this.videoElement.style.display = 'none';
    appendTo.appendChild(this.videoElement);
    return this.videoElement;
  }

  public isPlaying(): boolean {
    if (!this.videoElement) return false;
    return this.videoElement.currentTime > 0
      && !this.videoElement.paused && !this.videoElement.ended && this.videoElement.readyState > 2;
  }

  public toggle(isPlaying: boolean) {
    if (!this.videoElement) return Promise.resolve();
    if (isPlaying) return this.videoElement.play();
    if (!isPlaying) {
      this.videoElement.pause();
      return Promise.resolve();
    }
  }

  public get time(): number {
    if (!this.videoElement) return 0;
    return this.videoElement.currentTime;
  }

  public setTime(time: number) {
    if (!this.videoElement) return 0;
    this.videoElement.currentTime = time;
  }

  public setSource({ mp4, ogg }: VideoSourceType) {
    if (!this.videoElement) return;

    this.videoElement.pause();
    this.videoElement.currentTime = 0;

    if (this.videoElement.canPlayType('video/mp4')) {
      this.videoElement.src = mp4;
    } else if (this.videoElement.canPlayType('video/ogg')) {
      this.videoElement.src = ogg;
    }

    return this.update();
  }

  public update() {
    if (!this.videoElement) return;
    if (this.isPlaying()) this.videoElement.pause();
    return this.videoElement.play().then(
      () => {
        this.videoElement?.pause();
      },
    );
  }
}
