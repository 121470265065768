import { Vector3, Object3D, Clock } from 'three';
import { VRMHumanBoneName } from '@pixiv/three-vrm';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';
import { AnimationComponent } from './Animation.component';
import { MeshRendererComponent } from '../../engine/components/MeshRenderer.component';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';
import StuffVariable from '../network/variables/StuffVariable';
import { Entity } from '../../engine/Entity';
import CharacterObject from '../network/objects/CharacterObject';
import { Application } from '../../engine/Application';
import { MainScene } from '../scenes/MainScene';
import StuffObject from '../network/objects/StuffObject';

export type StuffComponentOptions = ComponentOptions & {
  data?: {
    position?: Vector3;
    type?: StuffType;
    attachOffset?: Vector3;
    takeDistance?: number;
  };
};

export enum StuffStatus {
  Waiting = 'Waiting',
  Taken = 'Taken',
  Dropped = 'Dropped',
}

export enum StuffType {
  None = 'None',
  Racket = 'Racket',
  Fansticse = 'Fansticse',
  Finger = 'Finger',
}

export type StuffState = {
  activeUserId?: string;
  status: StuffStatus;
};

export class StuffComponent extends EngineComponent {
  public state: StuffState = { activeUserId: '', status: StuffStatus.Waiting };

  public type: StuffType = StuffType.None;

  public initialized = false;

  public attached = false;

  public handObjects: Object3D[] = [];

  public static objectNamesByType = {
    [StuffType.None]: [],
    [StuffType.Racket]: ['racket_right'],
    [StuffType.Finger]: ['finger_right'],
    [StuffType.Fansticse]: ['fan_right', 'fan_left'],
  };

  protected clock = new Clock();

  public lastMovingTime = 0;

  public lastPosition: Vector3 = new Vector3();

  public attachToObjectRight: Object3D | null = null;

  public attachToObjectLeft: Object3D | null = null;

  public attachOffset: Vector3 = new Vector3(0, 0, 0);

  public originParent: Object3D | null = null;

  public takeDistance = 5;

  public distanceForGrip = 2;

  static get code(): string {
    return 'stuff';
  }

  constructor(options: StuffComponentOptions) {
    super(options);
    const position = options.data?.position;
    if (position) {
      this.setupPosition(position);
    }
    this.type = options.data?.type || this.type;
    this.entity.getComponentOrFail(MeshRendererComponent).events.once('contentAdded', () => {
      this.updateAnimation();
    });
    this.attachOffset = options.data?.attachOffset || this.attachOffset;
    this.takeDistance = options.data?.takeDistance || this.takeDistance;
  }

  public setupTargets(app: Application) {
    this.attachToObjectRight = null;
    this.attachToObjectLeft = null;
    const targetEntity = app.componentManager.getComponentsByType(NetworkObjectComponent)
      .find((netComponent) => {
        return netComponent?.netObject instanceof CharacterObject && netComponent?.netObject?.ownerId === this.state.activeUserId;
      })?.entity || (app.sceneManager.currentScene as MainScene).characterEntity;
    if (!targetEntity) return;
    const avatarEntity = targetEntity.children[0] as Entity;
    const vrm = avatarEntity ? avatarEntity
      .getComponent(MeshRendererComponent)?.getVRM() : null;
    if (vrm) {
      this.attachToObjectRight = vrm.humanoid?.getBoneNode(VRMHumanBoneName.RightHand) || null;
      this.attachToObjectLeft = vrm.humanoid?.getBoneNode(VRMHumanBoneName.LeftHand) || null;
    }
  }

  getHandObjects() {
    if (this.handObjects.length > 0) return this.handObjects;
    const scene = this.entity.app.sceneManager.currentThreeScene;
    if (!scene) return this.handObjects;
    this.handObjects = StuffComponent.objectNamesByType[this.type].map((name) => {
      return scene.getObjectByName(name)?.clone();
    }).filter((obj) => typeof obj !== 'undefined') as Object3D[];
    return this.handObjects;
  }

  public updateAnimation() {
    const animation = this.entity.getComponent(AnimationComponent);
    if (animation) {
      if (this.state.status === StuffStatus.Waiting) {
        animation.playAction('bounce');
      } else {
        animation.stopAction('bounce');
      }
    }
  }

  public setupPosition(position: Vector3): void {
    this.entity.position.copy(position);
  }

  public setState(state: StuffState, broadcast = true) {
    this.changeStatus(state.status);
    this.state = { ...state };
    if (broadcast) this.variable?.updateFromLocal();
  }

  public changeStatus(status: StuffStatus) {
    this.state.status = status;
    this.updateAnimation();
    this.updateTiming();
  }

  public updateTiming() {
    this.lastMovingTime = 0;
    if (this.state.status === StuffStatus.Dropped) {
      this.clock.start();
    } else {
      this.clock.stop();
    }
  }

  public isMoving(position: Vector3): boolean {
    const timeOnAir = this.clock.getElapsedTime();
    if (timeOnAir < 1) return true;
    if (timeOnAir > 10) return false;
    if ((timeOnAir - this.lastMovingTime) < 0.2) return true;
    this.lastMovingTime = timeOnAir;
    const result = this.lastPosition.distanceTo(position) > 0.0001;
    this.lastPosition.copy(position);
    return result;
  }

  public get variable(): StuffVariable | undefined {
    return this.netObject?.getVariableByName('stuff') as StuffVariable;
  }

  public get netObject(): StuffObject | undefined | null {
    return this.entity.getComponent(NetworkObjectComponent)?.netObject as StuffObject;
  }

  // public reset() {
  //   // this.updatedStatusInFrame = false;
  // }

  public isActive() {
    return this.state.status !== StuffStatus.Waiting;
  }

  public isWaiting() {
    return this.state.status === StuffStatus.Waiting;
  }

  public destroy() {

  }
}
