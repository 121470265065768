import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const LogoutIcon: FC<IconsType> = memo((props) => {
  const {
    width = 16,
    height = 16,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2H5.33333C4.22876 2 3.33333 2.89543 3.33333 4V12C3.33333 13.1046 4.22876 14 5.33333 14H10"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 8.00065L10 5.33398M12.6667 8.00065L10 10.6673M12.6667 8.00065H6"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default LogoutIcon;
