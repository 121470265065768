import * as Three from 'three';
import IKJoint from '../IKJoint';
import IKChain from '../IKChain';
import IKBaseConstraint from './IKBaseConstraint';

export type AxisConfig = {
  targetAxis: string;
  multiplier?: number;
  addDegrees?: number;
  parentMaxDegrees?: number;
  parentMinDegrees?: number;
};

export type AxesType = {
  xAxis?: AxisConfig;
  yAxis?: AxisConfig;
  zAxis?: AxisConfig;
};

export type IKTargetRotationConstraintOptions = {
  axes?: AxesType;
};

type Coords = 'x' | 'y' | 'z';

const nameToCoordsMap = {
  xAxis: 'x',
  yAxis: 'y',
  zAxis: 'z',
};

export default class IKTargetRotationConstraint extends IKBaseConstraint {
  public axesConfig: AxesType;

  constructor(options: IKTargetRotationConstraintOptions) {
    super();
    this.axesConfig = options.axes || {};
  }

  applyLazy(joint: IKJoint, chain: IKChain): boolean {
    if (!this.axesConfig) return false;
    let target = chain.target?.parent;
    // For debug
    if (target instanceof Three.Scene) target = chain.target;
    if (!target) return false;
    const { rotation } = joint.bone;
    Object.keys(this.axesConfig).forEach((axisName) => {
      const axisConfig = this.axesConfig[axisName as keyof AxesType];
      if (!axisConfig || !target) return;
      const axis: Coords = nameToCoordsMap[axisName as keyof AxesType] as Coords;
      const targetAxis: Coords = axisConfig.targetAxis as Coords;
      const multiplier = axisConfig.multiplier || 1;
      const deg = axisConfig.addDegrees || 0;

      let value = multiplier * target.rotation[targetAxis] + Three.MathUtils.degToRad(deg);
      value = this.limitByParent(
        value,
        joint.bone.parent,
        axisConfig.parentMaxDegrees || 0,
        axisConfig.parentMinDegrees || 0,
      );
      rotation[axis] = value;
    });
    joint.bone.updateMatrix();
    return true;
  }

  protected limitByParent(value: number, parent: Three.Object3D | null, max: number, min: number): number {
    if (!parent) return value;
    if ((Three.MathUtils.radToDeg(value) - parent.rotation.x) >= max) {
      return parent.rotation.x + Three.MathUtils.degToRad(max);
    }
    if ((Three.MathUtils.radToDeg(value) + parent.rotation.x) <= min) {
      return parent.rotation.x + Three.MathUtils.degToRad(min);
    }
    return value;
  }
}
