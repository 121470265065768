import { Object3D } from 'three/src/core/Object3D';
import * as THREE from 'three';
import * as ThreeVRM from '@pixiv/three-vrm';
import { VRMHumanBoneName } from '@pixiv/three-vrm';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import NetworkTransformComponent, {
  NetworkTransformComponentTypes,
} from '../../../engine/components/NetworkTransform.component';
import { Application } from '../../../engine/Application';
import NetworkManager from '../../../engine/network/NetworkManager';
import TransformVariable, { TransformVariableType } from '../../../engine/network/variables/TransformVariable';
import { Entity } from '../../../engine/Entity';
import SystemObject from '../../../engine/network/SystemObject';
import { AssetSourceType, MeshRendererComponent } from '../../../engine/components/MeshRenderer.component';
import {
  AnimationFilterOptions,
  AnimationFilterType,
  AnimatorComponent,
} from '../../../engine/components/Animator.component';
import { TPControllerComponent } from '../../components/TPController.component';
import { FPControllerComponent } from '../../components/FPController.component';
import { XRFPControllerComponent } from '../../components/XRFPController.component';
import { ColliderComponent, ColliderType } from '../../../engine/components/Collider.component';
import { PlayerControlsComponent } from '../../components/PlayerControls.component';
import {
  RigidBodyActivationState,
  RigidBodyComponent,
  RigidBodyType,
} from '../../../engine/components/RigidBody.component';
import CollisionFilters from '../../constants/collisionFilters';
import { PrimitiveComponent } from '../../../engine/components/Primitive.component';
import { DashboardSystem } from '../../systems/Dashboard.system/Dashboard.system';
import RaycastComponent from '../../components/Raycast.component';
import { DashboardComponent } from '../../components/Dashboard.component';
import AnimatorVariable, { AnimatorVariableType } from '../../../engine/network/variables/AnimatorVariable';
import NetworkAnimatorComponent, {
  NetworkAnimatorComponentTypes,
} from '../../../engine/components/NetworkAnimator.component';
import { SeatComponent } from '../../components/Seat.component';
import { NetworkPlayerComponent } from '../../components/NetworkPlayer.component';
import VrmIKComponent from '../../components/VrmIKComponent';
import AvatarComponent from '../../components/Avatar.component';
import { fixSkeletonAndMeshes } from '../../services/IKSystem/AxisUtils';
import { defaultIKConfig } from '../../services/VrmIK/DefaultVrmConfig';
import NetworkIKComponent, { NetworkIKComponentTypes } from '../../components/NetworkIK.component';
import IKVariable, { IKVariableValue } from '../variables/IKVariable';
import { NetworkObjectSerialized } from '../../../engine/network/NetworkObject';
import { GenderEnum } from '../../../enum/GenderEnum';

export type CharacterObjectSerialized = NetworkObjectSerialized & {
  url: string;
  gender: GenderEnum;
};

export default class CharacterObject extends SystemObject {
  public static type = 'character';

  public gender = GenderEnum.FEMALE;

  static register(manager: NetworkManager) {
    manager.objectsTypes[CharacterObject.type] = CharacterObject;
  }

  static buildNetworkObject(app: Application): CharacterObject | null {
    if (!app.networkManager) {
      return null;
    }
    const netObj = app.networkManager?.buildNetObject<CharacterObject>(CharacterObject.type);
    netObj.app = app;
    netObj.addVariables();
    return netObj;
  }

  public addVariables() {
    this.addVariable<TransformVariableType>(new TransformVariable());
    this.addVariable<AnimatorVariableType>(new AnimatorVariable());
    this.addVariable<IKVariableValue>(new IKVariable());
  }

  public get component(): NetworkObjectComponent | undefined {
    return this.app?.componentManager.getComponentsByType(NetworkObjectComponent).find((component) => {
      return component.netObject === this;
    });
  }

  public changeGender(gender: GenderEnum) {
    this.gender = gender;
    const { component } = this;
    if (!component) return;
    const avatarEntity = component.entity.children[0] as Entity;
    const animator = avatarEntity.getComponentOrFail(AnimatorComponent);
    animator.isReady = false;
    const meshComponent = avatarEntity.getComponentOrFail(MeshRendererComponent);
    meshComponent.clear();
    animator.cleanAnimation();
    animator.initAnimationSource().then(() => {
      meshComponent.changeSource({
        url: `avatars/VRM_model_FINAL/${this.gender}.vrm`,
        type: AssetSourceType.VRM,
      });
    });
    meshComponent.events.once('contentAdded', () => {
      avatarEntity.getComponentOrFail(VrmIKComponent).setVrm(meshComponent.getVRM());
    });
  }

  public attachToEntity(
    entity: Entity,
    avatarEntity: Entity,
    transformType = NetworkTransformComponentTypes.Source,
    animatorType = NetworkAnimatorComponentTypes.Source,
    ikType: NetworkIKComponentTypes = NetworkIKComponentTypes.Source,
  ) {
    entity.addComponent(NetworkObjectComponent, {
      netObject: this,
      // special fo reconnect
      removeTimeout: 2500,
    });
    entity.addComponent(NetworkTransformComponent, {
      variableName: 'transform',
      type: transformType,
    });
    entity.addComponent(NetworkAnimatorComponent, {
      variableName: 'animator',
      type: animatorType,
    });
    avatarEntity.addComponent(NetworkIKComponent, {
      variableName: 'ik',
      type: ikType,
    });
  }

  static createCharacterEntity(app: Application) {
    const characterEntity = app.entityManager.makeEntity();
    characterEntity.rotation.order = 'YXZ';
    characterEntity.position.set(-13, 0.95, -2);
    characterEntity.name = 'CharacterEntity';
    return characterEntity;
  }

  static _prepareSkeleton(app: Application, vrm: ThreeVRM.VRM, IKSystem: string): Record<string, THREE.Quaternion> {
    if (IKSystem !== 'system') return {};
    if (!vrm.humanoid) return {};

    const leftArmName = vrm.humanoid.getBoneNode(VRMHumanBoneName.LeftUpperArm)?.name || 'Left_arm';
    const rightArmName = vrm.humanoid.getBoneNode(VRMHumanBoneName.RightUpperArm)?.name || 'Right_arm';
    const skeletonRotations = {
      [leftArmName]: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI / 2),
      [rightArmName]: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2),
    };

    const rootBone = vrm.humanoid?.getBoneNode(VRMHumanBoneName.Hips)?.parent;
    if (rootBone) {
      fixSkeletonAndMeshes(rootBone as THREE.Bone, app.sceneManager.currentThreeScene, {
        preRotations: skeletonRotations,
      });
    }
    return skeletonRotations;
  }

  static buildAvatarEntity(app: Application, gender: string, needFirstPersonSetup = true) {
    const avatarEntity = app.entityManager.makeEntity();

    avatarEntity.addComponent(AvatarComponent);

    const meshComponent = avatarEntity.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.VRM,
        url: `avatars/VRM_model_FINAL/${gender}.vrm`,
      },
      needFirstPersonSetup,
    });

    let IKComponent: VrmIKComponent;
    meshComponent.events.once('contentAdded', () => {
      const vrm = meshComponent.getVRM();
      if (!vrm || !vrm.humanoid) return new Error('VRM not loaded properly');

      // TODO: choose system
      const ikConfig = { ...defaultIKConfig };
      const ikSystem = ikConfig.system;
      const bones: THREE.Bone[] = [];
      ikConfig.ikSimpleChainConfigs.forEach((chain) => chain.jointConfigs.forEach((joint) => {
        const bone = vrm.humanoid?.getBoneNode(joint.boneName);
        if (bone) bones.push(bone as THREE.Bone);
      }));

      const skeletonRotations: Record<string, THREE.Quaternion> = this._prepareSkeleton(app, vrm, ikSystem);

      IKComponent = avatarEntity.addComponent(VrmIKComponent, { vrm, ikConfig });
      IKComponent.disable();

      const animationFilters: AnimationFilterOptions[] = [{
        name: 'armRotation',
        type: AnimationFilterType.Rotation,
        enabled: true,
        rotation: skeletonRotations,
      }];
      animationFilters.push({
        name: 'ik',
        type: AnimationFilterType.Bones,
        bones, // IKComponent.getAllBones(),
        enabled: false,
        bindings: {
          enabled: 'ikFilterEnabled',
        },
      });

      const animator = avatarEntity.addComponent(AnimatorComponent, {
        initialActionName: 'idle',
        animationSources: [
          { url: 'avatars/Animations/walk.fbx', clipName: 'walk' },
          { url: 'avatars/Animations/idle.fbx', clipName: 'idle' },
          { url: 'avatars/Animations/back.fbx', clipName: 'back' },
          { url: 'avatars/Animations/left.fbx', clipName: 'left' },
          { url: 'avatars/Animations/right.fbx', clipName: 'right' },
          { url: 'avatars/Animations/seated.fbx', clipName: 'seated' },
          { url: 'avatars/Animations/cheer.fbx', clipName: 'cheer' },
        ],
        animationFilters,
        actions: [
          {
            name: 'walk',
            filters: ['ik', 'armRotation'],
            clipsData: [
              {
                name: 'walk',
                clipName: 'walk',
                bindings: {
                  activeWeight: 'forwardWeight',
                  speedMultiplier: 'speed',
                },
              },
              {
                name: 'back',
                clipName: 'back',
                resizeTo: 0.9666666388511658,
                startAt: 0.96 / 2,
                bindings: {
                  activeWeight: 'backwardWeight',
                  speedMultiplier: 'speed',
                },
              },
              {
                name: 'left',
                clipName: 'left',
                resizeTo: 0.9666666388511658,
                bindings: {
                  activeWeight: 'leftStrafeWeight',
                  speedMultiplier: 'speed',
                },
              },
              {
                name: 'right',
                clipName: 'right',
                resizeTo: 0.9666666388511658,
                startAt: 0.96 / 2,
                bindings: {
                  activeWeight: 'rightStrafeWeight',
                  speedMultiplier: 'speed',
                },
              },
              {
                name: 'leftBack',
                clipName: 'left',
                resizeTo: 0.9666666388511658,
                startAt: 0.96 / 2,
                bindings: {
                  activeWeight: 'leftBackStrafeWeight',
                  speedMultiplier: 'backStrafeSpeed',
                },
              },
              {
                name: 'rightBack',
                clipName: 'right',
                resizeTo: 0.9666666388511658,
                bindings: {
                  activeWeight: 'rightBackStrafeWeight',
                  speedMultiplier: 'backStrafeSpeed',
                },
              },
            ],
          },
          {
            name: 'idle',
            filters: ['ik', 'armRotation'],
            clipsData: [{
              name: 'idle',
              clipName: 'idle',
            }],
          },
          {
            name: 'seated',
            filters: ['ik', 'armRotation'],
            clipsData: [{
              name: 'seated',
              clipName: 'seated',
              bindings: {
                activeWeight: 'seatedWeight',
              },
            }],
          },
          {
            name: 'cheer',
            filters: ['ik', 'armRotation'],
            clipsData: [{
              resizeTo: 3.6,
              startAt: 0.1,
              name: 'cheer',
              clipName: 'cheer',
              bindings: {
                activeWeight: 'cheerWeight',
              },
            }],
          },
        ],
        parameters: {
          speed: 1,
          backStrafeSpeed: 1,
          forwardWeight: 1,
          backwardWeight: 1,
          leftStrafeWeight: 1,
          rightStrafeWeight: 1,
          leftBackStrafeWeight: 1,
          rightBackStrafeWeight: 1,
          seatedWeight: 1,
          cheerWeight: 1,
          ikFilterEnabled: false,
        },
      });
      // animator.enabled = false;
    });

    avatarEntity.position.y = -1;

    return avatarEntity;
  }

  static buildEntity(app: Application, characterEntity: Entity, cameraEntity: Entity) {
    const avatarEntity = CharacterObject.buildAvatarEntity(app, GenderEnum.FEMALE, true);
    const tpControllerComponent = characterEntity.addComponent(TPControllerComponent);
    tpControllerComponent.cameraEntity = cameraEntity;
    tpControllerComponent.avatarEntity = avatarEntity;

    tpControllerComponent.lookAtEntity = app.entityManager.makeEntity();
    tpControllerComponent.lookAtEntity.position.y = 0.72;
    tpControllerComponent.lookAtEntity.position.z = -0.05;

    const fpControllerComponent = characterEntity.addComponent(FPControllerComponent);
    fpControllerComponent.cameraEntity = cameraEntity;
    fpControllerComponent.avatarEntity = avatarEntity;
    fpControllerComponent.enabled = false;

    const xRFPControllerComponent = characterEntity.addComponent(XRFPControllerComponent);
    xRFPControllerComponent.cameraEntity = cameraEntity;
    xRFPControllerComponent.avatarEntity = avatarEntity;
    characterEntity.addComponent(PlayerControlsComponent);
    characterEntity.addComponent(SeatComponent);
    characterEntity.add(avatarEntity);
    // characterEntity.addComponent(GenderComponent);
    characterEntity.add(tpControllerComponent.lookAtEntity);
    characterEntity.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.Capsule,
        radius: 0.3,
        height: 1.3,
      },
    });
    characterEntity.addComponent(RigidBodyComponent, {
      type: RigidBodyType.Dynamic,
      mass: 1,
      activationState: RigidBodyActivationState.AlwaysActive,
      mask: CollisionFilters.StaticFilter,
      group: CollisionFilters.CharacterFilter,
    });

    // add network
    const netObject = CharacterObject.buildNetworkObject(app);
    if (netObject) {
      netObject.isNeedSpawn = false;
      netObject.attachToEntity(characterEntity, avatarEntity);
    }
  }

  public spawnEntity(): Object3D | undefined {
    if (!this.app) return;
    if (!this.getVariableByName('transform')) return;
    const entity = this.app.entityManager.makeEntity();
    const avatarEntity = CharacterObject.buildAvatarEntity(this.app, this.gender, false);
    entity.add(avatarEntity);
    const primitiveComponent = entity.addComponent(PrimitiveComponent);
    entity.addComponent(RaycastComponent, { target: primitiveComponent.data });
    entity.addComponent(DashboardComponent);
    entity.addComponent(NetworkPlayerComponent);
    // entity.addComponent(GenderComponent);
    const dashboardEntity = this.app.entityManager.makeEntity();
    const dashboardSystem = this.app.getSystem(DashboardSystem);
    if (dashboardSystem) {
      this.app.getSystemOrFail(DashboardSystem).setupUIEntity(dashboardEntity, 0.001);
      dashboardEntity.position.y = 1.1;
      dashboardEntity.rotateX(-Math.PI / 6);
    }
    entity.add(dashboardEntity);

    // FIXME: T-pose to idle visible
    entity.visible = false;
    setTimeout(() => {
      entity.visible = true;
    }, 1000);

    this.attachToEntity(
      entity,
      avatarEntity,
      NetworkTransformComponentTypes.Target,
      NetworkAnimatorComponentTypes.Target,
      NetworkIKComponentTypes.Target,
    );

    entity.position.set(-13, 0.95, -2);

    return entity;
  }

  public serialize(): CharacterObjectSerialized {
    const data = super.serialize() as CharacterObjectSerialized;
    data.gender = this.gender;
    return data;
  }

  public deserialize(data: CharacterObjectSerialized) {
    super.deserialize(data);
    if (this.gender !== data.gender) this.changeGender(data.gender);
    return this;
  }
}
