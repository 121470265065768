import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import FontJSON from 'three-mesh-ui/examples/assets/Roboto-msdf.json';
import FontImage from 'three-mesh-ui/examples/assets/Roboto-msdf.png';
import { PlayerControlsComponent } from '../../domain/components/PlayerControls.component';
import { PlayerControlsSystem } from '../../domain/systems/PlayerControls.system';

const APP = window.web3devApp;

class PressToESC {
  constructor(scene, camera) {
    this.scene = scene;
    this.camera = camera;

    this.containerGlobal = undefined;
  }

  create(text) {
    if (this.containerGlobal != undefined) {
      this.terminate(true, text);
      return;
    }

    const dist = 4;
    const cwd = new THREE.Vector3();
    this.camera.getWorldDirection(cwd);
    cwd.multiplyScalar(dist);
    cwd.add(this.camera.position);

    const container = new ThreeMeshUI.Block({
      height: 0.4,
      width: 2.8,
      justifyContent: 'center',
      contentDirection: 'row',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.25,
      padding: 0.02,
      borderRadius: 0.11,
      backgroundOpacity: 0.6,
      fontColor: new THREE.Color(0xffffff),

    });
    this.containerGlobal = container;

    container.position.set(cwd.x, cwd.y + 2.5, -cwd.z);
    // container.position.set(-1, 0, 0);
    container.setRotationFromQuaternion(this.camera.quaternion);
    // container.rotation.x = 0.25;

    container.add(
      new ThreeMeshUI.Text({ content: text }),
    );

    this.scene.add(container);

    setTimeout(() => {
      this.terminate();
    }, 2500);
  }

  terminate(redo, text) {
    if (!this.containerGlobal) return;

    this.containerGlobal.clear();
    this.scene.remove(this.containerGlobal);

    this.containerGlobal.update(true, true, true);
    this.containerGlobal = undefined;

    window.requestAnimationFrame(() => {
      if (redo) this.create(text);
    });
  }

  update() {
    if (!this.containerGlobal) return;
    ThreeMeshUI.update();
    // this.containerGlobal.lookAt( this.camera.position );
  }
}

export { PressToESC };

