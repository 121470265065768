import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const ChatIcon: FC<IconsType> = memo((props) => {
  const {
    width = '34',
    height = '34',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1999 22.5988C19.6123 22.5988 23.9999 18.838 23.9999 14.1988C23.9999 9.55964 19.6123 5.79883 14.1999 5.79883C8.78751 5.79883 4.3999 9.55964 4.3999 14.1988C4.3999 15.7288 4.87713 17.1633 5.71097 18.3988L4.3999 22.5988L9.84435 21.7257C11.1564 22.2846 12.6353 22.5988 14.1999 22.5988Z"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9999 12.207C27.3108 13.5552 29.5999 16.4478 29.5999 19.7987C29.5999 21.3287 29.1226 22.7631 28.2888 23.9987L29.5999 28.1987L24.1554 27.3255C22.8433 27.8844 21.3644 28.1987 19.7999 28.1987C15.5329 28.1987 11.9028 25.8612 10.5575 22.5987"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default ChatIcon;
