import { Component } from '../../engine/Component';

export default class OutlineComponent extends Component {
  public enabled = true;

  static get code(): string {
    return 'outline';
  }

  public disable() {
    this.enabled = false;
  }

  public enable() {
    this.enabled = true;
  }
}
