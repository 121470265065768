import React, { FC, useEffect, useMemo, useState } from 'react';
import { MessageText, MessageTimestamp, useMessageContext } from 'stream-chat-react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import classNames from './Message.module.css';

const CustomMessage: FC = () => {
  const { message } = useMessageContext();
  const [participant, setParticipant] = useState<Participant | null>(null);
  const messageInner = useMemo(() => {
    return [
      'str-chat__message-text-inner',
      classNames.messageInner,
    ].join(' ');
  }, []);

  useEffect(() => {
    setParticipant(VoxeetSDK.session.participant);
  }, []);

  const userStyle = useMemo(() => {
    const localStyle = message.user?.id === participant?.info.ownerId && classNames.localUser;
    return [
      classNames.user,
      localStyle,
    ].join(' ');
  }, [participant]);

  const nameUser = useMemo(() => {
    return message.user?.id === participant?.info.ownerId && classNames.localUser ? 'You' : message.user?.name;
  }, [participant]);

  return (
    <div className={classNames.wrapper}>
      <MessageTimestamp
        customClass={classNames.date}
        format="h:mm"
      />
      <div className={classNames.wrapperText}>
        <div className={userStyle}>{nameUser}</div>
        <MessageText
          customInnerClass={messageInner}
          message={message}
        />
      </div>
    </div>
  );
};

export default CustomMessage;
