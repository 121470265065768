import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const ChatIcon: FC<IconsType> = memo((props) => {
  const {
    width = '34',
    height = '34',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1113 19.3714C12.1113 20.923 13.3734 22.1854 14.9247 22.1854H19.0754C20.6267 22.1854 21.8888 20.923 21.8888 19.3714C21.8888 17.4405 20.7639 15.7676 19.1353 14.9733C19.5575 14.481 19.8135 13.8421 19.8135 13.1441C19.8135 11.5925 18.5514 10.3301 17.0001 10.3301C15.4487 10.3301 14.1866 11.5924 14.1866 13.1441C14.1866 13.8421 14.4426 14.481 14.8648 14.9733C13.2362 15.7675 12.1113 17.4405 12.1113 19.3714ZM15.6628 13.1441C15.6628 12.4066 16.2626 11.8066 17 11.8066C17.7373 11.8066 18.3372 12.4066 18.3372 13.1441C18.3372 13.8816 17.7374 14.4816 17 14.4816C16.2627 14.4816 15.6628 13.8816 15.6628 13.1441ZM17 15.9581C18.8816 15.9581 20.4125 17.4893 20.4125 19.3714C20.4125 20.1088 19.8127 20.7089 19.0753 20.7089H14.9247C14.1874 20.7089 13.5875 20.1088 13.5875 19.3714C13.5875 17.4893 15.1184 15.9581 17 15.9581Z"
        fill="#292D32"
        stroke="#292D32"
        strokeWidth="0.6"
      />
      <path
        d="M8.76855 9.51929C10.7659 7.3201 13.4999 6.03054 16.4668 5.88811C19.434 5.74547 22.2788 6.76743 24.4775 8.76518L24.9357 9.18146L23.324 9.25883C22.9168 9.27837 22.6026 9.62437 22.6221 10.0316C22.6411 10.4269 22.9674 10.7346 23.3588 10.7345C23.3707 10.7345 23.3827 10.7342 23.3947 10.7336L26.7863 10.5708C27.1935 10.5513 27.5077 10.2053 27.4882 9.79801L27.3254 6.40573C27.3059 5.99845 26.9593 5.68425 26.5528 5.70369C26.1456 5.72323 25.8314 6.06923 25.8509 6.47651L25.9282 8.08853L25.47 7.6722C22.9796 5.40932 19.7569 4.25216 16.3961 4.41325C13.0352 4.57459 9.9383 6.03537 7.6759 8.52647C4.80054 11.6924 3.73103 16.0559 4.81481 20.1989C4.90166 20.5307 5.20084 20.7505 5.52841 20.7505C5.59026 20.7505 5.6531 20.7427 5.71574 20.7263C6.11014 20.6231 6.34618 20.2197 6.243 19.8252C5.28597 16.167 6.2301 12.3143 8.76855 9.51929Z"
        fill="#292D32"
        stroke="#292D32"
        strokeWidth="0.6"
      />
      <path
        d="M29.1852 13.7996C29.0819 13.4052 28.6785 13.169 28.2842 13.2723C27.8898 13.3755 27.6538 13.779 27.757 14.1734C28.714 17.8317 27.7699 21.6843 25.2314 24.4793C23.2341 26.6785 20.5001 27.9681 17.5331 28.1105C17.3506 28.1193 17.1689 28.1237 16.9874 28.1237C14.2189 28.1236 11.5859 27.1083 9.52249 25.2335L9.06432 24.8172L10.676 24.7398C11.0831 24.7203 11.3974 24.3743 11.3778 23.967C11.3584 23.5597 11.0118 23.2452 10.6052 23.2649L7.2137 23.4278C6.80651 23.4473 6.49227 23.7933 6.51181 24.2006L6.67458 27.5928C6.69353 27.9881 7.01982 28.2957 7.41126 28.2957C7.42317 28.2957 7.43518 28.2954 7.44718 28.2948C7.85437 28.2753 8.16861 27.9293 8.14907 27.522L8.07177 25.91L8.52998 26.3263C10.8675 28.4502 13.8496 29.6002 16.9858 29.6002C17.1912 29.6002 17.3974 29.5952 17.604 29.5853C20.9648 29.424 24.0617 27.9632 26.3242 25.4721C29.1994 22.3062 30.269 17.9426 29.1852 13.7996Z"
        fill="#292D32"
        stroke="#292D32"
        strokeWidth="0.6"
      />
    </svg>

  );
});

export default ChatIcon;
