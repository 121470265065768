import * as Three from 'three';
import { System } from '../../engine/System';
import { MainScene } from '../scenes/MainScene';
import RaycastComponent from '../components/Raycast.component';
import OutlineComponent from '../components/Outline.component';

export default class OutlineSystem extends System {
  onUpdate(dt: number) {
    super.onUpdate(dt);

    // this.outlinePassUpdate(dt);
    this.outlineMaterial(dt);
  }

  outlineMaterial(dt: number) {
    const entities = this.componentManager
      .getComponentsByType(OutlineComponent)
      .filter((c) => c.enabled)
      .map((c) => c.entity);
    entities
      // .filter((entity) => entity.getComponentOrFail(RaycastComponent).isActive)
      .forEach((entity) => {
        const { isActive } = entity.getComponentOrFail(RaycastComponent);
        entity.traverse((obj) => {
          if (obj instanceof Three.Mesh && obj.material.color) {
            obj.material.color.fromArray(isActive ? [0, 0.9, 1] : [1, 1, 1]);
          }
        });
      });
  }

  outlinePassUpdate(dt: number) {
    // TODO: think about it
    const pass = (this.app.sceneManager.currentScene as MainScene).outlinePass;
    if (!pass) return;
    // pass.selectedObjects = [];
    const entities = this.componentManager
      .getComponentsByType(OutlineComponent)
      .filter((c) => c.enabled)
      .map((c) => c.entity);

    pass.selectedObjects = pass.selectedObjects.filter((obj) => {
      return !entities.find((entity) => entity === obj);
    });

    entities
      .filter((entity) => entity.getComponentOrFail(RaycastComponent).isActive)
      .forEach((entity) => {
        if (!pass.selectedObjects.find((obj) => obj.uuid === entity.uuid)) {
          pass.selectedObjects.push(entity);
        }
      });
  }
}
