import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const ArrowUpIcon: FC<IconsType> = memo((props) => {
  const {
    height = 24,
    width = 24,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99988 1L12.9999 7M6.99988 1L0.999878 7M6.99988 1L6.99988 15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
});

export default ArrowUpIcon;
