import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const EmojiIcon: FC<IconsType> = memo((props) => {
  const {
    height = 24,
    width = 24,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.72781 12.0004C9.01714 12.0004 9.29461 11.8854 9.4992 11.6808C9.70378 11.4762 9.81872 11.1988 9.81872 10.9094V8.72763C9.81872 8.4383 9.70378 8.16082 9.4992 7.95624C9.29461 7.75165 9.01714 7.63672 8.72781 7.63672C8.43848 7.63672 8.16101 7.75165 7.95642 7.95624C7.75184 8.16082 7.6369 8.4383 7.6369 8.72763V10.9094C7.6369 11.1988 7.75184 11.4762 7.95642 11.6808C8.16101 11.8854 8.43848 12.0004 8.72781 12.0004Z"
        fill="#D1D1D1"
      />
      <path
        d="M15.2743 12.0004C15.5636 12.0004 15.8411 11.8854 16.0457 11.6808C16.2503 11.4762 16.3652 11.1988 16.3652 10.9094V8.72763C16.3652 8.4383 16.2503 8.16082 16.0457 7.95624C15.8411 7.75165 15.5636 7.63672 15.2743 7.63672C14.985 7.63672 14.7075 7.75165 14.5029 7.95624C14.2983 8.16082 14.1834 8.4383 14.1834 8.72763V10.9094C14.1834 11.1988 14.2983 11.4762 14.5029 11.6808C14.7075 11.8854 14.985 12.0004 15.2743 12.0004Z"
        fill="#D1D1D1"
      />
      <path
        d="M15.5187 14.5831C15.1134 15.1355 14.5837 15.5847 13.9725 15.8944C13.3613 16.204 12.6857 16.3653 12.0006 16.3653C11.3154 16.3653 10.6399 16.204 10.0286 15.8944C9.41743 15.5847 8.88771 15.1355 8.48238 14.5831C8.29953 14.3589 8.03509 14.2165 7.74724 14.1872C7.45939 14.158 7.1717 14.2443 6.94747 14.4271C6.72325 14.61 6.58084 14.8744 6.55158 15.1623C6.52233 15.4501 6.60862 15.7378 6.79147 15.962C7.40214 16.7646 8.19001 17.4153 9.0936 17.8632C9.99719 18.3111 10.9921 18.5441 12.0006 18.5441C13.0091 18.5441 14.0039 18.3111 14.9075 17.8632C15.8111 17.4153 16.599 16.7646 17.2097 15.962C17.3925 15.7378 17.4788 15.4501 17.4495 15.1623C17.4203 14.8744 17.2779 14.61 17.0537 14.4271C16.8294 14.2443 16.5417 14.158 16.2539 14.1872C15.966 14.2165 15.7016 14.3589 15.5187 14.5831V14.5831Z"
        fill="#D1D1D1"
      />
      <path
        d="M12 0C9.62663 0 7.30655 0.703787 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.0051995 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693604 16.6688 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9962 8.81855 22.7308 5.76849 20.4811 3.51886C18.2315 1.26924 15.1814 0.00375324 12 0ZM12 21.8182C10.0582 21.8182 8.15991 21.2423 6.54531 20.1635C4.93072 19.0847 3.6723 17.5513 2.92919 15.7573C2.18607 13.9632 1.99164 11.9891 2.37048 10.0846C2.74931 8.18002 3.6844 6.43059 5.0575 5.0575C6.4306 3.6844 8.18003 2.74931 10.0846 2.37047C11.9891 1.99163 13.9632 2.18607 15.7573 2.92918C17.5513 3.6723 19.0847 4.93072 20.1635 6.54531C21.2424 8.1599 21.8182 10.0581 21.8182 12C21.8153 14.6031 20.78 17.0987 18.9393 18.9393C17.0987 20.7799 14.6031 21.8153 12 21.8182Z"
        fill="#D1D1D1"
      />
    </svg>
  );
});

export default EmojiIcon;
