import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import classNames from './Input.module.css';

export interface InputProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  width: string;
  placeholder: string;
  value?: string;
}

const Input: FC<InputProps> = (props) => {
  const {
    onChange = () => undefined,
    width = 'auto',
    placeholder,
    value = '',
  } = props;

  const [inputValue, setInputValue] = useState<string>('');

  const changeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setInputValue(e.target.value);
  }, [inputValue]);

  useEffect(() => {
    if (value.length > 0) setInputValue(value);
  }, [value]);

  return (
    <input
      style={{ width }}
      placeholder={placeholder}
      className={classNames.root}
      value={inputValue}
      onChange={changeHandler}
    />
  );
};

export default Input;
