import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const VolumeHightIcon: FC<IconsType> = memo((props) => {
  const {
    width = 16,
    height = 16,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34174 2.51544C10.1163 2.94345 10.4999 3.84569 10.4999 4.93974V11.0597C10.4999 12.1511 10.116 13.0526 9.34224 13.4813C8.56831 13.9101 7.60038 13.7573 6.6744 13.1768C6.67437 13.1767 6.67443 13.1768 6.6744 13.1768L4.73333 11.9603C4.59158 11.8772 4.43413 11.8331 4.28659 11.8331H3.33325C2.58951 11.8331 1.93987 11.6468 1.4797 11.1866C1.01953 10.7265 0.833252 10.0768 0.833252 9.33308V6.66641C0.833252 5.92267 1.01953 5.27303 1.4797 4.81286C1.93987 4.35268 2.58951 4.16641 3.33325 4.16641H4.28659C4.43414 4.16641 4.59159 4.12233 4.73334 4.03922L6.67432 2.82279C6.67434 2.82277 6.67429 2.82281 6.67432 2.82279C7.59951 2.24273 8.56741 2.08758 9.34174 2.51544ZM8.8581 3.39071C8.52245 3.20525 7.9603 3.1968 7.20552 3.67003L5.25368 4.89327L5.24852 4.89634C4.96481 5.06479 4.63075 5.16641 4.28659 5.16641H3.33325C2.74366 5.16641 2.3933 5.31347 2.18681 5.51996C1.98031 5.72646 1.83325 6.07682 1.83325 6.66641V9.33308C1.83325 9.92267 1.98031 10.273 2.18681 10.4795C2.3933 10.686 2.74366 10.8331 3.33325 10.8331H4.28659C4.63075 10.8331 4.9648 10.9347 5.24852 11.1032L5.25368 11.1062L7.20544 12.3294C7.95943 12.8021 8.52155 12.7927 8.8576 12.6066C9.19387 12.4202 9.49992 11.9484 9.49992 11.0597V4.93974C9.49992 4.04713 9.1935 3.57604 8.8581 3.39071Z"
        fill="#292D32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6998 4.93422C11.9206 4.76838 12.234 4.81294 12.3998 5.03374C13.7201 6.79163 13.7201 9.20973 12.3998 10.9676C12.234 11.1884 11.9206 11.233 11.6998 11.0671C11.479 10.9013 11.4344 10.5879 11.6002 10.3671C12.6533 8.96496 12.6533 7.0364 11.6002 5.63428C11.4344 5.41348 11.479 5.10005 11.6998 4.93422Z"
        fill="#292D32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9198 3.26617C13.1407 3.10039 13.4541 3.14503 13.6199 3.36588C15.68 6.11039 15.68 9.88837 13.6199 12.6329C13.4541 12.8537 13.1407 12.8984 12.9198 12.7326C12.699 12.5668 12.6543 12.2534 12.8201 12.0325C14.6133 9.64372 14.6133 6.35503 12.8201 3.96621C12.6543 3.74537 12.699 3.43195 12.9198 3.26617Z"
        fill="#292D32"
      />
    </svg>
  );
});

export default VolumeHightIcon;
