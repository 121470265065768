interface Button {
  rootIcon: {
    width: number;
    height: number;
  };
  innerIcon: {
    width: number;
    height: number;
  };
}

export const mobileButton: Button = {
  rootIcon: {
    width: 40,
    height: 40,
  },
  innerIcon: {
    width: 24,
    height: 24,
  },
};

export const desktopButton: Button = {
  rootIcon: {
    width: 56,
    height: 56,
  },
  innerIcon: {
    width: 33.6,
    height: 33.6,
  },
};
