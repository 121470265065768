import React from 'react';
import './App.css';
import './fonts.css';
import SportView from './views/SportView/SportView';

function App() {
  return (
    <SportView />
  );
}

export default App;
