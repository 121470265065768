import { Object3D } from 'three';
import { Component } from './Component';
import { Application } from './Application';

export type EntityOptions = {
  app: Application;
};

export class Entity extends Object3D {
  public app: Application;

  constructor(options: EntityOptions) {
    super();
    this.app = options.app;
  }

  public getComponent<T extends typeof Component>(componentType: T): InstanceType<T> | undefined {
    return this.app.componentManager.getComponent(componentType, this);
  }

  public getComponentFromParents<T extends typeof Component>(componentType: T): InstanceType<T> | undefined {
    const component = this.app.componentManager.getComponent(componentType, this);
    if (component) return component;
    const parentEntity: Entity | undefined = this.parent as Entity;
    return parentEntity?.getComponentFromParents ? parentEntity?.getComponentFromParents(componentType) : undefined;
  }

  public getComponentOrFail<T extends typeof Component>(componentType: T): InstanceType<T> {
    const component = this.app.componentManager.getComponent(componentType, this);

    if (!component) throw new Error('Component not found');

    return component;
  }

  public addComponent<T extends typeof Component>(componentType: T, data?: ConstructorParameters<T>[0]['data']): InstanceType<T> {
    return this.app.componentManager.makeComponent(componentType, this, data);
  }
}
