import * as THREE from 'three';

export function prerenderScene(scene: THREE.Scene, camera: THREE.Camera, renderer: THREE.WebGLRenderer) {
  try {
    scene.traverse((child) => {
      child.frustumCulled = false;
      child.userData.visible = child.visible;
      child.visible = true;
      if (child instanceof THREE.Mesh && child.material) {
        Object.keys(child.material).forEach((name) => {
          if (child.material[name] instanceof THREE.Texture) {
            renderer.initTexture(child.material[name]);
          }
        });
      }
    });

    renderer.compile(scene, camera);
    // renderer.render(scene, camera);

    scene.traverse((child) => {
      child.frustumCulled = true;
      child.visible = child.userData.visible;
    });
  } catch (e: any) {
    console.warn(e);
  }
}
