export type WorkerMessage = {
  type: string;
  data?: any;
};

export enum WorkerMessageTypes {
  tick = 'Tick',
  processMessage = 'processMessage',
  startWorker = 'startWorker',
  stopWorker = 'stopWorker',
  receiveMessage = 'receiveMessage',
  broadcastMessagesBatch = 'broadcastMessagesBatch',
  sendMessage = 'sendMessage',
}

export type MessagesWorkerEventTypes = {
  onMessage: (payload: WorkerMessage) => void;
};
