import THREE, { Quaternion, Vector3 } from 'three';
import EngineVariable from './EngineVariable';
import PositionBuffer from '../../../engine/network/interpolation/PositionBuffer';
import QuaternionBuffer from '../../../engine/network/interpolation/QuaternionBuffer';
import { SyncVariableSerialized } from '../../../engine/network/SyncVariable';
import { TransformVariableType } from '../../../engine/network/variables/TransformVariable';
import config from "../../../config";

export type IKVariableBoneValue = {
  name: string;
  quaternion: THREE.Quaternion;
  position: THREE.Vector3;
};

export type IKVariableValue = {
  enabled: boolean;
  bones: IKVariableBoneValue[];
};

export default class IKVariable extends EngineVariable<IKVariableValue> {
  public static type = 'ik';

  protected positionBuffers: { [name: string]: PositionBuffer } = {};

  protected quaternionBuffers: { [name: string]: QuaternionBuffer } = {};

  public interpolated = true;

  constructor(name = 'ik') {
    super(name);
  }

  public isChanged(prevValue: IKVariableValue | null, newValue: IKVariableValue) {
    // return true;
    if (!prevValue) return true;
    const eps = 0.03;
    let isChanged = false;
    if (prevValue.enabled === newValue.enabled && !newValue.enabled) return false;
    prevValue.bones.forEach((prevBoneData, index) => {
      if (isChanged) return;
      const newBoneData = newValue.bones[index];
      const prevRotation = new Vector3(...prevBoneData.quaternion.toArray());
      const newRotation = new Vector3(...newBoneData.quaternion.toArray());
      isChanged = prevBoneData.position.distanceTo(newBoneData.position) > eps
        || prevRotation.distanceTo(newRotation) > eps;
    });
    return isChanged;
  }

  saveValueFromNetwork(data: SyncVariableSerialized<IKVariableValue>, sendTime: number, receiveTime: number) {
    super.saveValueFromNetwork(data, sendTime, receiveTime);
    this.saveBufferData(receiveTime);
  }

  protected saveBufferData(time: number) {
    if (!this.value) return;
    this.value.bones.forEach((boneData) => {
      if (!this.positionBuffers[boneData.name]) this.positionBuffers[boneData.name] = new PositionBuffer();
      this.positionBuffers[boneData.name].setValue(boneData.position, time);
      if (!this.quaternionBuffers[boneData.name]) this.quaternionBuffers[boneData.name] = new QuaternionBuffer();
      this.quaternionBuffers[boneData.name].setValue(boneData.quaternion, time);
    });
  }

  public getInterpolationValue(t: number, ts: number): IKVariableValue {
    if (!this.interpolated && this.value) return this.value;
    return {
      enabled: !!this.value?.enabled,
      bones: this.value?.bones.map(({ name }) => {
        return {
          name,
          position: this.positionBuffers[name].getValue(t - config.interpolate - ts),
          quaternion: this.quaternionBuffers[name].getValue(t - config.interpolate - ts),
        };
      }) || [],
    };
  }

  public deserializeValue(value: IKVariableValue | null) {
    if (!value) return;
    this.value = {
      enabled: value.enabled,
      bones: value.bones.map((boneSerialized) => {
        const pos = boneSerialized?.position;
        const rot = boneSerialized?.quaternion as unknown as { _x: number; _y: number; _z: number; _w: number };
        return {
          name: boneSerialized.name,
          position: new Vector3(pos?.x, pos?.y, pos?.z),
          quaternion: new Quaternion(rot?._x, rot?._y, rot?._z, rot?._w),
        };
      }),
    };
  }
}
