import Ammo from 'ammo.js';
import { Component, ComponentOptions } from '../Component';
import { AmmoCollisionFlag } from '../physics/enums/AmmoCollisionFlag';
import { AmmoActivationState } from '../physics/enums/AmmoActivationState';
import { ColliderComponent } from './Collider.component';
import { PhysicSystem } from '../systems/Physic.system';

export enum RigidBodyActivationState {
  AlwaysActive = 'AlwaysActive',
  CanSleep = 'CanSleep',
}

export enum RigidBodyType {
  Static = 'Static',
  Kinematic = 'Kinematic',
  Dynamic = 'Dynamic',
}

export type RigidBodyComponentOptions = ComponentOptions & {
  data?: {
    activationState?: RigidBodyActivationState;
    type?: RigidBodyType;
    mass?: number;
    friction?: number;
    restitution?: number;
    colliderComponent?: ColliderComponent;
    interpolate?: boolean;
    group?: number;
    mask?: number;
  };
};

export type CollisionData = Ammo.btPersistentManifold;

// todo: make configurable (any primitive), refactor!!!
export class RigidBodyComponent extends Component {
  public btRigidBody?: Ammo.btRigidBody;

  public type: RigidBodyType;

  public friction: number;

  public enabled = true;

  public inWorld = false;

  public mass: number;

  public restitution: number;

  public interpolate = true;

  public group = 1;

  public mask = -1;

  public colliderComponent: ColliderComponent | undefined = undefined;

  public activationState: RigidBodyActivationState;

  public world: Ammo.btDiscreteDynamicsWorld | null = null;

  public collisions: CollisionData[] = [];

  static get code(): string {
    return 'rigid_body';
  }

  constructor(options: RigidBodyComponentOptions) {
    super(options);

    this.type = options.data?.type || RigidBodyType.Static;
    this.friction = options.data?.friction ?? 0;
    this.mass = options.data?.mass ?? 0;
    this.restitution = options.data?.restitution ?? 0;
    this.activationState = options.data?.activationState ?? RigidBodyActivationState.CanSleep;
    this.colliderComponent = options.data?.colliderComponent;
    this.interpolate = typeof options.data?.interpolate !== 'undefined' ? options.data?.interpolate : this.interpolate;
    this.group = typeof options.data?.group !== 'undefined' ? options.data?.group : this.group;
    this.mask = typeof options.data?.mask !== 'undefined' ? options.data?.mask : this.mask;

    this.setupBtRigidBody();
  }

  public applyEntityWorldMatrix(): void {
    if (!this.btRigidBody) return;

    const transform: Ammo.btTransform = new Ammo.btTransform();

    this.entity.updateMatrixWorld(true);
    this.entity.updateWorldMatrix(true, true);
    const matrix = this.entity.matrixWorld.toArray();
    transform.setFromOpenGLMatrix(matrix);

    this.btRigidBody.setWorldTransform(transform);
    this.btRigidBody.getMotionState().setWorldTransform(transform);
  }

  public destroy() {
    Ammo.destroy(this.btRigidBody);
  }

  public getBtRigidBodyOrFail(): Ammo.btRigidBody {
    if (!this.btRigidBody) throw new Error('getBtRigidBodyOrFail');

    return this.btRigidBody;
  }

  protected setupBtRigidBody(): void {
    const colliderComponent = this.colliderComponent ? this.colliderComponent : this.entity.getComponent(ColliderComponent);

    if (!colliderComponent) {
      return; // todo: need collider component, think about it
    }

    // todo: think about it
    if (!colliderComponent.btCollisionShape) {
      colliderComponent.events.once('afterUpdate', () => {
        if (!colliderComponent.btCollisionShape) return;

        this.btRigidBody = this.makeBtRigidBody(
          this.mass,
          colliderComponent.btCollisionShape,
          this.friction,
          this.restitution,
          this.type,
          this.activationState,
        );
      });
      return;
    }

    this.btRigidBody = this.makeBtRigidBody(
      this.mass,
      colliderComponent.btCollisionShape,
      this.friction,
      this.restitution,
      this.type,
      this.activationState,
    );
  }

  protected makeBtRigidBody(
    mass: number,
    btCollisionShape: Ammo.btCollisionShape,
    friction: number,
    restitution: number,
    type: RigidBodyType,
    activationState: RigidBodyActivationState,
  ): Ammo.btRigidBody {
    const bodyInfo = new Ammo.btRigidBodyConstructionInfo(
      mass,
      new Ammo.btDefaultMotionState(new Ammo.btTransform()),
      btCollisionShape,
      new Ammo.btVector3(0, 0, 0),
    );
    const btRigidBody = new Ammo.btRigidBody(bodyInfo);

    btRigidBody.setFriction(friction);
    btRigidBody.setRestitution(restitution);
    this.setBtRigidBodyType(btRigidBody, type);
    this.setBtRigidBodyActivationState(btRigidBody, activationState);

    return btRigidBody;
  }

  protected setBtRigidBodyType(body: Ammo.btRigidBody, type: RigidBodyType): void {
    switch (type) {
      case RigidBodyType.Dynamic:
        return body.setCollisionFlags(AmmoCollisionFlag.CF_DYNAMIC_OBJECT);
      case RigidBodyType.Kinematic:
        return body.setCollisionFlags(AmmoCollisionFlag.CF_KINEMATIC_OBJECT);
      case RigidBodyType.Static:
        return body.setCollisionFlags(AmmoCollisionFlag.CF_STATIC_OBJECT);
      default:
        throw new Error(`Unsupported rigidBody type ${type}`);
    }
  }

  protected setBtRigidBodyActivationState(body: Ammo.btRigidBody, state: RigidBodyActivationState): void {
    switch (state) {
      case RigidBodyActivationState.AlwaysActive:
        return body.setActivationState(AmmoActivationState.DISABLE_DEACTIVATION);
      case RigidBodyActivationState.CanSleep:
        return body.setActivationState(AmmoActivationState.WANTS_DEACTIVATION);
      default:
        throw new Error(`Unsupported rigidBody activation state ${state}`);
    }
  }

  public setLinearVelocity({ x, y, z }: { x?: number; y?: number; z?: number }) {
    const rb = this.getBtRigidBodyOrFail();
    const lv = rb.getLinearVelocity();
    const vel = new Ammo.btVector3(
      typeof x !== 'undefined' ? x as number : lv.x(),
      typeof y !== 'undefined' ? y as number : lv.y(),
      typeof z !== 'undefined' ? z as number : lv.z(),
    );
    rb.setLinearVelocity(vel);
  }

  public disable() {
    this.enabled = false;

    // if (!this.btRigidBody) return;
    //
    // this.world?.removeRigidBody(this.btRigidBody);

    // this.btRigidBody.setMassProps(0, new Ammo.btVector3(0, 0, 0));
    // // eslint-disable-next-line no-bitwise
    // this.btRigidBody.setCollisionFlags(this.btRigidBody.getCollisionFlags() | AmmoCollisionFlag.CF_NO_CONTACT_RESPONSE);
    // // this.btRigidBody.setActivationState(AmmoActivationState.ISLAND_SLEEPING);
  }

  public enable() {
    this.enabled = true;

    // if (!this.btRigidBody) return;
    //
    // this.world?.addRigidBody(this.btRigidBody, this.group, this.mask);

    // this.btRigidBody.setMassProps(this.mass, new Ammo.btVector3(0, 0, 0));
    // // eslint-disable-next-line no-bitwise
    // this.btRigidBody.setCollisionFlags(this.btRigidBody.getCollisionFlags() & ~AmmoCollisionFlag.CF_NO_CONTACT_RESPONSE);
    // // this.btRigidBody.setActivationState(AmmoActivationState.ACTIVE_TAG);
  }
}
