import { FC } from 'react';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import classNames from './UnionModal.module.css';
import AudioToggleButton from '../../components/ToggleAudioButton/AudioToggleButton';
import UserActionIcon from './UserActionIcon';

export interface UserActionsProps {
  isAdmin: boolean;
  participant: Participant;
  onKickParticipant: (item: Participant) => void;
  isSpeaking: boolean;
}

const UserActions: FC<UserActionsProps> = (props) => {
  const {
    isAdmin = false,
    participant,
    onKickParticipant = () => undefined,
    isSpeaking = false,
  } = props;

  return (
    <div className={classNames.actionList}>
      <AudioToggleButton
        isActive={isSpeaking}
        participant={participant}
      />
      {isAdmin && <UserActionIcon
        isDisable={false}
        onClick={() => onKickParticipant(participant)}
        icon="logout"
      />}
    </div>
  );
};

export default UserActions;
