import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import UserActionIcon from '../../containers/UnionModal/UserActionIcon';
import useAudio from '../../hooks/audio/useAudio';
import useParticipants from '../../hooks/audio/useParticipants';

type LocalToggleAudioButtonProps = {
  participant: Participant;
  isActive: boolean;
};

const AudioToggleButton: FC<LocalToggleAudioButtonProps> = (props) => {
  const {
    participant,
    isActive = false,
  } = props;

  const [isLocal, setIsLocal] = useState<boolean>(false);

  useEffect(() => {
    setIsLocal(VoxeetSDK.session.participant.id === participant.id);
  }, []);

  const { participantsStatus } = useParticipants();
  const { startParticipantAudio, stopParticipantAudio } = useAudio();
  const { isLocalAudio } = participantsStatus[participant.id] || {};

  const { isAudio, toggleAudio } = useAudio();

  const toggleAudioHandler = useCallback(() => {
    if (isLocal) toggleAudio(participant);
    else {
      if (isLocalAudio) stopParticipantAudio(participant);
      if (!isLocalAudio) startParticipantAudio(participant);
    }
  }, [participant, isLocalAudio, isLocal]);

  const iconLocalName = useMemo(() => {
    if (isAudio) return 'volumeHight';
    if (!isAudio) return 'volumeSlash';
    return 'volumeHight';
  }, [isAudio]);

  const iconRemoteName = useMemo(() => {
    if (isLocalAudio) return 'volumeHight';
    if (!isLocalAudio) return 'volumeSlash';
    return 'volumeHight';
  }, [isLocalAudio]);

  const isUser = useMemo(() => {
    return VoxeetSDK.session.participant.id === participant.id;
  }, [participant]);

  return (
    <UserActionIcon
      isBlue={isUser}
      isActive={isActive}
      onClick={toggleAudioHandler}
      icon={isLocal ? iconLocalName : iconRemoteName}
    />
  );
};

export default AudioToggleButton;
