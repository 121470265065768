import { FC, useMemo } from 'react';
import classNames from './UnionModal.module.css';
import Icon from '../../components/Icon/Icon';
import { IconsType } from '../../components/Icons/IconsType';

export interface UserActionIconProps extends IconsType {
  onClick: () => void;
  icon: string;
  isActive?: boolean;
  isDisable?: boolean;
  isBlue?: boolean;
}

const UserActionIcon: FC<UserActionIconProps> = (props) => {
  const {
    onClick = () => undefined,
    icon,
    isActive = false,
    isDisable = false,
    isBlue = false,
    height,
    width,
  } = props;

  const rootStyle = useMemo(() => {
    const style = isActive && classNames.volumeOn;
    const disableStyle = isDisable && classNames.disable;
    const isUser = isBlue && classNames.isUser;
    return [
      classNames.userActionButton,
      style,
      disableStyle,
      isUser,
    ].join(' ');
  }, [isActive, isDisable, isBlue]);

  return (
    <div
      className={rootStyle}
      onClick={onClick}
    >
      <Icon
        name={icon}
        height={height}
        width={width}
      />
    </div>
  );
};

export default UserActionIcon;
