import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const InsideRoundIcon: FC<IconsType> = memo((props) => {
  const {
    height,
    width,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2773_4717)">
        <rect
          width="56"
          height="56"
          rx="28"
          fill="white"
          fillOpacity="0.3"
        />
        <rect
          x="0.5"
          y="0.5"
          width="55"
          height="55"
          rx="27.5"
          stroke="url(#paint0_linear_2773_4717)"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2773_4717"
          x="-4"
          y="-4"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="2"
          />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2773_4717"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2773_4717"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2773_4717"
          x1="28"
          y1="0"
          x2="28"
          y2="56"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="white"
            stopOpacity="0.7"
          />
          <stop
            offset="1"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
});

export default InsideRoundIcon;
