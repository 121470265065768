import { Vector3, Object3D } from 'three';
import { VRMHumanBoneName } from '@pixiv/three-vrm';
import SystemObject from '../../../engine/network/SystemObject';
import NetworkManager from '../../../engine/network/NetworkManager';
import { Application } from '../../../engine/Application';
import { NetworkObjectLifeTimeTypes, NetworkObjectSerialized } from '../../../engine/network/NetworkObject';
import { Entity } from '../../../engine/Entity';
import NetworkTransformComponent, {
  NetworkTransformComponentCoordsSystem,
  NetworkTransformComponentTypes,
} from '../../../engine/components/NetworkTransform.component';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import TransformVariable, { TransformVariableType } from '../../../engine/network/variables/TransformVariable';
import { AssetSourceType, MeshRendererComponent } from '../../../engine/components/MeshRenderer.component';
import { StuffComponent, StuffType } from '../../components/Stuff.component';
import { AnimationComponent } from '../../components/Animation.component';
import RaycastComponent from '../../components/Raycast.component';
import OutlineComponent from '../../components/Outline.component';
import { ColliderComponent, ColliderType } from '../../../engine/components/Collider.component';
import {
  RigidBodyActivationState,
  RigidBodyComponent,
  RigidBodyType,
} from '../../../engine/components/RigidBody.component';
import CollisionFilters from '../../constants/collisionFilters';
import StuffVariable, { StuffVariableType } from '../variables/StuffVariable';
import { PrimitiveComponent } from '../../../engine/components/Primitive.component';

export type StuffObjectSerialized = NetworkObjectSerialized & {
  url: string;
  position: { x: number; y: number; z: number };
};

export default class StuffObject extends SystemObject {
  public static type = 'stuffobject';

  public lifeTimeType = NetworkObjectLifeTimeTypes.Shared;

  public url = '';

  public position = new Vector3();

  static register(manager: NetworkManager) {
    manager.objectsTypes[StuffObject.type] = StuffObject;
  }

  static build(app: Application, code: string, url: string, position: Vector3): StuffObject | null {
    if (!app.networkManager) return null;
    if (!app.networkManager.isRoomHost) return null;

    const netObj = app.networkManager?.buildSharedObject<StuffObject>(StuffObject.type, code, (obj) => {
      obj.url = url;
      obj.position = position;
    });
    netObj.setApplication(app);
    netObj.addVariables();
    netObj.isNeedSpawn = false;
    return netObj;
  }

  public addVariables() {
    this.addVariable<TransformVariableType>(new TransformVariable());
    this.addVariable<StuffVariableType>(new StuffVariable());
  }

  public attachToEntity(entity: Entity, type = NetworkTransformComponentTypes.Source) {
    entity.addComponent(NetworkObjectComponent, {
      netObject: this,
    });
    entity.addComponent(NetworkTransformComponent, {
      variableName: 'transform',
      type,
      coordsSystem: NetworkTransformComponentCoordsSystem.World,
    });
  }

  public static createEntityPrefab(
    app: Application,
    url: string,
    position: Vector3 | undefined = undefined,
  ): Entity | undefined {
    if (!app) return;
    const entity = app.entityManager.makeEntity();
    entity.addComponent(MeshRendererComponent, { sourceData: { type: AssetSourceType.GLTF, url } });
    entity.addComponent(AnimationComponent);
    const primitiveComponent = entity.addComponent(PrimitiveComponent, {
      height: 1,
      position: {
        y: 0.7,
      },
    });
    entity.addComponent(RaycastComponent, { target: primitiveComponent.data });
    let typeFromUrl = (url.split('/').pop() || '').split('.')[0].toLowerCase();
    typeFromUrl = typeFromUrl.charAt(0).toUpperCase() + typeFromUrl.slice(1) as keyof typeof StuffType;
    const type: StuffType = (<any>StuffType)[typeFromUrl];
    entity.addComponent(StuffComponent, { position, type, attachOffset: new Vector3(0, -0.2, 0) });
    entity.addComponent(OutlineComponent);

    // TODO: maybe just sphere
    entity.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.ConvexHull,
        applyTransform: true,
      },
    });

    const rigid = entity.addComponent(RigidBodyComponent, {
      type: RigidBodyType.Dynamic,
      mass: 1,
      friction: 0.5,
      restitution: 0.5,
      // interpolate: false,
      // TODO: we need CanSleep
      activationState: RigidBodyActivationState.AlwaysActive,
      group: CollisionFilters.StuffFilter,
      mask: CollisionFilters.StaticFilter,
    });

    rigid.btRigidBody?.setRollingFriction(1);

    // By default disable rigid body
    rigid.disable();
    return entity;
  }

  public spawnEntity(): Object3D | undefined {
    if (!this.app) return;
    if (!this.getVariableByName('stuff')) return;
    // if (!this.getVariableByName('transform')) return;
    const varPosition = this.getVariableByName('transform')?.value.position;
    const pos = varPosition && varPosition.length > 0 ? varPosition : this.position;
    const entity = StuffObject.createEntityPrefab(this.app, this.url, pos);
    if (entity) {
      this.attachToEntity(entity, NetworkTransformComponentTypes.Target);
    }
    return entity;
  }

  public serialize(): StuffObjectSerialized {
    const data = super.serialize() as StuffObjectSerialized;
    data.url = this.url;
    data.position = this.position;
    return data;
  }

  public deserialize(data: StuffObjectSerialized) {
    super.deserialize(data);
    this.url = data.url;
    this.position = new Vector3(data.position.x, data.position.y, data.position.z);
    return this;
  }
}
