import { Vector3, MathUtils as ThreeMath } from 'three';
import { IKConstraint } from '../types';
import IKJoint from '../IKJoint';
import IKChain from '../IKChain';

const Z_AXIS = new Vector3(0, 0, 1);
const { DEG2RAD, RAD2DEG } = ThreeMath;

export type IKBallConstraintOptions = {
  angle: number;
  up?: Vector3;
};

/**
 * A class for a constraint.
 */
export default class IKBallConstraint implements IKConstraint {
  public angle: number;

  public up: Vector3;

  constructor(options: IKBallConstraintOptions) {
    this.angle = options.angle;
    this.up = options.up || Z_AXIS;
  }

  /**
   * Applies a constraint to passed in IKJoint, updating
   * its direction if necessary. Returns a boolean indicating
   * if the constraint was applied or not.
   */
  apply(joint: IKJoint, chain: IKChain): boolean {
    // Get direction of joint and parent in world space
    const direction = new Vector3().copy(joint.getDirection());
    const jointIndex = chain.joints.findIndex((cj) => cj === joint);
    const parentDirection = jointIndex > 0 ? chain.joints[jointIndex - 1].getDirection().clone()
      : joint.localToWorldDirection(new Vector3().copy(this.up)).normalize();
    direction.normalize();
    parentDirection.normalize();

    // Find the current angle between them
    const currentAngle = direction.angleTo(parentDirection) * RAD2DEG;
    if ((this.angle / 2) < currentAngle) {

      // Find the correction axis and rotate around that point to the
      // largest allowed angle
      const correctionAxis = new Vector3().crossVectors(parentDirection, direction).normalize();

      parentDirection.applyAxisAngle(correctionAxis, this.angle * DEG2RAD * 0.5);
      joint.setDirection(parentDirection);
      return true;
    }

    return false;
  }
}
