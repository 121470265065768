import { useCallback, useState } from 'react';
import { Channel, DefaultGenerics, StreamChat } from 'stream-chat';

export interface UseCreateTextChatResult {
  initializeChat: (roomId: string, userId: string, userName: string) => void;
  chatClient?: StreamChat<DefaultGenerics> | null;
  channelClient?: Channel<DefaultGenerics> | null;
  leaveChatHandler: () => void;
  updateUser: (userID: string, name: string) => void;
  isDisconnect: boolean;
}

const useCreateTextChatRoom = (): UseCreateTextChatResult => {
  const [channelClient, setChannelClient] = useState<Channel<DefaultGenerics> | null>(null);
  const [chatClient, setChatClient] = useState<StreamChat<DefaultGenerics> | null>(null);
  const [isDisconnect, setIsDisconnect] = useState<boolean>(true);

  const initializeChat = async (userName = '',
    userId = '1',
    roomId = '') => {
    const chat = await StreamChat.getInstance(process.env.REACT_APP_INSTANCE_CHAT || '');
    console.log(userName);
    await chat.connectUser(
      {
        id: userId,
        name: userName,
      },
      chat.devToken(userId),
    );
    const channel = chat.channel('messaging', roomId, {
      name: `Meet ${roomId}`,
    });

    channel.create();

    channel.watch()
      .then(() => {
        setIsDisconnect(false);
      });
    setChannelClient(channel);
    setChatClient(chat);
  };

  const leaveChatHandler = useCallback(() => {
    setIsDisconnect(true);
    chatClient?.disconnectUser();
  }, []);

  const updateUser = async (userID: string, name: string) => {
    await chatClient?.upsertUser({
      id: userID,
      name,
    });
  };

  return {
    initializeChat,
    channelClient,
    updateUser,
    leaveChatHandler,
    isDisconnect,
    chatClient,
  };
};

export default useCreateTextChatRoom;
