import * as Three from 'three';
import * as ThreeVRM from '@pixiv/three-vrm';
import { VRMHumanBoneName } from '@pixiv/three-vrm';

const mixamoVRMRigMap = {
  mixamorigHips: 'hips',
  mixamorigSpine: 'spine',
  mixamorigSpine1: 'chest',
  mixamorigSpine2: 'upperChest',
  mixamorigNeck: 'neck',
  mixamorigHead: 'head',
  mixamorigLeftShoulder: 'leftShoulder',
  mixamorigLeftArm: 'leftUpperArm',
  mixamorigLeftForeArm: 'leftLowerArm',
  mixamorigLeftHand: 'leftHand',
  mixamorigLeftHandThumb1: 'leftThumbProximal',
  mixamorigLeftHandThumb2: 'leftThumbIntermediate',
  mixamorigLeftHandThumb3: 'leftThumbDistal',
  mixamorigLeftHandIndex1: 'leftIndexProximal',
  mixamorigLeftHandIndex2: 'leftIndexIntermediate',
  mixamorigLeftHandIndex3: 'leftIndexDistal',
  mixamorigLeftHandMiddle1: 'leftMiddleProximal',
  mixamorigLeftHandMiddle2: 'leftMiddleIntermediate',
  mixamorigLeftHandMiddle3: 'leftMiddleDistal',
  mixamorigLeftHandRing1: 'leftRingProximal',
  mixamorigLeftHandRing2: 'leftRingIntermediate',
  mixamorigLeftHandRing3: 'leftRingDistal',
  mixamorigLeftHandPinky1: 'leftLittleProximal',
  mixamorigLeftHandPinky2: 'leftLittleIntermediate',
  mixamorigLeftHandPinky3: 'leftLittleDistal',
  mixamorigRightShoulder: 'rightShoulder',
  mixamorigRightArm: 'rightUpperArm',
  mixamorigRightForeArm: 'rightLowerArm',
  mixamorigRightHand: 'rightHand',
  mixamorigRightHandPinky1: 'rightLittleProximal',
  mixamorigRightHandPinky2: 'rightLittleIntermediate',
  mixamorigRightHandPinky3: 'rightLittleDistal',
  mixamorigRightHandRing1: 'rightRingProximal',
  mixamorigRightHandRing2: 'rightRingIntermediate',
  mixamorigRightHandRing3: 'rightRingDistal',
  mixamorigRightHandMiddle1: 'rightMiddleProximal',
  mixamorigRightHandMiddle2: 'rightMiddleIntermediate',
  mixamorigRightHandMiddle3: 'rightMiddleDistal',
  mixamorigRightHandIndex1: 'rightIndexProximal',
  mixamorigRightHandIndex2: 'rightIndexIntermediate',
  mixamorigRightHandIndex3: 'rightIndexDistal',
  mixamorigRightHandThumb1: 'rightThumbProximal',
  mixamorigRightHandThumb2: 'rightThumbIntermediate',
  mixamorigRightHandThumb3: 'rightThumbDistal',
  mixamorigLeftUpLeg: 'leftUpperLeg',
  mixamorigLeftLeg: 'leftLowerLeg',
  mixamorigLeftFoot: 'leftFoot',
  mixamorigLeftToeBase: 'leftToes',
  mixamorigRightUpLeg: 'rightUpperLeg',
  mixamorigRightLeg: 'rightLowerLeg',
  mixamorigRightFoot: 'rightFoot',
  mixamorigRightToeBase: 'rightToes',
};

export class VrmAnimationConverter {
  public convertMixamoClipsToVRM(clips: Three.AnimationClip[], vrm: ThreeVRM.VRM, content: Three.Group[]): Three.AnimationClip[] {
    return clips.map((clip, index) => this.convertMixamoClipToVRM(clip, vrm, content[index]));
  }

  public convertMixamoClipToVRM(clip: Three.AnimationClip, vrm: ThreeVRM.VRM, content?: Three.Group): Three.AnimationClip {
    const tracks: Three.KeyframeTrack[] = [];
    const { humanoid } = vrm;
    const parentRestWorldRotation = new Three.Quaternion();
    const restRotationInverse = new Three.Quaternion();
    const quatA = new Three.Quaternion();

    if (!humanoid) throw new Error('VRM need contain humanoid data');

    clip.tracks.forEach((track) => {
      const trackSplitted = track.name.split('.');
      const mixamoRigName = trackSplitted[0];

      const vrmBoneName = mixamoVRMRigMap[mixamoRigName as keyof typeof mixamoVRMRigMap] as VRMHumanBoneName;
      const vrmNodeName = humanoid.getBoneNode(vrmBoneName)?.name;
      const mixamoRigNode = content?.getObjectByName(mixamoRigName);

      if (!mixamoRigNode) return;
      if (vrmNodeName != null) {
        const propertyName = trackSplitted[1];
        mixamoRigNode?.getWorldQuaternion(restRotationInverse).invert();
        mixamoRigNode?.parent?.getWorldQuaternion(parentRestWorldRotation);

        if (track instanceof Three.QuaternionKeyframeTrack) {
          for (let i = 0; i < track.values.length; i += 4) {
            const flatQuaternion = track.values.slice(i, i + 4);
            quatA.fromArray(flatQuaternion);
            quatA
              .premultiply(parentRestWorldRotation)
              .multiply(restRotationInverse);
            quatA.toArray(flatQuaternion);
            flatQuaternion.forEach((v, index) => {
              track.values[index + i] = v;
            });
          }

          tracks.push(new Three.QuaternionKeyframeTrack(
            `${vrmNodeName}.${propertyName}`,
            Array.from(track.times),
            Array.from(track.values).map((v, i) => (
              (vrm.meta?.metaVersion === '0' && (i % 2) === 0) ? -v : v
            )),
          ));
        } else if (track instanceof Three.VectorKeyframeTrack) {
          tracks.push(new Three.VectorKeyframeTrack(
            `${vrmNodeName}.${propertyName}`,
            Array.from(track.times),
            Array.from(track.values).map((v, i) => (
              ((vrm.meta?.metaVersion === '0' && (i % 3) !== 1) ? -v : v) * 0.01
            )),
          ));
        }
      }
    });

    return new Three.AnimationClip(clip.name, clip.duration, tracks);
  }
}
