import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const VolumeSlashIcon: FC<IconsType> = memo((props) => {
  const {
    height = 16,
    width = 16,
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34174 2.51544C10.1163 2.94345 10.4999 3.84569 10.4999 4.93974V5.57974C10.4999 5.85589 10.2761 6.07974 9.99992 6.07974C9.72378 6.07974 9.49992 5.85589 9.49992 5.57974V4.93974C9.49992 4.04713 9.1935 3.57604 8.8581 3.39071C8.52245 3.20525 7.9603 3.1968 7.20552 3.67003L5.25877 4.89008L5.24852 4.89634C4.96481 5.06479 4.63075 5.16641 4.28659 5.16641H3.33325C2.74366 5.16641 2.3933 5.31347 2.18681 5.51996C1.98031 5.72646 1.83325 6.07682 1.83325 6.66641V9.33308C1.83325 9.92267 1.98031 10.273 2.18681 10.4795C2.3933 10.686 2.74366 10.8331 3.33325 10.8331H4.66659C4.94273 10.8331 5.16659 11.0569 5.16659 11.3331C5.16659 11.6092 4.94273 11.8331 4.66659 11.8331H3.33325C2.58951 11.8331 1.93987 11.6468 1.4797 11.1866C1.01953 10.7265 0.833252 10.0768 0.833252 9.33308V6.66641C0.833252 5.92267 1.01953 5.27303 1.4797 4.81286C1.93987 4.35268 2.58951 4.16641 3.33325 4.16641H4.28659C4.43414 4.16641 4.59159 4.12233 4.73334 4.03922L6.67432 2.82279C6.67434 2.82277 6.67429 2.82281 6.67432 2.82279C7.59951 2.24273 8.56741 2.08758 9.34174 2.51544Z"
        fill="#292D32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.13281C10.2762 8.13281 10.5 8.35667 10.5 8.63281V11.0595C10.5 12.1509 10.1161 13.0523 9.34233 13.481C8.56837 13.9098 7.60042 13.757 6.6744 13.1764C6.44044 13.0297 6.3697 12.7212 6.51639 12.4872C6.66308 12.2532 6.97165 12.1825 7.20561 12.3292C7.9596 12.8019 8.52164 12.7925 8.85769 12.6063C9.19396 12.42 9.50001 11.9481 9.50001 11.0595V8.63281C9.50001 8.35667 9.72387 8.13281 10 8.13281Z"
        fill="#292D32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.347 5.81821C12.6017 5.71158 12.8946 5.83163 13.0012 6.08636C13.6682 7.67952 13.4689 9.541 12.4 10.9661C12.2343 11.187 11.9209 11.2318 11.7 11.0661C11.4791 10.9004 11.4343 10.587 11.6 10.3661C12.4512 9.23119 12.6119 7.746 12.0788 6.4725C11.9722 6.21777 12.0922 5.92484 12.347 5.81821Z"
        fill="#292D32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9059 4.73856C14.1604 4.63136 14.4536 4.75077 14.5608 5.00526C15.6086 7.49285 15.2952 10.4015 13.6198 12.6329C13.454 12.8537 13.1406 12.8983 12.9198 12.7325C12.699 12.5667 12.6544 12.2533 12.8202 12.0325C14.2781 10.0906 14.5514 7.55918 13.6392 5.39344C13.532 5.13895 13.6514 4.84575 13.9059 4.73856Z"
        fill="#292D32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0201 0.980431C15.2154 1.17569 15.2154 1.49228 15.0201 1.68754L1.68681 15.0209C1.49154 15.2161 1.17496 15.2161 0.979699 15.0209C0.784436 14.8256 0.784436 14.509 0.979699 14.3138L14.313 0.980431C14.5083 0.785169 14.8249 0.785169 15.0201 0.980431Z"
        fill="#292D32"
      />
    </svg>
  );
});

export default VolumeSlashIcon;
