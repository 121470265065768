import * as THREE from 'three';
import { Component } from '../../engine/Component';

export class SeatComponent extends Component {
  public playerIsSeat = false;

  public beforeSeatPosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0);

  static get code(): string {
    return 'seat';
  }
}
