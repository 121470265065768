import * as Three from 'three';
import { Reflector } from 'three/examples/jsm/objects/Reflector';
import { VRMFirstPerson } from '@pixiv/three-vrm';
import { System } from '../../engine/System';
import VrmIKComponent from '../components/VrmIKComponent';
import AvatarComponent from '../components/Avatar.component';
import { ControllerName, XRInputSystem } from '../../engine/systems/XRInputSystem';
import { AnimatorComponent } from '../../engine/components/Animator.component';
import { InputSystem } from '../../engine/systems/InputSystem';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';

function setupDebugMirror(threeScene: Three.Scene | null): Reflector | null {
  // greatly reduces performance, only debug

  if (!threeScene) return null;

  const geometry = new Three.PlaneGeometry(1, 3);
  const verticalMirror = new Reflector(geometry, {
    clipBias: 0.003,
    textureWidth: 1024,
    textureHeight: 1024,
    color: 0x889999,
    encoding: Three.sRGBEncoding,
  });

  // pos near spawn2
  verticalMirror.position.y = 1;
  verticalMirror.position.x = -13;
  verticalMirror.position.z = -4;
  verticalMirror.camera.layers.disable(VRMFirstPerson.DEFAULT_FIRSTPERSON_ONLY_LAYER);
  verticalMirror.camera.layers.enable(VRMFirstPerson.DEFAULT_THIRDPERSON_ONLY_LAYER);

  threeScene?.add(verticalMirror);

  verticalMirror.visible = false;

  return verticalMirror;
}

export default class AvatarSystem extends System {
  public debugMirror: Reflector | null = null;

  onUpdate(dt: number) {
    super.onUpdate(dt);

    if (!this.debugMirror) this.debugMirror = setupDebugMirror(this.app.sceneManager.currentThreeScene);

    this.app.componentManager.getComponentsByType(AvatarComponent).forEach((component) => {
      const ikComponent = component.entity.getComponent(VrmIKComponent);
      if (ikComponent) this.updateIK(ikComponent, dt);
    });

    if (this.activeDebugMirror() && this.debugMirror) {
      this.debugMirror.visible = !this.debugMirror.visible;
    }
  }

  updateIK(ikComponent: VrmIKComponent, dt: number) {
    // component.enabled = !!this.app.renderer.xr.isPresenting;

    const netObject = ikComponent.entity.getComponentFromParents(NetworkObjectComponent)?.netObject;
    if (netObject && !netObject.isOwner()) return false;

    const xRInputSystem = this.app.getSystemOrFail(XRInputSystem);
    const animatorComponent = ikComponent.entity.getComponent(AnimatorComponent);

    if (this.activeToggleIK()) {
      ikComponent.enabled = !ikComponent.enabled;
      if (animatorComponent) {
        animatorComponent.parameters.ikFilterEnabled = !animatorComponent.parameters.ikFilterEnabled;
      }
    }

    // TODO: move to player system or in component configuration
    if (this.app.renderer.xr.isPresenting && !ikComponent.attached) {
      ikComponent.enabled = true;
      if (animatorComponent) {
        animatorComponent.parameters.ikFilterEnabled = true;
      }
      if (xRInputSystem?.getGripSpace(ControllerName.Left) && xRInputSystem?.getGripSpace(ControllerName.Right)) {
        ikComponent.attach({
          leftHand: xRInputSystem?.getGripSpace(ControllerName.Left),
          rightHand: xRInputSystem?.getGripSpace(ControllerName.Right),
        });
      }
    }
    if (!this.app.renderer.xr.isPresenting && ikComponent.attached) {
      ikComponent.enabled = false;
      if (animatorComponent) {
        animatorComponent.parameters.ikFilterEnabled = false;
      }
      ikComponent.detach();
    }
  }

  public activeDebugMirror() {
    const input = this.app.getSystemOrFail(InputSystem);
    if (process.env.NODE_ENV !== 'development') return false;
    return input.keyboard.getKeyByCode('KeyM').wasPressedThisFrame;
  }

  public activeToggleIK() {
    const input = this.app.getSystemOrFail(InputSystem);
    if (process.env.NODE_ENV !== 'development') return false;
    return input.keyboard.getKeyByCode('KeyK').wasPressedThisFrame;
  }
}
