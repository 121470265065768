import { FC } from 'react';
import { IconsType } from './IconsType';
import CameraIcon from './CameraIcon';
import ChatIcon from './ChatIcon';
import CloseIcon from './CloseIcon';
import DublicateIcon from './DublicateIcon';
import InsideRoundIcon from './InsideRoundIcon';
import LogoutIcon from './LogoutIcon';
import MicrophoneIcon from './MicrophoneIcon';
import RoundIcon from './RoundIcon';
import UnionIcon from './UnionIcon';
import UpIcon from './UpIcon';
import VolumeSlashIcon from './VolumeSlashIcon';
import VolumeHightIcon from './VolumeHightIcon';
import MicrophoneSlashIcon from './MicrophoneSlashIcon';
import ArrowUpIcon from './ArrowUpIcon';
import ArrowDownIcon from './ArrowDownIcon';
import EmojiIcon from './EmojiIcon';
import HandIcon from './HandIcons';
import ChangeIcon from './ChangeIcon';
import HappyIcon from './HappyIcon';

export const Icons: Record<string, FC<IconsType>> = {
  CameraIcon,
  CloseIcon,
  DublicateIcon,
  InsideRoundIcon,
  LogoutIcon,
  ChatIcon,
  MicrophoneIcon,
  RoundIcon,
  UpIcon,
  UnionIcon,
  VolumeSlashIcon,
  VolumeHightIcon,
  MicrophoneSlashIcon,
  ArrowUpIcon,
  EmojiIcon,
  HandIcon,
  ChangeIcon,
  ArrowDownIcon,
  HappyIcon,
};
