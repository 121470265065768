class Extra{


    readJSON(file) {
		var request = new XMLHttpRequest();
		request.open('GET', file, false);
		request.send(null);
		if (request.status == 200)
		return JSON.parse(request.responseText)
    }
}

export {Extra};