import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { addInteractivityToElement } from '../helpers/addInteractivityToElement';

export type ContainerParams = {
  width: number;
  height: number;
  fontFamily?: string;
  fontTexture?: string;
  justifyContent?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  contentDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  backgroundColor?: string;
  backgroundOpacity: number;
  borderRadius?: [number, number, number, number];
  autoLayout?: boolean;
  visible: boolean;
  id: string;
};

export function Container(params: ContainerParams, children: ThreeMeshUI.Block[]): ThreeMeshUI.Block {
  const container = new ThreeMeshUI.Block({
    width: params.width,
    height: params.height,
    fontFamily: params.fontFamily,
    fontTexture: params.fontTexture,
    justifyContent: params.justifyContent,
    contentDirection: params.contentDirection,
    backgroundOpacity: params.backgroundOpacity,
    backgroundColor: new Three.Color(0xffffff),
    offset: 0,
    zIndex: 1,
    borderRadius: params.borderRadius || [0, 0, 0, 0],
  });
  container.visible = params.visible;
  // todo: missing types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  container.autoLayout = params.autoLayout ?? true;

  addInteractivityToElement({
    id: params.id,
    element: container,
    defaultAttributes: { },
    hoveredAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
    activeAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
  });

  // @ts-ignore
  container.backgroundMaterial.depthTest = true;
  // @ts-ignore
  container.backgroundMaterial.depthWrite = false;

  container.userData.uiData = {
    id: params.id,
    interactive: true,
  };

  if (children.length) container.add(...children);

  return container;
}
