import * as Three from 'three';
import { Component, ComponentOptions } from '../Component';

// todo: make configurable, refactor
export class CameraComponent extends Component {
  public threeCamera: Three.PerspectiveCamera = new Three.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    0.01,
    100,
  );

  static get code(): string {
    return 'camera';
  }

  constructor(options: ComponentOptions) {
    super(options);
    this.threeCamera.position.set(0, 0, 0);
    this.threeCamera.lookAt(new Three.Vector3(0, 0, -1));
    this.entity.add(this.threeCamera);
  }

  public destroy() {
    this.threeCamera.removeFromParent();
  }
}
