import { System } from '../../engine/System';
import { AnimationComponent } from '../components/Animation.component';

export class AnimationSystem extends System {
  onUpdate(ts: number) {
    const components = this.componentManager.getComponentsByType(AnimationComponent);
    components.forEach((component) => {
      component.update(ts / 5);
    });
  }
}
