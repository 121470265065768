import { Entity } from './Entity';
import { Application } from './Application';

export type EntityManagerOptions = {
  app: Application;
};

export class EntityManager {
  protected app: Application;

  constructor(options: EntityManagerOptions) {
    this.app = options.app;
  }

  public makeEntity(): Entity {
    return new Entity({ app: this.app });
  }
}
