import { Vector3 } from 'three';
import { Application } from '../../../engine/Application';

import randomSportJson from '../../../assets/json/randomSport.json';
import { Entity } from '../../../engine/Entity';
import StuffObject from '../../network/objects/StuffObject';
import { MainScene } from '../MainScene';
import { MeshRendererComponent } from '../../../engine/components/MeshRenderer.component';

function random(number: number): string {
  return String(Math.floor(Math.random() * number) + 1);
}

function generateStuffObjects(app: Application) {
  const entities: Entity[] = [];
  const promises: Promise<Entity | undefined>[] = [];
  randomSportJson.pos.forEach((position: number[], index) => {
    const url: string = randomSportJson.obj[random(3) as keyof typeof randomSportJson.obj][0];
    // const pos = new Vector3(-13, 1, -3);
    const pos = new Vector3(position[0], position[2], -position[1]);
    const netObj = StuffObject.build(app, `stuff_${index}`, url, pos);

    if (netObj) {
      const avatarEntity = (app.sceneManager.currentScene as MainScene).characterEntity?.children[0] as Entity;
      const promise = new Promise<Entity | undefined>((resolve) => {
        avatarEntity.getComponent(MeshRendererComponent)?.events.once('contentAdded', () => {
          const entity = StuffObject.createEntityPrefab(app, url, pos);
          // no network
          // if (entity && !app.networkManager) {
          //   entities.push(entity);
          // }
          if (entity && netObj) {
            entity.name = netObj.code;
            netObj.attachToEntity(entity);
            netObj.getVariableByName('stuff')?.setNeedUpdateFromLocal();
            entities.push(entity);
          }
          return resolve(entity);
        });
      });
      promises.push(promise);
    }
  });
  return Promise.all(promises).then(() => entities);
}

export function generateStuff(app: Application) {
  return new Promise<Entity[]>((resolve) => {
    return resolve(generateStuffObjects(app));
  });
}
