import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const RoundIcon: FC<IconsType> = memo((props) => {
  const {
    height,
    width,
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 28C2 42.3594 13.6406 54 28 54C42.3594 54 54 42.3594 54 28C54 13.6406 42.3594 2 28 2C13.6406 2 2 13.6406 2 28ZM28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0Z"
        fill="white"
      />
    </svg>

  );
});

export default RoundIcon;
