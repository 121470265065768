import { FC } from 'react';
import classNames from './UnionModal.module.css';

export interface UserNameProps {
  name: string;
}

const UserName: FC<UserNameProps> = (props) => {
  const { name } = props;

  return (
    <div className={classNames.userName}>{name}</div>
  );
};

export default UserName;
