import * as THREE from 'three';
import { PressToESC } from './PressToESC.js';

class VideoScene {
  constructor(inVR, ThreeMeshUI) {
    this.inVR = inVR;
    this.camera = {};
    this.scene = {};
    // this.renderer;
    this.video = document.getElementById('playerVideo');
    this.mesh360 = undefined;
    this.notification = undefined;
    this.meshFullScreen = undefined;
  }

  init() {
    this.video.play();

    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100);
    this.camera.lookAt(new THREE.Vector3(-1, 0, 0));

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);

    // sphere
    const geometry = new THREE.SphereGeometry(500, 60, 40);
    geometry.scale(-1, 1, 1);

    const texture = new THREE.VideoTexture(this.video);
    const material = new THREE.MeshBasicMaterial({ map: texture });

    const mesh360 = new THREE.Mesh(geometry, material);

    this.scene.add(mesh360);
    this.mesh360 = mesh360;

    const geometry2 = new THREE.PlaneGeometry(16, 9);
    geometry2.scale(-1, 1, 1);

    const meshFullScreen = new THREE.Mesh(geometry2, material);
    meshFullScreen.rotateY(-Math.PI / 2);
    this.scene.add(meshFullScreen);
    this.meshFullScreen = meshFullScreen;

    const dist = 6;
    const cwd = new THREE.Vector3();
    this.camera.getWorldDirection(cwd);
    cwd.multiplyScalar(dist);
    cwd.add(this.camera.position);
    meshFullScreen.position.set(cwd.x, cwd.y, cwd.z);
    this.scene.playerWatchingVideo = true;
    this.mesh360.visible = false;
    this.meshFullScreen.visible = false;
    this.scene.add(this.camera);
    this.notification = new PressToESC(this.scene, this.camera);
  }

  update(video_info, mode) {
    this.scene.playerWatchingVideo = true;
    this.camera.lookAt(new THREE.Vector3(-1, 0, 0));
    if (this.inVR) {
      this.notification.create('Press Y or B to exit');
    } else if (!this.inVR && window.innerWidth > 900) {
      if (window.innerWidth < 900) this.notification.create('Press to exit');
      else this.notification.create('Press ESC to exit');
    }

    if (mode === 'full') {
      this.mesh360.visible = false;
      this.meshFullScreen.visible = true;
    } else if (mode === '360') {
      this.mesh360.visible = true;
      this.meshFullScreen.visible = false;
    }

    // defs
    this.video.autoplay = false;
    this.video.controls = false;
    this.video.loop = true;
    this.video.style = 'display:none';

    if (this.video.canPlayType('video/mp4')) {
      this.video.src = video_info[0];
      // this.video.src = "./vidLib_local/video_1.mp4";
    } else if (this.video.canPlayType('video/ogg')) {
      this.video.src = video_info[1];
      // this.video.src = "./vidLib_local/video_1.ogv";
    }
    this.video.play();
  }

  render() {
    this.notification.update();
  }

  stopAudio() {
    this.video.muted = true;
  }

  startAudio() {
    this.video.muted = false;
  }
}

export { VideoScene };
