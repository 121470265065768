import * as THREE from 'three';

class EcommerceEvents {

    constructor(renderer) {
        this.renderer = renderer;
    }

    exitVR() {
        let session = this.renderer.xr.getSession();
        if (session) session.end();
    }

    buttonEvents(productId, button) {
        this.exitVR();

        //shop pages: product
        this.wpPostEvent('product', { key: productId, subkey: button })
    }

    wallEvents(button) {
        this.exitVR();

        //shop pages: category
        this.wpPostEvent('category', { key: button })
    }

    wpPostEvent(type, data) {
        console.log(type, data);
        window.parent.dispatchEvent(new CustomEvent('wp_iframe_shop', {
            detail: {
                type,
                ...data
            }
        }))
    }
}

export { EcommerceEvents };