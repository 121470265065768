import { Vector3 } from 'three';
import InterpolationBuffer from './InterpolationBuffer';

export type AnimationBufferValue = {
  actionName: string;
  parameters: Record<string, boolean | number>;
};

export default class AnimatorBuffer extends InterpolationBuffer<AnimationBufferValue> {
  interpolateBetween(t: number,
    v1: AnimationBufferValue,
    t1: number, v2: AnimationBufferValue,
    t2: number): AnimationBufferValue {
    if (!v1) return v2;
    if (!v2) return v1;
    return v1;
    const alfa = (t - t1) / (t2 - t1);
    const result: AnimationBufferValue = { actionName: v2.actionName, parameters: {} };
    Object.keys(v2.parameters).forEach((pname) => {
      result.parameters[pname] = (1 - alfa) * <number>v1.parameters[pname] + alfa * <number>v2.parameters[pname];
    });
    return result;
  }

  interpolateFuture(t: number,
    v1: AnimationBufferValue,
    t1: number, v2: AnimationBufferValue,
    t2: number): AnimationBufferValue {
    return v2;
  }
}
