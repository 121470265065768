import ThreeMeshUI from 'three-mesh-ui';
import * as THREE from 'three';
import { textContent } from 'domutils';
import * as Three from 'three';
import FontJSON from '../../../../assets/fonts/Roboto-msdf.json';
import FontImage from '../../../../assets/fonts/Roboto-msdf.png';
import { setAbsolutePosition } from '../helpers/setAbsolutePosition';
import { addInteractivityToElement } from '../helpers/addInteractivityToElement';

// todo: надо прокинуть параметры

export type TextParams = {
  absolutePosition?: THREE.Vector2;
  id: string;
};

const Text = (params: TextParams): ThreeMeshUI.Block => {
  const TextContainer = new ThreeMeshUI.Block({
    width: 500,
    height: 50,
    padding: 5,
    justifyContent: 'center',
    backgroundOpacity: 0,
    textAlign: 'center',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fontFamily: FontJSON,
    fontTexture: FontImage,
  });

  if (params.absolutePosition) {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    TextContainer.autoLayout = false;
    TextContainer.addEventListener('added', () => {
      if (TextContainer.parent) setAbsolutePosition(TextContainer.parent, TextContainer, params.absolutePosition);
    });
  }

  addInteractivityToElement({
    id: params.id,
    element: TextContainer,
    defaultAttributes: { },
    hoveredAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
    activeAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
  });

  const text = new ThreeMeshUI.Text({
    content: '',
    fontSize: 100,
    fontColor: new THREE.Color(0xFFFFFF),
  });

  TextContainer.userData.uiData = {
    id: params.id,
    interactive: true,
  };

  TextContainer.add(text);

  return TextContainer;
};

export default Text;
