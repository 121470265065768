import axios from 'axios';

const client = axios.create({
  baseURL: 'https://session.voxeet.com/v1/oauth2/',
});

client.interceptors.response.use((config) => {
  return config.data;
});

export default client;
