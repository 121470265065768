import { Constants } from '@webxr-input-profiles/motion-controllers';
import { Vector3 } from 'three';
import { System } from '../../engine/System';
import { StuffComponent, StuffStatus } from '../components/Stuff.component';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import RaycastComponent from '../components/Raycast.component';
import { InputSystem } from '../../engine/systems/InputSystem';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { MeshRendererComponent } from '../../engine/components/MeshRenderer.component';
import NetworkTransformComponent from '../../engine/components/NetworkTransform.component';
import { Ammo } from '../../engine/physics/AmmoLoader';

export class StuffSystem extends System {
  onUpdate(dt: number) {
    super.onUpdate(dt);

    const components = this.componentManager.getComponentsByType(StuffComponent);
    components.forEach((stuffComponent) => {
      // TODO: cache operation
      stuffComponent.setupTargets(this.app);

      if (this.checkRemoveObject(stuffComponent)) return;

      switch (stuffComponent.state.status) {
        case StuffStatus.Waiting:
          // set current state
          this.resetStuff(stuffComponent);
          // check action to change state
          if (this.canTake(stuffComponent)) {
            stuffComponent.setState({
              activeUserId: this.app.networkManager?.networkId,
              status: StuffStatus.Taken,
            });
          }
          break;
        case StuffStatus.Taken:
          // set current state
          this.take(stuffComponent);
          // check action to change state
          if (this.canDrop(stuffComponent)) {
            stuffComponent.setState({
              activeUserId: this.app.networkManager?.networkId,
              status: StuffStatus.Dropped,
            });
          }
          break;
        case StuffStatus.Dropped:
          // set current state
          this.drop(stuffComponent);
          if (this.isNotMove(stuffComponent)) {
            stuffComponent.setState({
              activeUserId: '',
              status: StuffStatus.Waiting,
            });
          }
          break;
        default:
          break;
      }
    });
  }

  public checkRemoveObject(stuffComponent: StuffComponent): boolean {
    if (
      stuffComponent.state.activeUserId
      && stuffComponent.netObject?.isOwner()
      && !this.app.networkManager?.isUserOnline(stuffComponent.state.activeUserId)
    ) {
      stuffComponent.setState({
        activeUserId: '',
        status: StuffStatus.Waiting,
      });
      return true;
    }
    return false;
  }

  public isStuffAtCurrentUser(stuffComponent: StuffComponent): boolean {
    return stuffComponent.state.activeUserId === this.app.networkManager?.networkId;
  }

  public isNotMove(stuffComponent: StuffComponent): boolean {
    if (this.isStuffAtCurrentUser(stuffComponent)) {
      return !stuffComponent.isMoving(stuffComponent.entity.position);
    }
    return false;
  }

  public drop(stuffComponent: StuffComponent) {
    this.toggleTakenObjects(stuffComponent, false);
    if (!stuffComponent.attached) return;
    this.detach(stuffComponent);
    this.setupTransforms(stuffComponent);
    stuffComponent.entity.getComponentOrFail(RigidBodyComponent).disable();
    if (this.isStuffAtCurrentUser(stuffComponent)) {
      this.throwObject(stuffComponent);
    }
    stuffComponent.attached = false;
  }

  public detach(stuffComponent: StuffComponent) {
    let position = new Vector3();
    stuffComponent.entity.getWorldPosition(position);
    const parent = stuffComponent.originParent || this.app.sceneManager.currentThreeScene;
    if (parent) {
      parent.add(stuffComponent.entity);
      position = parent.worldToLocal(position);
    }
    stuffComponent.entity.position.copy(position);

    // TODO: detach hand objects ??
  }

  public throwObject(stuffComponent: StuffComponent) {
    const rigid = stuffComponent.entity.getComponentOrFail(RigidBodyComponent);
    rigid.applyEntityWorldMatrix();
    // rigid.btRigidBody?.setActivationState(AmmoActivationState.DISABLE_DEACTIVATION);
    const ms = new Ammo.btDefaultMotionState();
    if (rigid.btRigidBody?.getWorldTransform()) ms.setWorldTransform(rigid.btRigidBody?.getWorldTransform());
    rigid.btRigidBody?.setMotionState(ms);
    // rigid.btRigidBody?.setCenterOfMassTransform(rigid.btRigidBody?.getWorldTransform());

    rigid.enable();

    // TODO: get direction from controller move
    const direction = new Vector3();
    // if (this.app.isInVR) {
    //   const cwp = new Vector3();
    //   component.entity.getWorldPosition(cwp);
    //   direction.copy(cwp.sub(component.entityPosition));
    // } else {
    this.app.camera?.getWorldDirection(direction);
    // }
    direction.normalize().multiplyScalar(7);
    rigid.setLinearVelocity({
      x: direction.x,
      y: direction.y,
      z: direction.z,
    });
    const angular = new Vector3(Math.random(), Math.random(), Math.random());
    angular.normalize().multiplyScalar(2);
    rigid.btRigidBody?.setAngularVelocity(new Ammo.btVector3(
      angular.x,
      angular.y,
      angular.z,
    ));
    // rigid.btRigidBody?.applyTorqueImpulse(new Ammo.btVector3(
    //   angular.x,
    //   angular.y,
    //   angular.z,
    // ));
    // rigid.btRigidBody?.applyCentralImpulse(new Ammo.btVector3(
    //   direction.x,
    //   direction.y,
    //   direction.z,
    // ));
    // rigid.btRigidBody?.app
    // const collider = component.entity.getComponentOrFail(ColliderComponent);
    rigid.btRigidBody?.setRollingFriction(1);
    // rigid.btRigidBody?.setAnisotropicFriction(collider.btCollisionShape?.getAnisotropicRollingFrictionDirection(),
    // btCollisionObject.CF_ANISOTROPIC_ROLLING_FRICTION);
    rigid.btRigidBody?.setDamping(0.0, 0.7);

    // FIXME: need to reset motionstate memory
    // this.app.getSystem(PhysicSystem)?.onUpdate(0.001);
  }

  public canDrop(stuffComponent: StuffComponent): boolean {
    if (this.isStuffAtCurrentUser(stuffComponent)) {
      return this.isInputSystemActiveForDrop();
    }
    return false;
  }

  public take(stuffComponent: StuffComponent) {
    this.toggleTakenObjects(stuffComponent, true);
    if (stuffComponent.attached) return;
    if (!this.attach(stuffComponent)) return;
    stuffComponent.entity.getComponentOrFail(RigidBodyComponent).disable();
    this.setupTransforms(stuffComponent);
    stuffComponent.attached = true;
  }

  public setupTransforms(stuffComponent: StuffComponent) {
    const transformComponent = stuffComponent.entity.getComponentOrFail(NetworkTransformComponent);
    transformComponent.enable();
    if (stuffComponent.state.activeUserId === this.app.networkManager?.networkId) {
      transformComponent.setSourceType();
    } else {
      transformComponent.setTargetType();
    }
  }

  public toggleTakenObjects(stuffComponent: StuffComponent, value: boolean) {
    const mainObject = stuffComponent.entity.getComponent(MeshRendererComponent)?.getMesh();
    if (mainObject) {
      mainObject.visible = !value;
      stuffComponent.entity.getComponent(StuffComponent)?.getHandObjects().forEach((obj) => {
        obj.visible = value;
      });
    }
  }

  public attach(stuffComponent: StuffComponent): boolean {
    if (!stuffComponent.attachToObjectRight || !stuffComponent.attachToObjectLeft) return false;
    stuffComponent.originParent = stuffComponent.entity.parent;
    stuffComponent.entity.position.set(0, 0, 0);
    stuffComponent.entity.rotation.set(0, 0, 0);
    stuffComponent.attachToObjectRight.add(stuffComponent.entity);

    const handObjects = stuffComponent.entity.getComponent(StuffComponent)?.getHandObjects();
    handObjects?.forEach((obj, index) => {
      if (!obj) return;
      obj.position.set(0, 0, 0);
      const target = index === 0 ? stuffComponent.attachToObjectRight : stuffComponent.attachToObjectLeft;
      target?.add(obj);
    });
    return true;
  }

  public resetStuff(stuffComponent: StuffComponent) {
    const rigidComponent = stuffComponent.entity.getComponentOrFail(RigidBodyComponent);
    if (stuffComponent.netObject) {
      stuffComponent.netObject.position = stuffComponent.entity.position.clone();
    }
    if (rigidComponent.enabled) {
      rigidComponent.applyEntityWorldMatrix();
    }
    rigidComponent.disable();
    stuffComponent.entity.rotation.set(0, 0, 0);
    const transformComponent = stuffComponent.entity.getComponent(NetworkTransformComponent);
    transformComponent?.disable();
    if (stuffComponent.attached) {
      this.detach(stuffComponent);
      stuffComponent.attached = false;
    }
    this.toggleTakenObjects(stuffComponent, false);
  }

  public canTake(stuffComponent: StuffComponent) {
    return this.componentManager.getComponentsByType(StuffComponent).every((component) => {
      return component.state.activeUserId !== this.app.networkManager?.networkId;
    })
      && this.ifCloseToTarget(stuffComponent)
      && this.isInputSystemActiveForTake();
  }

  public isInputSystemActiveForTake(): boolean {
    if (this.app.renderer.xr.isPresenting) {
      const xRInputSystem = this.app.getSystemOrFail(XRInputSystem);
      const rightState = xRInputSystem.getRightXrStandardSqueeze().state;
      const leftState = xRInputSystem.getLeftXrStandardSqueeze().state;
      const passed = Constants.ComponentState.PRESSED;
      return rightState === passed || leftState === passed;
    }
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    return inputSystem.touchscreen.primaryTouchSensor.pressSensor.isPressed || inputSystem.mouse.leftButton.wasPressedThisFrame;
  }

  public isInputSystemActiveForDrop() {
    if (this.app.renderer.xr.isPresenting) {
      const xRInputSystem = this.app.getSystemOrFail(XRInputSystem);
      return xRInputSystem.getRightXrStandardSqueeze().state !== Constants.ComponentState.PRESSED
        && xRInputSystem.getLeftXrStandardSqueeze().state !== Constants.ComponentState.PRESSED;
    }
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    return inputSystem.keyboard.getKeyByCode('Space').wasPressedThisFrame;
  }

  public ifCloseToTarget(stuffComponent: StuffComponent): boolean {
    // TODO: need test -- not work on VR !!!!
    if (this.app.isInVR) {
      const xRInputSystem = this.app.getSystemOrFail(XRInputSystem);
      let result = false;
      xRInputSystem.raySpaces.forEach((grip, index) => {
        if (result) return;
        const state = index === 0
          ? xRInputSystem.getLeftXrStandardSqueeze().state
          : xRInputSystem.getRightXrStandardSqueeze().state;
        const pressed = state === Constants.ComponentState.PRESSED;
        const gripPosition = new Vector3();
        grip.getWorldPosition(gripPosition);
        const takePosition = new Vector3();
        stuffComponent.entity.getWorldPosition(takePosition);
        if (pressed && gripPosition.distanceTo(takePosition) <= stuffComponent.distanceForGrip) {
          result = true;
          stuffComponent.attachToObjectRight = grip;
        }
      });
      return result;
    }

    // TODO: need test
    const raycast = stuffComponent.entity.getComponentOrFail(RaycastComponent);
    const distance = stuffComponent.attachToObjectRight?.getWorldPosition(new Vector3())
      .distanceTo(stuffComponent.entity.getWorldPosition(new Vector3())) || 100;
    return raycast.isActive
      && !!raycast.state.intersections
      && distance <= stuffComponent.takeDistance;
  }
}
