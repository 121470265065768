import { ComponentManager } from './ComponentManager';
import { Application } from './Application';
import { EntityManager } from './EntityManager';

export type SystemOptions = {
  app: Application;
  enabled?: boolean;
};

export class System {
  protected app: Application;

  // think about it
  static get code(): string {
    return 'system';
  }

  constructor(options: SystemOptions) {
    this.app = options.app;
  }

  public onUpdate(dt: number): void {

  }

  public onPhysicUpdate(dt: number): void {

  }

  public destroy(): void {

  }

  public onAfterRender(dt: number): void {

  }

  protected get componentManager(): ComponentManager {
    return this.app.componentManager;
  }

  protected get entityManager(): EntityManager {
    return this.app.entityManager;
  }
}
