import { StuffComponent, StuffStatus } from '../../components/Stuff.component';
import EngineVariable from './EngineVariable';

export type StuffVariableType = {
  activeUserId?: string;
  status: StuffStatus;
};

export default class StuffVariable extends EngineVariable<StuffVariableType> {
  public static type = 'stuff';

  public autoSync = true;

  public required = true;

  constructor(name = 'stuff') {
    super(name);
  }

  public getDefault(): StuffVariableType {
    return {
      activeUserId: '',
      status: StuffStatus.Waiting,
    };
  }

  public reset() {
    // this.set(this.getDefault());
  }

  // then local changed --> update variable == send state to network
  public updateFromLocal(): boolean {
    const { app } = StuffVariable;
    if (!app) return false;
    const component = this.getComponent(app, StuffComponent);
    if (!component) return false;

    const value: StuffVariableType = { ...component.state };
    this.set(value);
    console.log('Set status from local');
    return true;
  }

  // then receive changed from network -> get variable state & update local values
  public updateFromNetwork(): boolean {
    const { app } = StuffVariable;
    if (!app) return false;
    const component = this.getComponent(app, StuffComponent);
    if (!component) return false;
    if (!this.value) return false;

    component.setState(this.value, false);
    console.log('Set status from network');
    return true;
  }
}
