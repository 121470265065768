import { FC } from 'react';
import classNames from './ModalHeader.module.css';
import CloseIcon from '../../Icons/CloseIcon';

export interface ModalProps {
  title: string;
  onClose: () => void;
}

const ModalHeader: FC<ModalProps> = (props) => {
  const {
    title = 'Meeting Chat',
    onClose = () => undefined,
  } = props;

  return (
    <div className={classNames.root}>
      <div className={classNames.header}>
        <div className={classNames.title}>{title}</div>
        <div
          className={classNames.closeIcon}
          onClick={onClose}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
