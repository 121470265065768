import { FC } from 'react';
import { IconsType } from './IconsType';

const HandIcon: FC<IconsType> = (props) => {
  const {
    width = '24',
    height = '24',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6V4.5C13 3.67157 12.3284 3 11.5 3V3C10.6716 3 10 3.67157 10 4.5V12M13 11V5.5C13 4.67157 13.6716 4 14.5 4V4C15.3284 4 16 4.67157 16 5.5V10M10 7V5.5C10 4.67157 9.32843 4 8.5 4V4C7.67157 4 7 4.67157 7 5.5V11.5M7 14V11.5C7 10.6716 6.32843 10 5.5 10V10C4.67157 10 4 10.6716 4 11.5V13.5C4 16 5.5 21 11.5 21C14 21 19 19.5 19 13.5V9.5C19 8.67157 18.3284 8 17.5 8V8C16.6716 8 16 8.67157 16 9.5V12"
        stroke="black"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>

  );
};

export default HandIcon;
