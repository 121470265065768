import { VideoSourceType } from '../../services/VideoController';
import VideoTextureComponent from '../../components/VideoTexture.component';
import EngineVariable from './EngineVariable';

export type VideoVariableValueType = {
  source?: VideoSourceType;
  isPlaying: boolean;
  videoTime: number;
  localTime: number;
};

export default class VideoVariable extends EngineVariable<VideoVariableValueType> {
  public static type = 'video';

  public required = true;

  public autoSync = true;

  constructor(name = 'video') {
    super(name);
  }

  public reset() {
    const component = this.getComponent(VideoVariable.app, VideoTextureComponent);
    if (component) {
      this.set(component.videoState);
    }
  }

  // then local changed --> update variable == send state to network
  public updateFromLocal(): boolean {
    const { app } = VideoVariable;
    if (!app) return false;
    const component = this.getComponent(app, VideoTextureComponent);
    if (!component || !component.enabled) return false;

    console.log('video local changed');
    const state = component.videoState;
    this.set(state);
    return true;
  }

  // then receive changed from network -> get variable state & update local values
  public updateFromNetwork(): boolean {
    const { app } = VideoVariable;
    if (!app) return false;
    const component = this.getComponent(app, VideoTextureComponent);
    if (!component || !component.enabled) return false;
    if (!this.value) return false;

    console.log('video network sync');
    component.setState(this.value);
    return true;
  }
}
