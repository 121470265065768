import THREE, { Vector3 } from 'three';
import { System } from '../../engine/System';
import { SeatComponent } from '../components/Seat.component';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { Entity } from '../../engine/Entity';
import { TPControllerComponent } from '../components/TPController.component';
import { FPControllerComponent } from '../components/FPController.component';
import { XRFPControllerComponent } from '../components/XRFPController.component';
import { PlayerComponent } from '../components/Player.component';

export class SeatSystem extends System {
  onUpdate(ts: number) {
    super.onUpdate(ts);
  }

  public takeSeat = (obj: THREE.Mesh, target: string) => {
    const characterEntity = this.app.sceneManager.currentThreeScene?.getObjectByName('CharacterEntity');
    if (!characterEntity) return;
    this.componentManager.getComponentsByType(SeatComponent)
      .forEach((component) => {
        component.playerIsSeat = true;
        console.log(characterEntity.position);
        component.beforeSeatPosition.copy(characterEntity.position);
        (characterEntity as Entity).getComponent(RigidBodyComponent)?.disable();

        // @ts-ignore
        if (characterEntity.seatingOnObject)characterEntity.seatingOnObject.visible = true;
        const seatwp = new Vector3();
        console.log(2, obj.position, obj.getWorldPosition(seatwp), seatwp);
        obj.getWorldPosition(seatwp);

        characterEntity.position.set(seatwp.x, seatwp.y, seatwp.z);

        obj.visible = false;
        const lookAtPosition = this.app.sceneManager.currentThreeScene?.getObjectByName(target)?.position;
        if (!lookAtPosition) return;
        characterEntity.lookAt(lookAtPosition);

        characterEntity.rotation.y += Math.PI;
        characterEntity.rotation.x = 0;
        // @ts-ignore
        characterEntity.seatingOnObject = obj;

        const tPControllerComponent = (characterEntity as Entity).getComponentOrFail(TPControllerComponent);
        const fPControllerComponent = (characterEntity as Entity).getComponentOrFail(FPControllerComponent);
        const xrfPControllerComponent = (characterEntity as Entity).getComponentOrFail(XRFPControllerComponent);
        tPControllerComponent.isInitialized = false;
        fPControllerComponent.isInitialized = false;
        xrfPControllerComponent.isInitialized = false;
      });
  };
}
