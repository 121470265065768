import { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from '@mui/material';
import classNames from './UnionModal.module.css';
import DublicateIcon from '../../components/Icons/DublicateIcon';

export interface InviteRoomProps {
  link?: string;
}

const InviteRoom: FC<InviteRoomProps> = (props) => {
  const {
    link = '/',
  } = props;

  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      if (isOpenTooltip) setIsOpenTooltip(false);
    }, 1000);
  }, [isOpenTooltip]);

  return (
    <div className={classNames.inviteRoom}>
      <div className={classNames.inviteText}>You can share link to invite friends.</div>
      <div className={classNames.clipboard}>
        <div className={classNames.link}>{link}</div>
        <CopyToClipboard
          onCopy={() => setIsOpenTooltip(true)}
          text={link}
        >
          <Tooltip
            open={isOpenTooltip}
            placement="top"
            title="Copied Link!"
          >
            <div className={classNames.dublicateIcon}>
              <DublicateIcon
                width="24"
                height="24"
              />
            </div>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default InviteRoom;
