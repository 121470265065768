import { Object3D, Vector3 } from 'three';
import * as Three from 'three';
import SystemObject from '../../../engine/network/SystemObject';
import { NetworkObjectLifeTimeTypes } from '../../../engine/network/NetworkObject';
import NetworkManager from '../../../engine/network/NetworkManager';
import { Application } from '../../../engine/Application';
import VideoVariable, { VideoVariableValueType } from '../variables/VideoVariable';
import { Entity } from '../../../engine/Entity';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import VideoTextureComponent from '../../components/VideoTexture.component';
import { VideoSourceType } from '../../services/VideoController';

export default class VideoObject extends SystemObject {
  public static type = 'videoobject';

  public lifeTimeType = NetworkObjectLifeTimeTypes.Shared;

  static register(manager: NetworkManager) {
    manager.objectsTypes[VideoObject.type] = VideoObject;
  }

  static build(app: Application, code: string): VideoObject | null {
    if (!app.networkManager) return null;
    if (!app.networkManager.isRoomHost) return null;

    const netObj = app.networkManager?.buildSharedObject<VideoObject>(VideoObject.type, code);
    netObj.setApplication(app);
    netObj.addVariables();
    return netObj;
  }

  public addVariables() {
    this.addVariable<VideoVariableValueType>(new VideoVariable());
  }

  public attachToEntity(entity: Entity) {
    entity.addComponent(NetworkObjectComponent, {
      netObject: this,
    });
  }

  public static createEntityPrefab(
    app: Application,
    scene: Three.Scene | null,
    objectName: string,
    source?: VideoSourceType,
  ): Entity | undefined {
    if (!scene) return;
    const object = scene.getObjectByName(objectName);
    if (!object) return;

    const entity = app.entityManager.makeEntity();

    // replace object by entity
    // TODO: move to EntityManager
    entity.copy(object as Entity);
    object.parent?.add(entity);
    object.removeFromParent();
    entity.attach(object);

    entity.addComponent(VideoTextureComponent, {
      targetMesh: object as Three.Mesh,
      source,
    });
    return entity;
  }

  public spawnEntity(
    position: Vector3 | undefined = undefined,
  ): Object3D | undefined {
    if (!this.app) return;
    const videoVar = this.getVariableByName('video');
    // console.log(videoVar);
    if (!videoVar) return;
    const entity = VideoObject.createEntityPrefab(
      this.app,
      this.app.sceneManager.currentThreeScene,
      this.code,
      videoVar.value ? videoVar.value.source : undefined,
    );
    if (entity) {
      this.attachToEntity(entity);
      // entity.getComponent(VideoTextureComponent)?.setState(videoVar.value);
      videoVar.setNeedUpdateFromNetwork();
    }
    return entity;
  }
}
