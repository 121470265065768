import * as ThreeVRM from '@pixiv/three-vrm';
import EventEmitter from 'eventemitter3';
import { GLTF as ThreeGLTF, GLTFLoader as ThreeGLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as Three from 'three';
import { AnimationClip } from 'three';
import { Component, ComponentEventTypes, ComponentOptions } from '../Component';
import { ThreeMemoryCleaner } from '../services/ThreeMemoryCleaner';

export type MeshComponentEventTypes = ComponentEventTypes & {
  contentAdded: (payload: { content: Three.Object3D; animations?: AnimationClip[] }) => void;
};

export enum AssetSourceType {
  GLTF = 'GLTF',
  VRM = 'VRM',
}

export type AssetSourceDataVRM = {
  type: AssetSourceType.VRM;
  url: string;
};

export type AssetSourceDataGLTF = {
  type: AssetSourceType.GLTF;
  url: string;
};

export type AssetSourceData = AssetSourceDataGLTF | AssetSourceDataVRM;

export type MeshComponentOptions = ComponentOptions & {
  data?: {
    color?: Three.Color;
    sourceData?: AssetSourceData;
    needFirstPersonSetup?: boolean;
  };
};

// todo: refactor
export class MeshRendererComponent extends Component {
  static cache: Record<string, Promise<ThreeGLTF>> = {};

  public needFirstPersonSetup = true;

  protected _sourceData: AssetSourceData;

  protected _events: EventEmitter<MeshComponentEventTypes> = new EventEmitter<MeshComponentEventTypes>();

  protected gltfLoader: ThreeGLTFLoader = new ThreeGLTFLoader();

  protected vrmLoader: ThreeGLTFLoader = new ThreeGLTFLoader();

  // make material (?) component
  protected _color: Three.Color | null = null;

  protected _vrm?: ThreeVRM.VRM; // todo: temporary, need vrm controller component

  public data = new Three.Group();

  constructor(options: MeshComponentOptions) {
    super(options);

    this.vrmLoader.register((parser) => new ThreeVRM.VRMLoaderPlugin(parser));
    this._sourceData = options.data?.sourceData || { type: AssetSourceType.GLTF, url: '' };
    this._color = options.data?.color ?? null;
    this.needFirstPersonSetup = typeof options.data?.needFirstPersonSetup !== 'undefined'
      ? options.data.needFirstPersonSetup : this.needFirstPersonSetup;
    this.load();
  }

  public get events(): EventEmitter<MeshComponentEventTypes> {
    return this._events;
  }

  static get code(): string {
    return 'mesh_renderer';
  }

  public get color(): Three.Color | null {
    return this._color;
  }

  public set color(color: Three.Color | null) {
    this._color = color;
    this.updateColor();
  }

  public destroy() {
    this.data.removeFromParent();
    ThreeMemoryCleaner.disposeThreeGraph(this.data);
  }

  public getVRM(): ThreeVRM.VRM | undefined {
    return this._vrm;
  }

  public getMesh(): Three.Mesh | undefined {
    let mesh: Three.Mesh | undefined;
    this.data.traverse((obj) => {
      if (obj instanceof Three.Mesh && !mesh) {
        mesh = obj;
      }
    });
    return mesh;
  }

  protected updateColor(): void {
    this.data.traverse((object) => {
      if (!(object instanceof Three.Mesh)) return;
      if (!(object.material instanceof Three.MeshStandardMaterial)) return;

      // todo: fast fix, need material management functionally-
      if (object.material.name.endsWith('_mat')) {
        if (object.material.name.endsWith('_img_mat')) return;
        object.material.color.copy(this._color || new Three.Color());
        object.material.needsUpdate = true;
      }
    });
  }

  public changeSource(source: AssetSourceData): void {
    this._sourceData.type = source.type;
    this._sourceData.url = source.url;
    this.load();
  }

  protected load(): void {
    switch (this._sourceData.type) {
      case AssetSourceType.GLTF:
        this.loadGLTF();
        break;
      case AssetSourceType.VRM:
        this.loadVRM();
        break;
      default:
        throw new Error('Mesh renderer type is not supported');
    }
  }

  protected loadGLTF(): void {
    const { url } = this._sourceData;

    if (!url) return;

    // todo: REFACTORING
    // if (!MeshRendererComponent.cache[url]) {
    MeshRendererComponent.cache[url] = new Promise((resolve) => {
      this.gltfLoader.load(url, (gltf) => {
        resolve(gltf);
      }, (xhr) => {
        window.dispatchEvent(new CustomEvent('scene_prg', { detail: { prg: xhr.loaded / 14176560 * 100 } }));
      });
    });
    // }

    MeshRendererComponent.cache[url].then((gltf) => {
      const root = gltf.scene.clone(true);

      root.traverse((object) => {
        if (!(object instanceof Three.Mesh)) return;
        if (!(object.material instanceof Three.MeshStandardMaterial)) return;

        // need for coloring, bad performance (100500 materials per frame)
        object.material = object.material.clone();
      });

      this.data.add(...root.children);
      if (this._color) this.updateColor();
      this.entity.add(this.data);
      this.events.emit('contentAdded', { content: this.data, animations: gltf.animations });
    });
  }

  public clear() {
    ThreeMemoryCleaner.disposeThreeGraph(this.data);
    this.data.clear();
    this.data.removeFromParent();
  }

  public loadVRM(): void {
    const { url } = this._sourceData;

    if (!url) return;

    // todo: REFACTORING
    // if (!MeshRendererComponent.cache[url]) {
    MeshRendererComponent.cache[url] = new Promise((resolve) => {
      this.vrmLoader.load(url, (gltf) => {
        ThreeVRM.VRMUtils.removeUnnecessaryVertices(gltf.scene); // wtf?
        ThreeVRM.VRMUtils.removeUnnecessaryJoints(gltf.scene); // wtf?
        resolve(gltf);
      });
    });
    // }

    MeshRendererComponent.cache[url].then((gltf) => {
      // todo: VERY temporary, need vrm controller system
      if (gltf.userData.vrm) {
        this._vrm = gltf.userData.vrm;
        if (this.needFirstPersonSetup) {
          this._vrm?.firstPerson?.setup();
        }
        this._vrm?.springBoneManager?.reset();
        // todo: VERY VERY temporary, need vrm controller system
        // setInterval(() => {
        //   // this.vrm.springBoneManager?.update(1 / 60);
        //   this._vrm?.update(1 / 60);
        // }, (1 / 60) * 1000);
      }
      this.data.add(gltf.scene);
      this.entity.add(this.data);
      this.events.emit('contentAdded', { content: this.data });
    });
  }
}
