import * as Three from 'three';
import { Application } from '../../../engine/Application';
import VideoObject from '../../network/objects/VideoObject';

export type VideosDataType = {
  [key: string]: string[];
};

export function appendVideos(app: Application, scene: Three.Scene, data: VideosDataType) {
  Object.keys(data).forEach((objectName) => {
    const mp4 = data[objectName][0];
    const ogg = data[objectName][0];

    const netObj = VideoObject.build(app, objectName);
    if (netObj) {
      const entity = VideoObject.createEntityPrefab(app, scene, objectName, { mp4, ogg });
      if (entity) {
        netObj.attachToEntity(entity);
        netObj.getVariableByName('video')?.setNeedUpdateFromLocal();
      }
    }
  });
}
