import { useContext } from 'react';
import { AudioContext } from '../../context/AudioProvider/AudioProvider';
import { AudioContextType } from '../../context/AudioProvider/AudioProviderType';

export interface UseAudioProps {
  isAudio: AudioContextType['isAudio'];
  isLocalAudioLoading: AudioContextType['isLocalAudioLoading'];
  toggleAudio: AudioContextType['toggleAudio'];
  startParticipantAudio: AudioContextType['startParticipantAudio'];
  stopParticipantAudio: AudioContextType['stopParticipantAudio'];
}

const useAudio = (): UseAudioProps => {
  const {
    isAudio,
    toggleAudio,
    startParticipantAudio,
    stopParticipantAudio,
    isLocalAudioLoading,
  } = useContext(AudioContext);

  return {
    isLocalAudioLoading,
    isAudio,
    toggleAudio,
    startParticipantAudio,
    stopParticipantAudio,
  };
};

export default useAudio;
