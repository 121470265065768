import MessagesPool, { Message } from './MessagesPool';

export default class ReceiveMessagesPool extends MessagesPool {
  public lastTime: { [key: string] : number } = {};

  public receiveMessage(message: Message) {
    message.receiveTime = Date.now();
    // const timeDiff = message.receiveTime - message.serverSendTime;
    if (message.required) {
      this.requiredMessages.push(message);
      this.requiredMessages.sort((m1, m2) => {
        return m1.serverSendTime - m2.serverSendTime;
      });
    } else {
      this.messages.push(message);
    }
  }

  public getMessagesAtInterval(fromDelta = 0, toDelta = 0): Message[] {
    const now = Date.now();
    const messages = this.messages.filter((message) => {
      // TODO: timeDiff to interpolation
      const timeDiff = message.receiveTime - message.serverSendTime;
      message.timeDiff = timeDiff;
      const diffNow = now - timeDiff;
      return message.serverSendTime < (diffNow - toDelta)
        && message.serverSendTime > ((this.lastTime[message.data.type] - toDelta) || 0);

      // && message.serverSendTime < (startPoint + toDelta);
      // return (now - fromDelta) < message.serverSendTime && message.serverSendTime < (now - toDelta);
    });
    // console.log(messages);
    messages.sort((m1, m2) => {
      return m1.serverSendTime - m2.serverSendTime;
    });
    const messageByType = this.getMessagesByType(messages);
    if (messages.length > 0) {
      Object.keys(messageByType).forEach((type) => {
        const typeMessages = messageByType[type];
        // console.log('message pool count', type, typeMessages.length);
        this.lastTime[type] = typeMessages[typeMessages.length - 1].serverSendTime;
      });
    }
    // console.log('------');
    // console.log(this.messages.length, messages.length);
    this.messages = this.messages.filter((tm) => !messages.find((em) => tm.uid === em.uid));
    // console.log(this.messages.length);
    // console.log('------');
    return messages;
  }

  clear() {
    super.clear();
    this.clearRequiredMessages();
    // return;
    const now = Date.now();
    this.clearPoolByReceiveTime();
    this.clearPoolBySendTime();

    // Check duplicates
    // const uidCounts: { [key: string]: number } = {};
    // this.messages.forEach((message) => {
    //   if (typeof uidCounts[message.uid] === undefined) uidCounts[message.uid] = 0;
    //   uidCounts[message.uid] += 1;
    // });
    // Object.keys(uidCounts).forEach((uid) => {
    //   if (uidCounts[uid] > 1) console.log('duplicate!!!!', uid);
    // });

    // this.messages = this.messages.filter((message) => message.receiveTime - message.sendTime < 5000);
    // ????
    // this.messages = this.messages.filter((message) => message.sendTime > (this.lastTime[message.data.type] || now));
  }
}
