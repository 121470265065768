import * as Three from 'three';
import { XRControllerModel, XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { XRGripSpace, XRTargetRaySpace } from 'three/src/renderers/webxr/WebXRController';
import { Constants, MotionController, Component as ControllerComponent } from '@webxr-input-profiles/motion-controllers';
import { System, SystemOptions } from '../System';

export enum ControllerName {
  Left = 'left',
  Right = 'right',
  None = 'none',
}

type ControllerComponentValues = {
  state: Constants.ComponentState;
  button: number;
  xAxis: number;
  yAxis: number;
};

// todo: refactor, think about profiles and gamepad api
export class XRInputSystem extends System {
  public gripSpaces: XRGripSpace[] = [];

  public raySpaces: XRTargetRaySpace[] = [];

  public controllerModels: XRControllerModel[] = [];

  public motionControllers: MotionController[] = [];

  protected xRControllerModelFactory = new XRControllerModelFactory();

  static get code(): string {
    return 'x_r_input';
  }

  constructor(options: SystemOptions) {
    super(options);

    [0, 1].forEach((controllerIndex) => {
      this.buildController(controllerIndex);
    });
  }

  onUpdate(ts: number) {
    this.controllerModels.forEach((model, modelIndex) => {
      if (!model.motionController) return;

      this.motionControllers[modelIndex] = model.motionController;
    });
  }

  public getAButton(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'a-button');
  }

  public getBButton(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'b-button');
  }

  public getRightXrStandardThumbstick(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'xr-standard-thumbstick');
  }

  public getLeftXrStandardThumbstick(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Left, 'xr-standard-thumbstick');
  }

  public getRightXrStandardTrigger(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'xr-standard-trigger');
  }

  public getLeftXrStandardTrigger(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Left, 'xr-standard-trigger');
  }

  public getLeftXrStandardSqueeze(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Left, 'xr-standard-squeeze');
  }

  public getRightXrStandardSqueeze(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'xr-standard-squeeze');
  }

  public getComponent(controllerName: string, componentName: string): ControllerComponent | undefined {
    return this.getController(controllerName)?.components[componentName];
  }

  public getController(controllerName: string): MotionController | undefined {
    return this.motionControllers.find((controller) => {
      return controller && (controller.xrInputSource as XRInputSource)?.handedness === controllerName;
    });
  }

  public getRaySpace(controllerName: string): XRTargetRaySpace | undefined {
    return this.raySpaces[this.getControllerIndex(controllerName)];
  }

  public getGripSpace(controllerName: string): XRGripSpace | undefined {
    return this.gripSpaces[this.getControllerIndex(controllerName)];
  }

  public getComponentRequiredValues(controllerIndex: string, componentName: string): ControllerComponentValues {
    const component = this.getComponent(controllerIndex, componentName);

    if (!component) return this.getFallbackValues();

    return this.makeRequiredValues(component.values);
  }

  public getControllerNameByIndex(controllerIndex: number): ControllerName | undefined {
    const controller = this.motionControllers[controllerIndex];
    const controllerName = (controller?.xrInputSource as XRInputSource)?.handedness;

    if (!controllerName) return;
    if (!Object.values(ControllerName).includes(controllerName as ControllerName)) return;

    return controllerName as ControllerName;
  }

  public getFallbackValues(): ControllerComponentValues {
    return {
      state: Constants.ComponentState.DEFAULT,
      button: 0,
      xAxis: 0,
      yAxis: 0,
    };
  }

  public makeRequiredValues(values: ControllerComponent['values']): ControllerComponentValues {
    return {
      state: values.state,
      button: values.button ?? 0,
      xAxis: values.xAxis ?? 0,
      yAxis: values.yAxis ?? 0,
    };
  }

  protected buildController(controllerIndex: number): void {
    const gripSpace = this.app.renderer.xr.getControllerGrip(controllerIndex);
    const raySpace = this.app.renderer.xr.getController(controllerIndex);
    this.gripSpaces.push(gripSpace);
    this.raySpaces.push(raySpace);
    raySpace.add(this.buildRay());
    const controllerModel = this.xRControllerModelFactory.createControllerModel(gripSpace);
    this.controllerModels.push(controllerModel);
    gripSpace.add(controllerModel);

    raySpace.addEventListener('connected', (event) => {
      raySpace.add(this.buildRay());
    });
  }

  protected buildRay(): Three.Line {
    const geometry = new Three.BufferGeometry();
    geometry.setAttribute('position', new Three.Float32BufferAttribute([0, 0, 0, 0, 0, -2], 3));
    geometry.setAttribute('color', new Three.Float32BufferAttribute([0.5, 0.5, 0.5, 0, 0, 0], 3));

    const material = new Three.LineBasicMaterial({ vertexColors: true, blending: Three.AdditiveBlending });

    return new Three.Line(geometry, material);
  }

  protected getControllerIndex(controllerName: string): number {
    return this.motionControllers.findIndex((controller) => {
      if (!controller) return;
      return (controller.xrInputSource as XRInputSource).handedness === controllerName;
    });
  }
}
