import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { setAbsolutePosition } from '../helpers/setAbsolutePosition';
import { addInteractivityToElement } from '../helpers/addInteractivityToElement';

export type CircleButtonParams = {
  id: string;
  icon: ThreeMeshUI.Block;
  absolutePosition?: Three.Vector2;
  backgroundColor?: Three.Color;
  backgroundOpacity?: number;
  visible?: boolean;
  size: number;
};

export function CircleButton(params: CircleButtonParams, children: ThreeMeshUI.Block[] = []): ThreeMeshUI.Block {
  const button = new ThreeMeshUI.Block({
    width: params.size,
    height: params.size,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: params.size / 2,
    borderWidth: 0,
    backgroundColor: params.backgroundColor ?? new Three.Color().setStyle('#222222'),
    backgroundOpacity: params.backgroundOpacity ?? 0.85,
    offset: 0.1,
  });

  button.visible = params.visible ?? true;

  if (params.absolutePosition) {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    button.autoLayout = false;
    button.addEventListener('added', () => {
      if (button.parent) setAbsolutePosition(button.parent, button, params.absolutePosition);
    });
  }

  button.userData.uiData = {
    id: params.id,
    interactive: true,
  };

  addInteractivityToElement({
    id: params.id,
    element: button,
    defaultAttributes: { backgroundColor: params.backgroundColor ?? new Three.Color().setStyle('#222222') },
    hoveredAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
    activeAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
  });

  const iconWrapper = new ThreeMeshUI.Block({
    width: params.size,
    height: params.size,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    borderWidth: 0,
    backgroundOpacity: 0,
    offset: 0.1,
  });

  // todo: missing types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconWrapper.autoLayout = false;

  button.add(iconWrapper);
  iconWrapper.add(params.icon);

  if (children.length) button.add(...children);

  return button;
}
