import { FC, ReactNode, KeyboardEvent, forwardRef, useMemo } from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import classNames from './Modal.module.css';
import { PositionProps } from '../../containers/SportActions/LeftSide/LeftSideComponent';

export interface ModalProps {
  isOpen: boolean;
  children: ReactNode;
  position: PositionProps;
  height?: string;
  width?: string;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  center?: boolean;
}

const Modal: FC<ModalProps> = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const {
    isOpen = false,
    children,
    position,
    height = '630px',
    width = 'auto',
    onKeyDown = () => undefined,
    center = false,
  } = props;

  const variants = {
    closed: {
      opacity: 0,
      scale: 0.5,
      transition: { duration: 0.5 },
      transitionEnd: { display: 'none' },
    },
    open: {
      display: 'block',
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { duration: 0.5 },
    },
  };

  const styleRoot = useMemo(() => {
    const centerStyle = center && classNames.rootCenter;
    return [
      classNames.root,
      centerStyle,
    ].join(' ');
  }, [center]);

  return (
    <Box
      ref={ref}
      id="modal"
      onKeyDown={onKeyDown}
      style={{
        maxHeight: height, top: position.top, left: position.left, right: position.right, bottom: position.bottom, width,
      }}
      className={styleRoot}
      component={motion.div}
      variants={variants}
      animate={isOpen ? 'open' : 'closed'}
      initial={variants.closed}
    >
      {children}
    </Box>
  );
});

export default Modal;
