import * as Three from 'three';
import { VRM } from '@pixiv/three-vrm';
import { FBXLoader as ThreeFBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { Component as EngineComponent, ComponentOptions } from '../Component';
import { MeshRendererComponent } from './MeshRenderer.component';
import { VrmAnimationConverter } from '../services/VrmAnimationConverter';

export type AnimationActionData = {
  name: string;
  filters?: string[];
  clipsData: {
    name: string;
    clipName: string;
    speedMultiplier?: number;
    activeWeight?: number;
    startAt?: number;
    resizeTo?: number;
    bindings?: {
      activeWeight?: string;
      speedMultiplier?: string;
    };
  }[];
};

export type AnimationSourceData = {
  url: string;
  clipName: string; // todo: or index or array or something else
};

export enum AnimationFilterType {
  Bones = 'Bones',
  Rotation = 'Rotation',
}

export type AnimationFilterBaseOption = {
  name: string;
  type: AnimationFilterType;
  enabled?: boolean;
  bindings?: {
    enabled?: string;
  };
};

export type AnimationFilterBonesOption = AnimationFilterBaseOption & {
  type: AnimationFilterType.Bones;
  bones: Three.Object3D[];
};

export type AnimationFilterRotationOption = AnimationFilterBaseOption & {
  type: AnimationFilterType.Rotation;
  rotation: Record<string, Three.Quaternion>;
};

export type AnimationFilterOptions = AnimationFilterBonesOption | AnimationFilterRotationOption;

export type AnimationComponentOptions = ComponentOptions & {
  data?: {
    initialActionName?: string;
    animationSources?: AnimationSourceData[];
    actions?: AnimationActionData[];
    parameters?: Record<string, boolean | number>;
    animationFilters?: AnimationFilterOptions[];
  };
};

export type Filter = (clip: Three.AnimationClip, filter: AnimationFilterOptions) => void;

// todo: refactor!!!
export class AnimatorComponent extends EngineComponent {
  public animationSourcesData: AnimationSourceData[] = [];

  public actionsData: AnimationActionData[] = [];

  public threeFbxLoader = new ThreeFBXLoader();

  public threeAnimationClips: Three.AnimationClip[] = [];

  public threeAnimationClipsFiltered: Three.AnimationClip[] = [];

  public threeAnimationMixer = new Three.AnimationMixer(new Three.Object3D());

  public threeAnimationActions: Record<string, Three.AnimationAction[]> = {};

  public actionName = '';

  public isReady = false;

  public actionsWeight: Record<string, number> = {};

  public actionsActiveFilters: Record<string, Record<string, string[]>> = {};

  public actionClipsWight: Record<string, Record<string, number>> = {};

  public actionClipsOriginals: Record<string, Record<string, Three.AnimationClip>> = {};

  public parameters: Record<string, boolean | number> = {};

  public animationContents: Three.Group[] = [];

  public animationFiltersOptions: AnimationFilterOptions[] = [];

  public animationFilter: Record<string, Filter> = {
    [AnimationFilterType.Bones]: this.filterBones,
    [AnimationFilterType.Rotation]: this.filterRotation,
  };

  constructor(options: AnimationComponentOptions) {
    super(options);
    this.animationSourcesData = options.data?.animationSources ?? [];
    this.actionsData = options.data?.actions ?? [];
    this.actionName = options.data?.initialActionName || '';
    this.parameters = options.data?.parameters || {};
    this.animationFiltersOptions = options.data?.animationFilters || this.animationFiltersOptions;
    this.animationFiltersOptions.forEach((filter) => {
      if (typeof filter.enabled === 'undefined') {
        filter.enabled = true;
      }
    });
    this.initAnimationSource();
  }

  static get code(): string {
    return 'animator';
  }

  public filterBones(clip: Three.AnimationClip, filter: AnimationFilterOptions) {
    if (filter.type !== AnimationFilterType.Bones) return;
    const bonesNames = filter.bones.map((bone) => bone.name);
    clip.tracks = clip.tracks.filter((track) => {
      const nameParts = track.name.split('.');
      return !(nameParts.length >= 1 && bonesNames.includes(nameParts[0]));
    });
  }

  public filterRotation(clip: Three.AnimationClip, filter: AnimationFilterOptions) {
    if (filter.type !== AnimationFilterType.Rotation) return;
    clip.tracks.forEach((track) => {
      const nameParts = track.name.split('.');
      if (nameParts.length > 1) {
        const boneName = nameParts[0];
        const trackType = nameParts[1];
        if (trackType === 'quaternion' && filter.rotation[boneName]) {
          for (let i = 0; i < track.values.length; i += 4) {
            const quat = new Three.Quaternion(track.values[i], track.values[i + 1], track.values[i + 2], track.values[i + 3]);
            quat.multiply(filter.rotation[boneName]);
            track.values[i] = quat.x;
            track.values[i + 1] = quat.y;
            track.values[i + 2] = quat.z;
            track.values[i + 3] = quat.w;
          }
        }
      }
    });
  }

  public filterAnimationClip(clip: Three.AnimationClip, filter: AnimationFilterOptions): Three.AnimationClip {
    if (!filter.enabled) return clip;
    const filteredClip = clip.clone();
    filteredClip.name = clip.name;
    const filterCallback = this.animationFilter[filter.type];
    if (filterCallback) filterCallback(filteredClip, filter);
    return filteredClip;
  }

  public filterAction(action: Three.AnimationAction, actionData: AnimationActionData): Three.AnimationAction {
    if (!actionData.filters) actionData.filters = [];
    const currentClip = action.getClip();
    const filters = actionData.filters
      .map((filterName) => this.animationFiltersOptions.find((filter) => filter.name === filterName))
      .filter((filter) => filter && filter.enabled);
    const filtersNames = filters.map((filter) => filter?.name).sort();
    if (!this.actionsActiveFilters[actionData.name]) this.actionsActiveFilters[actionData.name] = {};
    if (
      this.actionsActiveFilters[actionData.name][currentClip.name]
      && JSON.stringify(this.actionsActiveFilters[actionData.name][currentClip.name].sort()) === JSON.stringify(filtersNames)
    ) return action;
    this.actionsActiveFilters[actionData.name][currentClip.name] = filtersNames as string[];
    let clip = this.actionClipsOriginals[actionData.name][currentClip.name];
    filters.forEach((filter) => {
      if (!filter) return;
      clip = this.filterAnimationClip(clip, filter);
    });
    action.stop();
    this.threeAnimationMixer.uncacheClip(currentClip);
    this.threeAnimationMixer.uncacheAction(currentClip);
    return this.threeAnimationMixer.clipAction(clip);
  }

  public cleanAnimation(): void {
    if (this.threeAnimationMixer) {
      this.threeAnimationMixer.stopAllAction();
      Object.keys(this.threeAnimationActions).forEach((name) => {
        this.threeAnimationActions[name].forEach((action) => {
          this.threeAnimationMixer.uncacheAction(action.getClip());
          this.threeAnimationMixer.uncacheClip(action.getClip());
          this.threeAnimationMixer.uncacheRoot(this.threeAnimationMixer.getRoot());
        });
      });
    }
    this.threeAnimationActions = {};
    this.isReady = false;
    this.actionsWeight = {};
    this.actionClipsWight = {};
    this.actionClipsOriginals = {};
    this.actionsActiveFilters = {};
    this.animationContents = [];
    this.threeAnimationClips = [];
  }

  protected makeActions(data: Three.Object3D): void {
    // todo: configurable, move something where
    const converter = new VrmAnimationConverter();
    this.threeAnimationClips = converter.convertMixamoClipsToVRM(
      this.threeAnimationClips, this.getVRMOrFail(), this.animationContents,
    );

    this.threeAnimationMixer = new Three.AnimationMixer(data);
    this.threeAnimationActions = this.actionsData.reduce<Record<string, Three.AnimationAction[]>>(
      (resultThreeActions, actionData) => {
        this.actionClipsWight[actionData.name] = {};
        this.actionClipsOriginals[actionData.name] = {};

        resultThreeActions[actionData.name] = actionData.clipsData.map((clipData) => {
          const clip = this.threeAnimationClips.find((_clip) => _clip.name === clipData.clipName);
          if (!clip) throw new Error(`Animation clip ${clipData.name} not found`);

          const clonedClip = clip.clone();
          clonedClip.name = clipData.name;

          if (clipData.resizeTo) {
            const k = clipData.resizeTo / clonedClip.duration;
            clonedClip.tracks.forEach((track) => {
              track.times = track.times.map((time) => {
                return time * k;
              });
            });
            clonedClip.duration = clipData.resizeTo;
          }
          this.actionClipsOriginals[actionData.name][clonedClip.name] = clonedClip;
          const action = this.filterAction(this.threeAnimationMixer.clipAction(clonedClip), actionData);

          action.enabled = true;
          action.weight = 0;
          this.actionClipsWight[actionData.name][clonedClip.name] = 0;
          action.stop();
          return action;
        });

        this.actionsWeight[actionData.name] = 0;

        return resultThreeActions;
      }, {},
    );
    this.isReady = true;
  }

  // todo: temporary
  protected getVRMOrFail(): VRM {
    const vrm = this.entity.getComponentOrFail(MeshRendererComponent).getVRM();
    if (!vrm) throw new Error('Vrm not found');

    return vrm;
  }

  protected tryMakeActions(): void {
    const meshComponent = this.entity.getComponentOrFail(MeshRendererComponent);
    if (meshComponent.data.children.length) {
      this.makeActions(meshComponent.data);
    } else {
      meshComponent.events.once('contentAdded', () => {
        this.makeActions(meshComponent.data);
      });
    }
  }

  public initAnimationSource() {
    return Promise.all(this.animationSourcesData.map((sourceData) => new Promise((resolve) => {
      this.threeFbxLoader.load(sourceData.url, (content) => {
        this.animationContents.push(content);
        const clip = content.animations[0];
        clip.name = sourceData.clipName;

        this.threeAnimationClips.push(clip);
        resolve(undefined);
      });
    }))).then(() => this.tryMakeActions());
  }

  public destroy(): void {
    // todo: erase memory!!!!
  }
}
