import { FC, ReactNode, useMemo } from 'react';
import classNames from './ActionPanel.module.css';

export interface ActionPanelProps {
  children: ReactNode;
  direction?: 'column' | 'row';
  maxWidth?: string;
  side?: string;
}

const ActionPanel: FC<ActionPanelProps> = ({ children, direction = 'row', maxWidth = 'auto', side = 'flex-end' }) => {
  const stylesAction = useMemo(() => {
    const directionActionStyle = classNames[`${direction}`];
    return [
      classNames.root,
      directionActionStyle,
    ].join(' ');
  }, [direction]);

  return (
    <div
      style={{ maxWidth, alignItems: side }}
      className={stylesAction}
    >
      {children}
    </div>
  );
};

export default ActionPanel;
