import WebSDK from '@voxeet/voxeet-web-sdk';

export default class MediaDevicesService {
  public static enumerateAudioInputDevices() {
    return WebSDK.mediaDevice.enumerateAudioInputDevices();
  }

  public static selectMicrophone(device: string) {
    return WebSDK.mediaDevice.selectAudioInput(device);
  }
}
