import * as Three from 'three';
import { AbstractScene } from './AbstractScene';
import { Application } from '../Application';

export type SceneManagerOptions = {
  app: Application;
};

export class SceneManager {
  protected app: Application;

  public sceneIsLoaded = false;

  public currentScene: AbstractScene | null = null;

  constructor(options: SceneManagerOptions) {
    this.app = options.app;
  }

  public get currentThreeScene(): Three.Scene | null {
    return this.currentScene?.threeScene || null;
  }

  public loadScene(scene: AbstractScene): Promise<void> {
    return this.destroyCurrentScene().then(() => {
      return scene.load(this.app).then(() => {
        this.currentScene = scene;
        this.sceneIsLoaded = true;
      });
    });
  }

  public destroyCurrentScene(): Promise<void> {
    if (!this.currentScene) return Promise.resolve();

    return this.currentScene.destroy(this.app);
  }
}
