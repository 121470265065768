import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';

export type IconParams = {
  imageUrl: string;
  width: number;
  height: number;
};

export function Icon(params: IconParams): ThreeMeshUI.Block {
  const block = new ThreeMeshUI.Block({
    width: params.width,
    height: params.height,
    borderWidth: 0,
    borderRadius: 0,
    offset: 0.1,
  });

  // todo: missing types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  new Three.TextureLoader().load(params.imageUrl, (texture) => block.set({ backgroundTexture: texture }));

  return block;
}
