import ThreeMeshUI from 'three-mesh-ui';
import * as THREE from 'three';
import { System } from '../../../engine/System';
import { Entity } from '../../../engine/Entity';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { makeDashboardTemplate } from './MakeDashboardSystem';
import { PlayerComponent } from '../../components/Player.component';
import { UIDocumentElementState, UIDocumentSystem } from '../../../engine/systems/UIDocument.system';
import { ButtonId } from './enum/ButtonId';
import { PanelId } from './enum/PanelId';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';

export class DashboardSystem extends System {
  onUpdate(dt: number) {
    super.onUpdate(dt);
    ThreeMeshUI.update();
    this.app.componentManager.getComponentsByType(PlayerComponent).forEach((component) => {
      this.handleActiveButtons(component);
    });
  }

  public setupUIEntity(screenUIEntity: Entity, scale: number): void {
    const uiComponent = screenUIEntity.addComponent(UIDocumentComponent);
    const playerComponent = screenUIEntity.addComponent(PlayerComponent);

    uiComponent.setRoot(makeDashboardTemplate(playerComponent));
    if (uiComponent.root) uiComponent.root.scale.set(scale, scale, scale);
  }

  public handleActiveButtons(component: PlayerComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    if (states[ButtonId.OnAudio]?.state === UIDocumentElementState.Active) {
      component.isOffAudioClick = true;
    }
    if (states[ButtonId.OffAudio]?.state === UIDocumentElementState.Active) {
      component.isOffAudioClick = true;
    }
    if (states[ButtonId.Logout]?.state === UIDocumentElementState.Active) {
      component.isLogoutClick = true;
    }

    this.app.getSystemOrFail(UIDocumentSystem).updateState(component.entity.getComponentOrFail(UIDocumentComponent));
  }

  public toggleAudio(component: PlayerComponent, isAudio: boolean): void {
    const document = component.entity.getComponentOrFail(UIDocumentComponent);
    const onAudioButton = document.getElementById(ButtonId.OnAudio);
    const offAudioButton = document.getElementById(ButtonId.OffAudio);

    if (onAudioButton) onAudioButton.visible = isAudio;
    if (offAudioButton) offAudioButton.visible = !isAudio;
  }

  public togglePanels = (isVisible: boolean, id: string): void => {
    const components = this.componentManager.getComponentsByType(PlayerComponent)
      .filter((component) => {
        return (component.entity.parent as Entity).getComponent(NetworkObjectComponent)?.netObject?.ownerId === id;
      });
    components.forEach((component) => {
      // component.isOffAudioClick = false;
      const document = component.entity.getComponentOrFail(UIDocumentComponent);
      const staticPanel = document.getElementById(PanelId.StaticPanel);
      const infoPanel = document.getElementById(PanelId.InfoPanel);
      if (staticPanel && infoPanel) {
        staticPanel.visible = isVisible;
        infoPanel.visible = !isVisible;
      }
    });
  };

  public updateMicrophone = (component: PlayerComponent, isSpeak: boolean) => {
    const document = component.entity.getComponentOrFail(UIDocumentComponent);
    const microphone = document.getElementById(ButtonId.Microphone);
    if (microphone) microphone.visible = isSpeak;
  };

  public updateName = (id: string, name: string) => {
    const components = this.componentManager.getComponentsByType(PlayerComponent)
      .filter((component) => {
        return (component.entity.parent as Entity).getComponent(NetworkObjectComponent)?.netObject?.ownerId === id;
      });
    components.forEach((component) => {
      const document = component.entity.getComponentOrFail(UIDocumentComponent);
      const textBlock = document.getElementById('Text');
      if (textBlock) {
        textBlock.children.forEach((child) => {
          if (child instanceof ThreeMeshUI.Text) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            child.set({ content: name });
          }
        });
      }
    });
  };

  public lookAt = (position: THREE.Vector3): void => {
    const components = this.componentManager.getComponentsByType(PlayerComponent);
    components.forEach((component) => {
      const document = component.entity.getComponentOrFail(UIDocumentComponent);
      const mainContainer = document.getElementById(PanelId.MainContainer);
      if (mainContainer) mainContainer.lookAt(position);
    });
  };
}
