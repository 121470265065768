import MessagesPool, { Message } from './MessagesPool';

export default class SendMessagesPool extends MessagesPool {
  public getMessagePool(): Message[] {
    this.messages.sort((m1, m2) => {
      return m1.sendTime - m2.sendTime;
    });
    const messages = [...this.messages];
    // if (messages.length > 0) {
    //   console.log('Send message count', messages.length);
    // }
    this.messages = [];
    return messages;
  }

  public replaceVariableMessage(message: Message) {
    if (message.variableUid) {
      this.messages = this.messages.filter((ms) => ms.variableUid !== message.variableUid);
    }
    this.messages.push(message);
  }

  public sendMessage(message: Message) {
    message.sendTime = message.sendTime || Date.now();
    if (message.required) {
      this.messages.push(message);
    } else {
      this.replaceVariableMessage(message);
    }
  }

  clear() {
    super.clear();
  }
}
