import * as Three from 'three';
import { System, SystemOptions } from '../System';
import { RigidBodyComponent } from '../components/RigidBody.component';
import { Ammo } from '../physics/AmmoLoader';
import { AmmoDebugDrawer } from '../physics/AmmoDebugDrawer';
import { AmmoDebugDrawMode } from '../physics/enums/AmmoDebugDrawMode';
import { ColliderComponent } from '../components/Collider.component';

// todo: refactor, maybe its not a system (core part)
export class PhysicSystem extends System {
  public debugDrawer: AmmoDebugDrawer;

  protected physicsWorld: Ammo.btDiscreteDynamicsWorld;

  protected destroyPhysicsWorld: () => void = () => undefined;

  constructor(options: SystemOptions) {
    super(options);
    this.physicsWorld = this.buildWorld();
    this.debugDrawer = this.buildDebugger(this.physicsWorld);
  }

  static get code(): string {
    return 'physic';
  }

  public get world(): Ammo.btDiscreteDynamicsWorld {
    return this.physicsWorld;
  }

  public onUpdate(dt: number) {
    if (this.app.sceneManager.currentThreeScene) this.app.sceneManager.currentThreeScene.add(this.debugDrawer.mesh); // todo: fix

    this.handleNewAddedComponents();

    this.physicsWorld.stepSimulation(dt, 60, 1 / 60);
    this.syncRigidBodiesState(dt, true);
    this.app.systems.forEach((system) => system.onPhysicUpdate(dt));
    this.debugDrawer.update(this.physicsWorld);
  }

  public destroy() {
    this.destroyPhysicsWorld();
    this.debugDrawer.destroy();
  }

  protected handleWordTick(worldId: Ammo.btDynamicsWorld, dt: number): void {
    this.syncRigidBodiesState(dt);
    this.app.systems.forEach((system) => system.onPhysicUpdate(dt));
  }

  protected attachDetachRigidBody(component: RigidBodyComponent): void {
    if (!component.btRigidBody) return;
    if (!component.enabled && component.world && component.inWorld) {
      this.world.removeRigidBody(component.btRigidBody);
      component.inWorld = false;
    }

    if (component.enabled && component.world && !component.inWorld) {
      this.world.addRigidBody(component.btRigidBody);
      component.inWorld = true;
    }
  }

  protected syncRigidBodiesState(dt: number, interpolate = false): void {
    this.componentManager.getComponentsByType(RigidBodyComponent).forEach((component) => {
      this.attachDetachRigidBody(component);
      if (!component.btRigidBody) return;
      if (!component.enabled) return;
      if (!component.btRigidBody.isActive()) return;

      let transform: Ammo.btTransform | undefined;

      if (interpolate && component.interpolate) {
        transform = new Ammo.btTransform();
        const ms = component.btRigidBody.getMotionState();
        ms.getWorldTransform(transform);
      } else {
        transform = component.btRigidBody.getWorldTransform();
      }

      const btPosition = transform.getOrigin();
      const btRotation = transform.getRotation();

      const worldPosition = new Three.Vector3(btPosition.x(), btPosition.y(), btPosition.z());
      const worldQuaternion = new Three.Quaternion(btRotation.x(), btRotation.y(), btRotation.z(), btRotation.w());

      const worldMatrix = new Three.Matrix4()
        // .makeRotationFromQuaternion(component.entity.getWorldQuaternion(new Three.Quaternion()))
        // temporary disabled
        .makeRotationFromQuaternion(worldQuaternion)
        .setPosition(worldPosition);
      let localMatrix = worldMatrix;

      if (component.entity.parent) {
        const parentWorldInverted = component.entity.parent.matrixWorld.clone().invert();
        localMatrix = parentWorldInverted.multiply(worldMatrix);
      }

      component.entity.matrix.copy(localMatrix);
      component.entity.matrix.decompose(
        component.entity.position,
        component.entity.quaternion,
        component.entity.scale,
      );
    });
  }

  protected buildWorld(): Ammo.btDiscreteDynamicsWorld {
    const collisionConfiguration = new Ammo.btDefaultCollisionConfiguration();
    const dispatcher = new Ammo.btCollisionDispatcher(collisionConfiguration);
    const broadPhase = new Ammo.btDbvtBroadphase();
    const solver = new Ammo.btSequentialImpulseConstraintSolver();
    const physicsWorld = new Ammo.btDiscreteDynamicsWorld(dispatcher, broadPhase, solver, collisionConfiguration);
    const tickCallback = Ammo.addFunction(this.handleWordTick.bind(this));
    physicsWorld.setGravity(new Ammo.btVector3(0.0, -9.81, 0.0));
    physicsWorld.setInternalTickCallback(tickCallback);
    physicsWorld.getBroadphase().getOverlappingPairCache().setInternalGhostPairCallback(new Ammo.btGhostPairCallback());

    this.destroyPhysicsWorld = () => {
      Ammo.destroy(physicsWorld);
      Ammo.destroy(collisionConfiguration);
      Ammo.destroy(dispatcher);
      Ammo.destroy(broadPhase);
      Ammo.destroy(solver);
    };

    return physicsWorld;
  }

  protected buildDebugger(world: Ammo.btDiscreteDynamicsWorld): AmmoDebugDrawer {
    const ammoDebugDrawer = new AmmoDebugDrawer({
      // eslint-disable-next-line no-bitwise
      debugDrawMode: AmmoDebugDrawMode.DrawWireframe | AmmoDebugDrawMode.DrawContactPoints,
    });

    world.setDebugDrawer(ammoDebugDrawer);

    return ammoDebugDrawer;
  }

  protected handleNewAddedComponents(): void {
    this.componentManager.getComponentsByType(ColliderComponent).forEach((colliderComponent) => {
      if (!colliderComponent.world && colliderComponent.btPairCachingGhostObject) {
        colliderComponent.world = this.physicsWorld;
        colliderComponent.applyEntityWorldMatrix();
        this.physicsWorld.addCollisionObject(colliderComponent.btPairCachingGhostObject);
      }

      const rigidBodyComponent = colliderComponent.entity.getComponent(RigidBodyComponent);

      if (rigidBodyComponent && !rigidBodyComponent.world && rigidBodyComponent.btRigidBody) {
        rigidBodyComponent.world = this.physicsWorld;
        rigidBodyComponent.applyEntityWorldMatrix();
        this.physicsWorld.addRigidBody(rigidBodyComponent.btRigidBody, rigidBodyComponent.group, rigidBodyComponent.mask);
        rigidBodyComponent.inWorld = true;
      }
    });
  }
}
