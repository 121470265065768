import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import * as THREE from 'three';
import { FrontSide, Mesh, TextureLoader } from 'three';
import EventEmitter from 'eventemitter3';
import { TAARenderPass } from 'three/examples/jsm/postprocessing/TAARenderPass';
import { Application } from '../../engine/Application';
import { InputSystem } from '../../engine/systems/InputSystem';
import { CameraSystem } from '../../engine/systems/Camera.system';
import { PhysicSystem } from '../../engine/systems/Physic.system';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { XRStatsSystem } from '../../engine/systems/XRStats.system';
import { UIDocumentSystem } from '../../engine/systems/UIDocument.system';
import { AbstractScene } from '../../engine/scene/AbstractScene';
import { CameraComponent } from '../../engine/components/Camera.component';
import roomModels from '../../assets/json/room_models.json';
import videosJson from '../../assets/json/videos.json';
import { AssetSourceType, MeshRendererComponent } from '../../engine/components/MeshRenderer.component';
import { ColliderComponent, ColliderType } from '../../engine/components/Collider.component';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { Entity } from '../../engine/Entity';
import { ComponentEventTypes } from '../../engine/Component';
import { generateStuff } from './helpers/generateStuff';
import { StuffSystem } from '../systems/Stuff.system';
import { AnimationSystem } from '../systems/Animation.system';
import RaycastSystem from '../systems/Raycast.system';
import OutlineSystem from '../systems/Outline.system';
import { setupControllers } from './helpers/setupControllers';
import { setupEnvironment } from './helpers/setupEnvironment';
import CollisionFilters from '../constants/collisionFilters';
import NetworkSystem from '../../engine/systems/NetworkSystem';
import CharacterObject from '../network/objects/CharacterObject';
import StuffObject from '../network/objects/StuffObject';
import StuffVariable from '../network/variables/StuffVariable';
import { DashboardSystem } from '../systems/Dashboard.system/Dashboard.system';
import { appendVideos, VideosDataType } from './helpers/appendVideos';
import VideoObject from '../network/objects/VideoObject';
import VideoVariable from '../network/variables/VideoVariable';
import { AnimatorSystem } from '../../engine/systems/Animator.system';
import { PlayerControlsSystem } from '../systems/PlayerControls.system';
import { XRFPControllerSystem } from '../systems/XRFPController.system';
import { FPControllerSystem } from '../systems/FPController.system';
import { TPControllerSystem } from '../systems/TPController.system';
import { SeatSystem } from '../systems/Seat.system';
import SceneInteraction from '../../Scene/SceneInteraction';
import VrmIKSystem from '../systems/VrmIKSystem';
import AvatarSystem from '../systems/Avatar.system';
import NetworkIKSystem from '../systems/NetworkIK.system';
import IKVariable from '../network/variables/IKVariable';
import {VideoTextureSystem} from "../systems/VideoTextureSystem";

export type MainSceneEventTypes = ComponentEventTypes & {
  roomLoaded: (payload: { content: THREE.Object3D }) => void;
  sceneLoaded: () => void;
  onProgress: (payload: { percent: number }) => void;
};

export class MainScene extends AbstractScene {
  protected _events: EventEmitter<MainSceneEventTypes> = new EventEmitter<MainSceneEventTypes>();

  public colliderUrl = './3d/collider/collider.glb';

  public isSetUp = false;

  public composer: EffectComposer | null = null;

  public outlinePass: OutlinePass | null = null;

  public characterEntity: Entity | null = null;

  public cameraEntity: Entity | null = null;

  public get events(): EventEmitter<MainSceneEventTypes> {
    return this._events;
  }

  load(app: Application): Promise<void> {
    if (!this.isSetUp) {
      this.setupNetwork(app);
      this.setupSystems(app);
      this.setupScene(app);
      this.addCharacter(app);
    }
    this.isSetUp = true;
    return Promise.resolve(undefined);
  }

  destroy(app: Application): Promise<void> {
    // app.destroyAllSystems();
    // app.componentManager.destroyAllComponents();
    // ThreeMemoryCleaner.disposeThreeGraph(this.threeScene);
    return Promise.resolve(undefined);
  }

  protected setupNetwork(app: Application): void {
    if (app.networkManager) {
      CharacterObject.register(app.networkManager);
      StuffObject.register(app.networkManager);
      StuffVariable.register(app.networkManager, app);
      VideoObject.register(app.networkManager);
      VideoVariable.register(app.networkManager, app);
      IKVariable.register(app.networkManager, app);
    }
  }

  protected setupSystems(app: Application): void {
    [
      new InputSystem({ app }),
      new XRInputSystem({ app }),
      new CameraSystem({ app }),
      new PhysicSystem({ app }),
      new UIDocumentSystem({ app }),
      new XRStatsSystem({ app }),
      new AnimatorSystem({ app }),

      //
      new AnimationSystem({ app }),
      new RaycastSystem({ app }),
      new OutlineSystem({ app }),
      new StuffSystem({ app }),
      // new TakeSystem({ app }),
      // new ThrowSystem({ app }),
      new PlayerControlsSystem({ app }),
      new XRFPControllerSystem({ app }),
      new FPControllerSystem({ app }),
      new TPControllerSystem({ app }),
      new DashboardSystem({ app }),

      new NetworkSystem({ app }),
      new SeatSystem({ app }),
      new SceneInteraction({ app }),
      new VrmIKSystem({ app }),
      new AvatarSystem({ app }),
      new NetworkIKSystem({ app }),
      new VideoTextureSystem({ app }),

    ].forEach((system) => app.systems.push(system));

    const cameraEntity = app.entityManager.makeEntity();
    this.cameraEntity = cameraEntity;
    cameraEntity.addComponent(CameraComponent);
    this.threeScene.add(cameraEntity);

    setupControllers(app, cameraEntity);
    setupEnvironment(app, this.threeScene);

    // FIXME
    app.getSystem(CameraSystem)?.onUpdate(0);
  }

  protected setupScene(app: Application): void {
    this.composer = new EffectComposer(app.renderer);
    this.setupComposer(app);
    this.loadScene(app);
  }

  public render(app: Application, delta: number) {
    // if (app.renderer.xr.getSession()) {
    super.render(app, delta);
    // } else {
    //   this.composer?.render(delta);
    // }
  }

  public setupComposer(app: Application): void {
    if (!this.composer) return;

    if (app.camera) {
      const taaRenderPass = new TAARenderPass(this.threeScene, app.camera, 0xfffff, 1.0);
      taaRenderPass.unbiased = false;
      taaRenderPass.sampleLevel = 2;
      taaRenderPass.accumulate = false;
      this.composer.addPass(taaRenderPass);
    }

    if (this.threeScene && app.camera) {
      // const renderPass = new RenderPass(this.threeScene, app.camera);
      // this.composer.addPass(renderPass);
      const outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight),
        this.threeScene, app.camera);
      this.composer.addPass(outlinePass);
      (new TextureLoader()).load('./3d/textures/outline_pattern.jpg', (texture) => {
        outlinePass.patternTexture = texture;
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        outlinePass.usePatternTexture = true;
      });
      outlinePass.hiddenEdgeColor.set('#000000');
      this.outlinePass = outlinePass;
    }

    this.composer.renderTarget1.texture.encoding = THREE.sRGBEncoding;
    this.composer.renderTarget2.texture.encoding = THREE.sRGBEncoding;
    this.composer.renderTarget1.texture.format = THREE.RGBFormat;
    this.composer.renderTarget2.texture.format = THREE.RGBFormat;
    this.composer.renderTarget1.texture.needsUpdate = true;
    this.composer.renderTarget2.texture.needsUpdate = true;

    this.composer.setSize(window.innerWidth, window.innerHeight);
  }

  public loadScene(app: Application): void {
    const entities: Entity[] = [];
    // let loadedRooms = 0;
    Promise.all(Object.keys(roomModels).map((roomName) => {
      const url: string = roomModels[roomName as keyof typeof roomModels];
      return new Promise((resolve) => {
        const roomEntity = app.entityManager.makeEntity();
        roomEntity.addComponent(MeshRendererComponent, { sourceData: { type: AssetSourceType.GLTF, url } }).events.once('contentAdded', ({ content }) => {
          resolve(content);
          content.traverse((obj) => {
            if (obj instanceof Mesh) {
              obj.material.side = FrontSide;
            }
          });
          // loadedRooms += 1;
          // this.events.emit('onProgress', { percent: (loadedRooms * 100) / roomsCount });
        });
        // TODO: move here light settings
        // roomEntity.addComponent(EnvMapComponent, { envUrl: './3d/hdr/hdr_env.hdr' });
        entities.push(roomEntity);
      });
    })).then(() => {
      this.threeScene.add(...entities);
      return this.addRoomCollider(app);
    }).then(async () => {
      this.setupCharacter(app);
      generateStuff(app).then((stuff) => {
        if (stuff.length > 0) {
          this.threeScene.add(...stuff);
        }
      });
      const devMode = process.env.NODE_ENV === 'development';
      const vidTex = devMode ? videosJson : await this.getRestApiData('rev_video');
      appendVideos(app, this.threeScene, vidTex as VideosDataType);

      this.finishInitialization(app);
    });
  }

  getRestApiData(endpoint = '') {
    return fetch(`${process.env.REACT_APP_DATA_URL}${endpoint}`).then((res) => res.json());
  }

  public finishInitialization(app: Application) {
    app.networkManager?.finishInitialization();
    this.events.emit('sceneLoaded');
  }

  public addRoomCollider(app: Application) {
    return Promise.all(['character', 'other'].map((name) => {
      return new Promise<void>((resolve) => {
        const colliderEntity = app.entityManager.makeEntity();
        colliderEntity.name = 'RoomCollider';
        colliderEntity
          .addComponent(MeshRendererComponent, { sourceData: { type: AssetSourceType.GLTF, url: this.colliderUrl } })
          .events.once('contentAdded', ({ content }) => {
            const colliderMesh = content.getObjectByName('collider');
            if (colliderMesh) colliderMesh.visible = false;
            resolve();
          });
        const collider = colliderEntity.addComponent(ColliderComponent, {
          shapeData: {
            type: ColliderType.TriangleMesh,
            meshName: 'collider',
            applyTransform: true,
          },
        });
        const rigid = colliderEntity.addComponent(RigidBodyComponent, {
          colliderComponent: collider,
          friction: name === 'other' ? 1 : 0,
          restitution: name === 'other' ? 0.9 : 0,
          group: CollisionFilters.StaticFilter,
          mask: name === 'other'
            // eslint-disable-next-line no-bitwise
            ? CollisionFilters.AllFilter & ~CollisionFilters.CharacterFilter
            : CollisionFilters.CharacterFilter,
        });

        // rigid.btRigidBody?.mask
        // collider.btCollisionShape.
        if (name === 'other') {
          rigid.btRigidBody?.setRollingFriction(1);
        }
      });
    }));
  }

  public setupCharacter(app: Application) {
    if (!this.characterEntity) return;
    if (!this.cameraEntity) return;
    // this.characterEntity.addComponent(RaycastComponent);
    CharacterObject.buildEntity(app, this.characterEntity, this.cameraEntity);
  }

  public addCharacter(app: Application) {
    this.characterEntity = CharacterObject.createCharacterEntity(app);
    this.threeScene.add(this.characterEntity);
  }
}
