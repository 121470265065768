import { generateUUID } from 'three/src/math/MathUtils';
import { Component } from './Component';
import { Entity } from './Entity';
// import { Constructor } from 'make-error';

export class ComponentManager {
  public components: Record<string, Component[]> = {};

  public componentsByIndex: Record<number, Component> = {};

  protected componentIndex = 0;

  public makeComponent<T extends typeof Component>(componentType: T, entity: Entity, data?: ConstructorParameters<T>[0]['data']): InstanceType<T> {
    const component = new componentType({ id: generateUUID(), entity, data, index: this.componentIndex++ });

    this.components[componentType.code] = this.components[componentType.code] || [];
    this.components[componentType.code].push(component);
    this.componentsByIndex[component.index] = component;

    return component as InstanceType<T>;
  }

  public getComponent<T extends typeof Component>(componentType: T, entity: Entity): InstanceType<T> | undefined {
    const component = (this.components[componentType.code] || []).find((_component) => _component.entity === entity);

    return component ? component as InstanceType<T> : component;
  }

  public getComponentsByType<T extends typeof Component>(componentType: T): InstanceType<T>[] {
    return (this.components[componentType.code] || []) as InstanceType<T>[];
  }

  public getComponentByIndex(index: number): Component | undefined {
    return this.componentsByIndex[index];
  }

  public destroyAllComponents(): void {
    Object.keys(this.components).forEach((componentCode) => {
      this.components[componentCode].forEach((component) => {
        component.destroy();
      });
    });

    this.components = {};
  }

  public destroyComponentsForEntity(entity: Entity): void {
    Object.keys(this.components)
      .forEach((componentCode) => {
        this.components[componentCode]
          .filter((component) => component.entity.uuid === entity.uuid)
          .forEach((component) => {
            component.destroy();
          });
        this.components[componentCode] = this.components[componentCode]
          .filter((component) => component.entity.uuid !== entity.uuid);
      });
  }
}
