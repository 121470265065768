import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const UpIcon: FC<IconsType> = memo((props) => {
  const {
    height = 24,
    width = 24,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9999 4.39844L11.3999 9.99844M16.9999 4.39844L22.5999 9.99844M16.9999 4.39844V18.3984"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.39986 15.5996C4.39986 22.5584 10.0411 28.1996 16.9999 28.1996C23.9586 28.1996 29.5999 22.5584 29.5999 15.5996"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
});

export default UpIcon;
