import * as THREE from 'three';
import { System } from '../../engine/System';
import VideoTextureComponent from '../components/VideoTexture.component';
import { MainScene } from '../scenes/MainScene';
import { forDistRayCast } from '../../constans/raycast';

export class VideoTextureSystem extends System {
  private raycaster = new THREE.Raycaster();

  onUpdate(dt: number) {
    super.onUpdate(dt);
    this.app.componentManager.getComponentsByType(VideoTextureComponent).forEach((component) => {
      this.showRadius(component);
    });
  }

  showRadius(component: VideoTextureComponent): void {
    const { characterEntity } = this.app.sceneManager.currentScene as MainScene;
    if (!characterEntity) return;
    if (!component.controller.videoElement?.muted) {
      const { entity } = component;
      const positionX = entity.position.x - characterEntity.position.x;
      const positionZ = entity.position.z - characterEntity.position.z;
      const generalPositionX = positionX < 0 ? -positionX : positionX;
      const generalPositionZ = positionZ < 0 ? -positionZ : positionZ;
      if (generalPositionX > generalPositionZ) component.changeVolume(generalPositionX,this.raycast(component));
      else component.changeVolume(generalPositionZ,this.raycast(component));
    }
  }

  public raycast(component: VideoTextureComponent): boolean {
    const { characterEntity } = this.app.sceneManager.currentScene as MainScene;
    const { entity } = component;
    if (characterEntity) {
      const position = new THREE.Vector3();
      const target = new THREE.Vector3();
      const direction = new THREE.Vector3();

      characterEntity.getWorldPosition(position);
      entity.getWorldPosition(target);
      direction.subVectors(target, position).normalize();

      this.raycaster.set(position, direction);
      const intercets = this.raycaster.intersectObjects(component.raycastArray);

      if (intercets[0]?.object.name === entity.name) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
}
