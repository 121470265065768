import * as Three from 'three';
import { Component } from '../../engine/Component';
import { TPControllerComponent } from './TPController.component';
import { FPControllerComponent } from './FPController.component';

export class PlayerControlsComponent extends Component {
  public cameraSensitivity = Math.PI / 2;

  public virtualMovementDirection: Three.Vector2 = new Three.Vector2();

  public controlBeforeChange: TPControllerComponent | FPControllerComponent | undefined;

  public isChangeControl = false;

  static get code(): string {
    return 'player_controls';
  }
}
