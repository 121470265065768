import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const DublicateIcon: FC<IconsType> = memo((props) => {
  const {
    width = '32',
    height = '32',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12H25C26.6569 12 28 13.3431 28 15V25C28 26.6569 26.6569 28 25 28H15C13.3431 28 12 26.6569 12 25V20M20 12H15C13.3431 12 12 13.3431 12 15V20M20 12V7C20 5.34315 18.6569 4 17 4H7C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H12"
        stroke="#2586FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
});

export default DublicateIcon;
