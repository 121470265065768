import { Intersection, Object3D } from 'three';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';
import { ControllerName } from '../../engine/systems/XRInputSystem';

export enum RaycastComponentStatus {
  Default = 'Default',
  Intersect = 'Intersect',
}

export type RaycastComponentXRSource = {
  type: 'xr';
  controllerName: ControllerName;
};

export type RaycastComponentBrowserSource = {
  type: 'browser';
  controllerName: 'mouse';
};

export type RaycastComponentState = {
  status: RaycastComponentStatus;
  source?: RaycastComponentXRSource | RaycastComponentBrowserSource;
  intersections?: Intersection[];
};

export type RaycastComponentOptions = ComponentOptions & {
  data?: {
    target?: Object3D;
  };
};

export default class RaycastComponent extends EngineComponent {
  public cursor = 'pointer';

  public state: RaycastComponentState = { status: RaycastComponentStatus.Default };

  public target?: Object3D;

  constructor(options: RaycastComponentOptions) {
    super(options);
    this.target = options.data?.target || this.entity;
  }

  static get code(): string {
    return 'raycast';
  }

  public get isActive(): boolean {
    return this.state.status === RaycastComponentStatus.Intersect;
  }

  public setState(state: RaycastComponentState) {
    this.state = state;
  }
}
