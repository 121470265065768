import { FC } from 'react';
import classNames from './Card.module.css';

export interface CardProps {
  url: string;
  onClick: () => void;
  height: string;
}

const Card: FC<CardProps> = (props) => {
  const {
    url,
    onClick = () => undefined,
    height,
  } = props;

  return (
    <button
      type="button"
      className={classNames.root}
      onClick={onClick}
    >
      <img
        style={{ height }}
        src={url}
      />
    </button>
  );
};

export default Card;
