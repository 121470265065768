import * as ThreeVrm from '@pixiv/three-vrm';
import * as Three from 'three';
import * as Ammo from 'ammo.js';
import { System } from '../../engine/System';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { CameraComponent } from '../../engine/components/Camera.component';
import { FPControllerComponent } from '../components/FPController.component';
import { AnimatorComponent } from '../../engine/components/Animator.component';
import { MeshRendererComponent } from '../../engine/components/MeshRenderer.component';
import { TPControllerComponent } from '../components/TPController.component';
import { InputSystem } from '../../engine/systems/InputSystem';

/**
 * First person controller
 */
export class FPControllerSystem extends System {
  protected spherical: Three.Spherical = new Three.Spherical();

  static get code(): string {
    return 'f_p_controller';
  }

  public onXRSessionStart() {
    this.componentManager.getComponentsByType(FPControllerComponent).forEach((component) => {
      component.isInitialized = false;
    });
  }

  onUpdate() {
    if (this.app.renderer.xr.isPresenting) return;

    this.componentManager.getComponentsByType(FPControllerComponent).forEach((component) => {
      if (!component.enabled) return;
      if (!component.isInitialized) return this.initializeComponent(component);

      this.clampCameraRotation(component);
      this.updateCameraPosition(component);
      this.syncCharacterRotation(component);
      this.updateCharacterVelocity(component);
      this.updateAnimations(component);
    });
  }

  protected initializeComponent(fPControllerComponent: FPControllerComponent): void {
    this.setupVRMCameraMode(fPControllerComponent);
    this.initCameraRotation(fPControllerComponent);
    this.updateCameraPosition(fPControllerComponent);
    fPControllerComponent.isInitialized = true;
  }

  protected updateCharacterVelocity(component: FPControllerComponent): void {
    const rb = component.entity.getComponentOrFail(RigidBodyComponent);

    const velocity = component.sprintIsActive ? component.sprintVelocity : component.baseVelocity;
    const movementVector = new Three.Vector3(component.movementVector.x, 0, component.movementVector.y);

    movementVector.multiplyScalar(velocity).clampLength(-velocity, velocity).applyEuler(component.entity.rotation);

    rb.getBtRigidBodyOrFail().setLinearVelocity(new Ammo.btVector3(
      movementVector.x,
      rb.getBtRigidBodyOrFail().getLinearVelocity().y(),
      movementVector.z,
    ));
  }

  protected clampCameraRotation(component: FPControllerComponent): void {
    component.cameraPhi = Math.min(Math.max(component.cameraPhi, Math.PI * 0.1), Math.PI * 0.9);
  }

  protected setupVRMCameraMode(component: FPControllerComponent): void {
    if (!component.avatarEntity) return;

    component.avatarEntity.visible = true; // temporary
    const cameraComponent = component.getCameraEntityOrFail().getComponentOrFail(CameraComponent);
    cameraComponent.threeCamera.layers.enable(ThreeVrm.VRMFirstPerson.DEFAULT_FIRSTPERSON_ONLY_LAYER);
    cameraComponent.threeCamera.layers.disable(ThreeVrm.VRMFirstPerson.DEFAULT_THIRDPERSON_ONLY_LAYER);
  }

  protected updateCameraPosition(fPControllerComponent: FPControllerComponent): void {
    this.spherical.set(1, fPControllerComponent.cameraPhi, fPControllerComponent.cameraTheta);

    const cameraPosition = this.getHeadCameraPosition(fPControllerComponent);
    const lookDirection = new Three.Vector3().setFromSpherical(this.spherical).negate().add(cameraPosition);
    const cameraEntity = fPControllerComponent.getCameraEntityOrFail();
    const cameraComponent = cameraEntity.getComponentOrFail(CameraComponent);

    cameraComponent.entity.position.copy(cameraPosition);
    cameraComponent.threeCamera.position.set(0, 0, 0);
    cameraComponent.threeCamera.lookAt(lookDirection);
  }

  protected getHeadCameraPosition(fPControllerComponent: FPControllerComponent): Three.Vector3 {
    const position = fPControllerComponent.entity.position.clone(); // entity center
    const head = this.getHead(fPControllerComponent);

    if (head) {
      position.copy(head.getWorldPosition(new Three.Vector3()));
      const eyesOffset = new Three.Vector3(0, 0, -0.15).applyQuaternion(fPControllerComponent.entity.quaternion);
      position.add(eyesOffset);
    }

    return position;
  }

  protected getHead(fPControllerComponent: FPControllerComponent): Three.Object3D | undefined {
    const vrm = fPControllerComponent?.avatarEntity?.getComponentOrFail(MeshRendererComponent).getVRM();

    return vrm?.humanoid?.getBoneNode('head') ?? undefined;
  }

  protected initCameraRotation(component: FPControllerComponent): void {
    component.cameraTheta = component.entity.rotation.y;
  }

  protected syncCharacterRotation(component: FPControllerComponent): void {
    const { threeCamera } = component.getCameraEntityOrFail().getComponentOrFail(CameraComponent);
    const lookVector = threeCamera.getWorldDirection(new Three.Vector3());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!this.app.sceneManager.currentThreeScene.playerSeated) {
      const rotor = new Three.Matrix4().lookAt(
        new Three.Vector3(0, 0, 0),
        new Three.Vector3(lookVector.x, 0, lookVector.z),
        new Three.Vector3(0, 1, 0),
      );
      component.entity.rotation.setFromRotationMatrix(rotor);
    }

    component.entity.getComponentOrFail(RigidBodyComponent).applyEntityWorldMatrix();

    const head = this.getHead(component);

    if (head) {
      head
        .applyQuaternion(component.entity.quaternion.clone().invert())
        .applyQuaternion(threeCamera.quaternion);
    }
  }

  protected updateAnimations(component: FPControllerComponent): void {
    if (!component.stopAnimation) {
      const avatarAnimatorComponent = component.getAvatarEntityOrFail().getComponentOrFail(AnimatorComponent);
      const velocity = component.sprintIsActive ? component.sprintVelocity : component.baseVelocity;
      const movementVector = new Three.Vector3(component.movementVector.x, 0, component.movementVector.y);
      // @ts-ignore
      if (this.app.sceneManager.currentThreeScene.playerSeated) {
        avatarAnimatorComponent.actionName = 'seated';
      } else {
        if (movementVector.length() === 0) {
          avatarAnimatorComponent.actionName = 'idle';
          return;
        }

        avatarAnimatorComponent.actionName = 'walk';
        const walkVelocityMultiplier = velocity / component.baseVelocity;
        const movementMultiplier = movementVector.clone().clampLength(0, 1).length();
        const normalizedMovement = movementVector.clone().normalize();

        const {parameters} = avatarAnimatorComponent;

        parameters.forwardWeight = normalizedMovement.z < 0 ? Math.abs(normalizedMovement.z) : 0;
        parameters.backwardWeight = normalizedMovement.z > 0 ? Math.abs(normalizedMovement.z) : 0;

        if (parameters.backwardWeight > 0) {
          parameters.leftBackStrafeWeight = normalizedMovement.x > 0 ? Math.abs(normalizedMovement.x) : 0;
          parameters.rightBackStrafeWeight = normalizedMovement.x < 0 ? Math.abs(normalizedMovement.x) : 0;
          parameters.leftStrafeWeight = 0;
          parameters.rightStrafeWeight = 0;
        } else {
          parameters.leftBackStrafeWeight = 0;
          parameters.rightBackStrafeWeight = 0;
          parameters.leftStrafeWeight = normalizedMovement.x < 0 ? Math.abs(normalizedMovement.x) : 0;
          parameters.rightStrafeWeight = normalizedMovement.x > 0 ? Math.abs(normalizedMovement.x) : 0;
        }

        parameters.speed = movementMultiplier * walkVelocityMultiplier;
        parameters.strafeSpeed = movementMultiplier * walkVelocityMultiplier;
        parameters.backStrafeSpeed = movementMultiplier * walkVelocityMultiplier * -1;
      }
    }
  }
}
