import * as THREE from 'three';

const RESETQUAT = new THREE.Quaternion();
const Y_AXIS = new THREE.Vector3(0, 1, 0);

export type ContextType = {
  exclude?: (string | number)[];
  averagedDirs?: Record<string, THREE.Vector3>;
  worldPos?: Record<string, THREE.Vector3[]>;
  preRotations?: Record<string, THREE.Quaternion>;
};

function getOriginalWorldPositions(
  rootBone: THREE.Bone,
  worldPos: Record<string, THREE.Vector3[]>,
) {
  const rootBoneWorldPos = rootBone.getWorldPosition(new THREE.Vector3());
  worldPos[rootBone.id] = [rootBoneWorldPos];
  rootBone.children.forEach((child) => {
    getOriginalWorldPositions(child as THREE.Bone, worldPos);
  });
}

function calculateAverages(
  parentBone: THREE.Bone,
  worldPos: Record<string, THREE.Vector3[]>,
  averagedDirs: Record<string, THREE.Vector3>,
) {
  const averagedDir = new THREE.Vector3();
  parentBone.children.forEach((childBone) => {
    // average the child bone world pos
    const childBonePosWorld = worldPos[childBone.id][0];
    averagedDir.add(childBonePosWorld);
  });

  averagedDir.multiplyScalar(1 / (parentBone.children.length));
  averagedDirs[parentBone.id] = averagedDir;

  parentBone.children.forEach((childBone) => {
    calculateAverages(childBone as THREE.Bone, worldPos, averagedDirs);
  });
}

/**
 * Takes in a rootBone and recursively traverses the bone heirarchy,
 * setting each bone's +Z axis to face it's child bones. The IK system follows this
 * convention, so this step is necessary to update the bindings of a skinned mesh.
 *
 * Must rebind the model to it's skeleton after this function.
 */

function precalculateZForwards(rootBone: THREE.Bone, context: ContextType) {
  context = context || rootBone;
  context.worldPos = context.worldPos || {};
  context.averagedDirs = context.averagedDirs || {};
  context.preRotations = context.preRotations || {};
  getOriginalWorldPositions(rootBone, context.worldPos);
  calculateAverages(rootBone, context.worldPos, context.averagedDirs);
  return context;
}

function updateTransformations(
  parentBone: THREE.Bone,
  worldPos: Record<string, THREE.Vector3[]>,
  averagedDirs: Record<string, THREE.Vector3>,
  preRotations: Record<string, THREE.Quaternion>,
) {
  const averagedDir = averagedDirs[parentBone.id];
  if (averagedDir) {
    // set quaternion
    parentBone.quaternion.copy(RESETQUAT);
    // parentBone.quaternion.premultiply(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI*2));
    parentBone.updateMatrixWorld();

    // get the child bone position in local coordinates
    // var childBoneDir = parentBone.worldToLocal(averagedDir.clone()).normalize();

    // set direction to face child
    // setQuaternionFromDirection(childBoneDir, Y_AXIS, parentBone.quaternion)
    // console.log('new quaternion', parentBone.quaternion.toArray().join(','));
  }
  const preRot = preRotations[parentBone.id] || preRotations[parentBone.name];
  if (preRot) parentBone.quaternion.multiply(preRot);
  // parentBone.quaternion.multiply(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI));
  parentBone.updateMatrixWorld();

  // set child bone position relative to the new parent matrix.
  parentBone.children.forEach((childBone) => {
    const childBonePosWorld = worldPos[childBone.id][0].clone();
    parentBone.worldToLocal(childBonePosWorld);
    childBone.position.copy(childBonePosWorld);
  });

  parentBone.children.forEach((childBone) => {
    updateTransformations(childBone as THREE.Bone, worldPos, averagedDirs, preRotations);
  });
}

function setZForward(rootBone: THREE.Bone, context: ContextType) {
  if (!context || !context.worldPos) {
    context = context || {};
    precalculateZForwards(rootBone, context);
  }
  if (context.worldPos && context.averagedDirs && context.preRotations) {
    updateTransformations(rootBone, context.worldPos, context.averagedDirs, context.preRotations);
  }
  return context;
}

/**
 * Takes in a rootBone and recursively traverses the bone heirarchy,
 * setting each bone's +Z axis to face it's child bones. The IK system follows this
 * convention, so this step is necessary to update the bindings of a skinned mesh.
 *
 * Must rebind the model to it's skeleton after this function.
 *
 * @param {THREE.Bone} rootBone
 * @param {Object} context - options and buffer for stateful bone calculations
 *                 context.exclude: [ boneNames to exclude ]
 *                 context.preRotations: { boneName: THREE.Quaternion, ... }
 */

export function fixSkeletonZForward(rootBone: THREE.Bone, context: ContextType = {}) {
  context = context || {};
  precalculateZForwards(rootBone, context);
  if (context.exclude) {
    const bones: THREE.Object3D[] = [rootBone];
    rootBone.traverse((b) => bones.push(b));
    bones.forEach((b) => {
      if (!context.exclude || !context.averagedDirs) return;
      if (context.exclude.indexOf(b.id) !== -1 || context.exclude.indexOf(b.name) !== -1) {
        delete context.averagedDirs[b.id];
      }
    });
  }
  return setZForward(rootBone, context);
}

export function fixSkeletonAndMeshes(rootBone: THREE.Bone, scene: THREE.Object3D | null, context: ContextType = {}) {
  fixSkeletonZForward(rootBone, context);
  const meshes: THREE.SkinnedMesh[] = [];
  if (scene) {
    scene?.traverse((mesh) => {
      if (mesh instanceof THREE.SkinnedMesh) {
        meshes.push(mesh);
      }
    });
  }
  meshes.forEach((mesh) => {
    mesh.updateMatrixWorld(true);
    mesh.updateWorldMatrix(true, true);
    mesh.bind(mesh.skeleton);
  });
}
