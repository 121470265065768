import * as Three from 'three';
import { Intersection } from 'three';
import { System } from '../../engine/System';
import RaycastComponent, {
  RaycastComponentBrowserSource,
  RaycastComponentStatus,
  RaycastComponentXRSource,
} from '../components/Raycast.component';
import { InputSystem } from '../../engine/systems/InputSystem';
import { ControllerName, XRInputSystem } from '../../engine/systems/XRInputSystem';

export default class RaycastSystem extends System {
  protected rayCaster: Three.Raycaster = new Three.Raycaster();

  onUpdate(ts: number) {
    const components = this.componentManager.getComponentsByType(RaycastComponent);
    components.forEach((component) => this.updateState(component));
  }

  public resetState(component: RaycastComponent) {
    component.setState({ status: RaycastComponentStatus.Default, intersections: [] });
  }

  public updateState(component: RaycastComponent) {
    this.resetState(component);
    if (!component.target) return;
    if (this.app.renderer.xr.isPresenting) {
      this.handleXR(component);
    } else {
      this.handleBrowser(component);
    }
  }

  public handleXR(component: RaycastComponent) {
    const xRInputSystem = this.app.getSystemOrFail(XRInputSystem);
    const raySpaces = [
      xRInputSystem.getRaySpace(ControllerName.Left),
      xRInputSystem.getRaySpace(ControllerName.Right),
    ];

    const intersects = raySpaces.map((raySpace) => {
      if (!raySpace) return [];
      const rotationMatrix = new Three.Matrix4();
      rotationMatrix.extractRotation(raySpace.matrixWorld);
      this.rayCaster.ray.origin.setFromMatrixPosition(raySpace.matrixWorld);
      this.rayCaster.ray.direction.set(0, 0, -1).applyMatrix4(rotationMatrix);
      return component.target ? this.rayCaster.intersectObject(component.target, true) : [];
    }, []);

    intersects.forEach((intr, index) => {
      this.setState(component, intr, {
        type: 'xr',
        controllerName: index === 0 ? ControllerName.Left : ControllerName.Right,
      });
    });
  }

  public handleBrowser(component: RaycastComponent): void {
    if (!this.app.camera) return;
    // TODO: do only on mouse move
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    this.rayCaster.setFromCamera(inputSystem.mouse.position, this.app.camera);
    const intersects = component.target ? this.rayCaster.intersectObject(component.target, true) : [];
    this.setState(component, intersects, { type: 'browser', controllerName: 'mouse' });
  }

  public setState(component: RaycastComponent, intersections: Intersection[],
    source: RaycastComponentXRSource | RaycastComponentBrowserSource) {
    component.setState({
      status: intersections.length > 0 ? RaycastComponentStatus.Intersect : RaycastComponentStatus.Default,
      source,
      intersections,
    });

    //
    if (source.type === 'browser' && intersections.length > 0) {
      this.app.renderer.domElement.style.cursor = component.cursor;
    }
  }
}
