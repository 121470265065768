import { FC, MouseEvent, useMemo } from 'react';
import classNames from './IconButton.module.css';
import RoundIcon from '../Icons/RoundIcon';
import InsideRoundIcon from '../Icons/InsideRoundIcon';
import Icon from '../Icon/Icon';
import { desktopButton, mobileButton } from '../../utils/ButtonSize';

export interface IconButtonProps {
  disabled?: boolean;
  onCLick?: (event: MouseEvent<HTMLDivElement>) => void;
  icon: string;
  isActive?: boolean;
  id?: string;
  size?: 'Md' | 'Xs';
}

const IconButton: FC<IconButtonProps> = (props) => {
  const {
    disabled = false,
    onCLick = () => undefined,
    icon,
    id = '',
    isActive = false,
    size = 'Xs',
  } = props;

  const styleButton = useMemo(() => {
    const buttonDisabled = disabled && classNames.disabled;
    const rootSize = classNames[`root${size}`];
    return [
      classNames.root,
      rootSize,
      buttonDisabled,
    ].join(' ');
  }, [disabled, size]);

  const insideIconStyle = useMemo(() => {
    const activeButton = isActive && classNames.activeRoot;
    return [
      classNames.inside,
      activeButton,
    ].join(' ');
  }, [isActive]);

  const iconsSize = useMemo(() => (size === 'Md' ? desktopButton : mobileButton), [size]);

  return (
    <div
      id={id}
      className={styleButton}
      onClick={onCLick}
    >
      <div className={classNames.roundIcon}>
        <RoundIcon
          width={iconsSize.rootIcon.width}
          height={iconsSize.rootIcon.height}
        />
      </div>
      <div className={insideIconStyle}>
        <div className={classNames.insideIcon}>
          <InsideRoundIcon
            width={iconsSize.rootIcon.width}
            height={iconsSize.rootIcon.height}
          />
        </div>
        <div className={classNames.icon}>
          <Icon
            width={iconsSize.innerIcon.width}
            height={iconsSize.innerIcon.height}
            name={icon}
          />
        </div>
      </div>
    </div>
  );
};

export default IconButton;
