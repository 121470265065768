import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

class SeatingPlace {
  constructor(scene, camera, renderer) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.loader = new GLTFLoader();

    this.objToAnimate = [];
  }

  generate() {
    let envMap;
    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    new RGBELoader()
      .load('./3d/hdr/hdr_env.hdr', (texture) => {
        envMap = pmremGenerator.fromEquirectangular(texture).texture;
        texture.dispose();
        pmremGenerator.dispose();
      });

    const lib = this.scene.seating_places;
    for (const seating in lib) {
      this.loader.load('./3d/seating_pointer/seating_pointer.glb',
        (gltf) => {
          const model = gltf.scene;
          this.scene.add(model);
          model.position.set(lib[seating][1][0], lib[seating][1][2], -lib[seating][1][1]);

          model.traverse((child) => {
            if (child.isMesh) {
              child.material.envMap = envMap;
              child.material.envMapIntensity = 0.75;
              child.material.needsUpdate = true;
              child.isSeating = true;
              child.visible = false;
              child.seatingTarget = lib[seating][0];
              this.scene.rayCastGroup.push(child);
              this.objToAnimate.push(child);
              // console.log(child)
            }
          });
          // anims[animObj] = {};
          // anims[animObj].mixer = new THREE.AnimationMixer(model);
          // anims[animObj].clips = gltf.animations;
          // anims[animObj].clip = THREE.AnimationClip.findByName(anims[animObj].clips,'bounce');
          // anims[animObj].action = anims[animObj].mixer.clipAction(anims[animObj].clip);
          // anims[animObj].action.play();
        });
    }
  }

  update(clock, playerPosition) {
    this.objToAnimate.forEach((obj) => {
      obj.lookAt(playerPosition);
      obj.position.y += (Math.sin(clock * 4) * -1) * 0.002;
    });
  }
}

export { SeatingPlace };
