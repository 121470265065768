import * as Three from 'three';
import { System } from '../../engine/System';
import { PlayerControlsComponent } from '../components/PlayerControls.component';
import { InputSystem } from '../../engine/systems/InputSystem';
import { TPControllerComponent } from '../components/TPController.component';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { PhysicSystem } from '../../engine/systems/Physic.system';
import { XRStatsSystem } from '../../engine/systems/XRStats.system';
import { FPControllerComponent } from '../components/FPController.component';

/**
 * Third person controller
 */
export class PlayerControlsSystem extends System {
  static get code(): string {
    return 'player_controls';
  }

  public moveIsBlock = false;

  public sceneIsBlock = false;

  protected lastPhysicsDebugButtonState = 'default';

  protected lastXRStatsButtonState = 'default';

  protected get inputSystem(): InputSystem | undefined {
    return this.app.getSystem(InputSystem);
  }

  public onUpdate() {
    this.componentManager.getComponentsByType(PlayerControlsComponent).forEach((playerControlsComponent) => {
      if (this.app.renderer.xr.isPresenting) return; // todo: add xr input handling
      this.handleCharacterControllerSwitch(playerControlsComponent);
      if (!this.sceneIsBlock) this.handleCharacterControls(playerControlsComponent);
      this.checkPlayerWatchVideo(playerControlsComponent);
      this.handlePhysicsDebugControls();
      this.handleXRStatsControls();
    });
  }

  // maybe need move to separate system
  public toggleCharacterController(playerControlsComponent: PlayerControlsComponent): void {
    const tPControllerComponent = playerControlsComponent.entity.getComponentOrFail(TPControllerComponent);
    const fPControllerComponent = playerControlsComponent.entity.getComponentOrFail(FPControllerComponent);
    tPControllerComponent.isInitialized = false;
    fPControllerComponent.isInitialized = false;
    tPControllerComponent.enabled = !tPControllerComponent.enabled;
    fPControllerComponent.enabled = !fPControllerComponent.enabled;
  }

  protected checkPlayerWatchVideo(playerControlsComponent: PlayerControlsComponent): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.app.sceneManager.currentThreeScene.playerWatchingVideo) {
      this.disabled(playerControlsComponent);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (!this.app.sceneManager.currentThreeScene.playerWatchingVideo && playerControlsComponent.isChangeControl) {
      this.enabled(playerControlsComponent);
    }
  }

  protected disabled(playerControlsComponent: PlayerControlsComponent): void {
    const tPControllerComponent = playerControlsComponent.entity.getComponentOrFail(TPControllerComponent);
    const fPControllerComponent = playerControlsComponent.entity.getComponentOrFail(FPControllerComponent);
    let controllerComponent: TPControllerComponent | FPControllerComponent | undefined;

    if (tPControllerComponent.enabled) controllerComponent = tPControllerComponent;
    if (fPControllerComponent.enabled) controllerComponent = fPControllerComponent;
    if (controllerComponent) {
      playerControlsComponent.isChangeControl = true;
      playerControlsComponent.controlBeforeChange = controllerComponent;
    }
    tPControllerComponent.enabled = false;
    fPControllerComponent.enabled = false;
  }

  public enabled(playerControlsComponent: PlayerControlsComponent): void {
    playerControlsComponent.isChangeControl = false;
    if (!playerControlsComponent.controlBeforeChange) return;
    playerControlsComponent.controlBeforeChange.enabled = true;
  }

  protected handleCharacterControllerSwitch(playerControlsComponent: PlayerControlsComponent): void {
    const inputSystem = this.app.getSystem(InputSystem);

    if (!inputSystem || !inputSystem.keyboard.getKeyByCode('KeyC').wasPressedThisFrame) return;

    this.toggleCharacterController(playerControlsComponent);
  }

  protected handleCharacterControls(playerControlsComponent: PlayerControlsComponent): void {
    const tPControllerComponent = playerControlsComponent.entity.getComponentOrFail(TPControllerComponent);
    const fPControllerComponent = playerControlsComponent.entity.getComponentOrFail(FPControllerComponent);

    let controllerComponent: TPControllerComponent | FPControllerComponent | undefined;

    if (tPControllerComponent.enabled) controllerComponent = tPControllerComponent;
    if (fPControllerComponent.enabled) controllerComponent = fPControllerComponent;

    if (!controllerComponent) return;

    const { cameraSensitivity } = playerControlsComponent;
    const cameraDelta = this.getCameraDelta();

    const movementDirection = this.getMovementDirection(playerControlsComponent);
    movementDirection.setY(movementDirection.y * -1);
    if (!this.moveIsBlock) controllerComponent.movementVector.copy(movementDirection);
    controllerComponent.cameraTheta += (cameraDelta.x * cameraSensitivity);
    controllerComponent.cameraPhi -= (cameraDelta.y * cameraSensitivity);
    controllerComponent.cameraPhi -= (cameraDelta.y * cameraSensitivity);
    controllerComponent.sprintIsActive = this.inputSystem?.keyboard?.getKeyByCode('ShiftLeft').isPressed ?? false;

    const wheelDeltaY = this.inputSystem?.mouse.wheelDelta.y;
    if (tPControllerComponent.enabled && !!wheelDeltaY) {
      tPControllerComponent.cameraDistance += Math.sin(-wheelDeltaY) * 0.4;
    }
  }

  protected handlePhysicsDebugControls(): void {
    if (!this.debugButtonPressedInCurrentFrame()) return;

    const { debugDrawer } = this.app.getSystemOrFail(PhysicSystem);
    debugDrawer.enabled = !debugDrawer.enabled;
  }

  protected handleXRStatsControls(): void {
    const statsSystem = this.app.getSystemOrFail(XRStatsSystem);

    if (!statsSystem) return;
    if (!this.statsButtonPressedInCurrentFrame()) return;

    statsSystem.toggleEnabled();
  }

  protected getMovementDirection(component: PlayerControlsComponent): Three.Vector2 {
    const movementDirectionVector = new Three.Vector2(0, 0);

    if (component.virtualMovementDirection.length() !== 0) {
      return movementDirectionVector.copy(component.virtualMovementDirection).clampScalar(-1, 1);
    }
    if (this.inputSystem) {
      if (this.inputSystem.keyboard.getKeyByCode('KeyW').isPressed) {
        movementDirectionVector.y = 1;
      }
      if (this.inputSystem.keyboard.getKeyByCode('KeyS').isPressed) {
        movementDirectionVector.y = -1;
      }
      if (this.inputSystem.keyboard.getKeyByCode('KeyA').isPressed) {
        movementDirectionVector.x = -1;
      }
      if (this.inputSystem.keyboard.getKeyByCode('KeyD').isPressed) {
        movementDirectionVector.x = 1;
      }
      return movementDirectionVector;
    }
    return movementDirectionVector;
  }

  protected getCameraDelta(): Three.Vector2 {
    const cameraDeltaVector = new Three.Vector2(0, 0);

    if (!this.inputSystem) return cameraDeltaVector;

    const { touchscreen, mouse } = this.inputSystem;
    const { primaryTouch } = touchscreen;

    if (mouse.leftButton.isPressed && !mouse.leftButton.wasPressedThisFrame) {
      return cameraDeltaVector.copy(mouse.delta.threeVector2);
    }

    if (primaryTouch.press.isPressed && !primaryTouch.press.wasPressedThisFrame) {
      return cameraDeltaVector.copy(primaryTouch.delta.threeVector2);
    }

    return new Three.Vector2();
  }

  // todo: improve xrinput system with current frame states
  // todo: TEMPORARY here
  protected debugButtonPressedInCurrentFrame(): boolean {
    const xRInputSystem = this.app.getSystem(XRInputSystem);
    const inputSystem = this.app.getSystem(InputSystem);

    if (this.app.renderer.xr.isPresenting) {
      if (!xRInputSystem) return false;

      const currentState = xRInputSystem.getBButton().state;
      const prevState = this.lastPhysicsDebugButtonState;

      if (currentState === 'touched') {
        this.lastPhysicsDebugButtonState = currentState;
        return false;
      }

      if (currentState === prevState) return false;

      this.lastPhysicsDebugButtonState = currentState;

      return currentState === 'pressed';
    }

    if (!inputSystem) return false;
    if (process.env.NODE_ENV === 'development') return inputSystem.keyboard.getKeyByCode('KeyB').wasPressedThisFrame;
    return false;
  }

  // todo: improve xrinput system with current frame states
  // todo: TEMPORARY here
  protected statsButtonPressedInCurrentFrame(): boolean {
    const xRInputSystem = this.app.getSystem(XRInputSystem);
    const inputSystem = this.app.getSystem(InputSystem);

    if (this.app.renderer.xr.isPresenting) {
      if (!xRInputSystem) return false;

      const currentState = xRInputSystem.getAButton().state;
      const prevState = this.lastXRStatsButtonState;

      if (currentState === 'touched') {
        this.lastXRStatsButtonState = currentState;
        return false;
      }

      if (currentState === prevState) return false;

      this.lastXRStatsButtonState = currentState;

      return currentState === 'pressed';
    }

    if (!inputSystem) return false;

    if (process.env.NODE_ENV === 'development') return inputSystem.keyboard.getKeyByCode('KeyX').wasPressedThisFrame;
    return false;
  }
}
