import { Component } from '../../engine/Component';
import { Entity } from '../../engine/Entity';

// todo: make configurable (any primitive), refactor
export class XRFPControllerComponent extends Component {
  public cameraEntity!: Entity;

  public avatarEntity?: Entity;

  public isInitialized = false;

  public baseVelocity = 2.8;

  static get code(): string {
    return 'x_r_f_p_controller';
  }

  public getCameraEntityOrFail(): Entity {
    if (!this.cameraEntity) throw new Error('Camera entity not found');

    return this.cameraEntity;
  }

  public getAvatarEntityOrFail(): Entity {
    if (!this.avatarEntity) throw new Error('Avatar entity not found');

    return this.avatarEntity;
  }
}
