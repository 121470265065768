import React, {
  FC, useCallback, useContext, useEffect, useState,
} from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { Button, Snackbar } from '@mui/material';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { MathUtils } from 'three';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { Channel as ChannelType } from 'stream-chat/dist/types/channel';
import LeftSideComponent from './LeftSide/LeftSideComponent';
import RightSideComponent from './RightSide/RightSideComponent';
import useAudio from '../../hooks/audio/useAudio';
import useConference from '../../hooks/audio/useConference';
import { Application } from '../../engine/Application';
import SceneInteraction from '../../Scene/SceneInteraction';
import useParticipants from '../../hooks/audio/useParticipants';
import { PlayerComponent } from '../../domain/components/Player.component';
import { SelectPlayerType } from '../../types/SelectPlayersType';
import useMediaQuery from '../../hooks/useMediaQuery';
import { devices } from '../../types/devices';
import { PlayerPosition } from '../../constans/PlayerPosition';
import SnackbarNotification from '../../components/NotificationSnackbar/NotificationSnackbar';
import { AudioContext } from '../../context/AudioProvider/AudioProvider';
import { AbstractScene } from '../../engine/scene/AbstractScene';
import classNames from './SportActions.module.css';
import { AnimatorComponent } from '../../engine/components/Animator.component';

export interface BottomActionsProps {
  roomId?: string;
  isAdmin?: boolean;
  isSharedVoice?: boolean;
  app: Application | null;
  joinInfo: string;
  onChangeAvatar: () => void;
  leaveChatHandler: () => void;
  userView: string;
  chatClient?: StreamChat<DefaultGenerics> | null;
  channelClient?: ChannelType<DefaultGenerics> | null;
  isDisconnect?: boolean;
}

const SportActions: FC<BottomActionsProps> = (props) => {
  const {
    roomId = '',
    isAdmin = false,
    onChangeAvatar = () => undefined,
    leaveChatHandler = () => undefined,
    chatClient,
    isSharedVoice,
    channelClient,
    isDisconnect,
    userView = '',
    app,
    joinInfo,
  } = props;

  const [sceneIntersections, setSceneInteraction] = useState<undefined | SceneInteraction>();

  const {
    setApplication,
  } = useContext(AudioContext);

  useEffect(() => {
    setSceneInteraction(app?.getSystemOrFail(SceneInteraction));
    if (app !== null) setApplication(app);
  }, [app]);

  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [isOpenUnion, setIsOpenUnion] = useState<boolean>(false);
  const [player, setPlayer] = useState<SelectPlayerType>({
    ownerId: '',
    isClick: false,
    networkId: '',
  });
  const [isClickAudio, setIsCLickAudio] = useState(false);
  const [isClickLogout, setIsClickLogout] = useState<boolean>(false);
  const [isClickCamera, setIsClickCamera] = useState<boolean>(false);
  const { isConference } = useConference();
  const { device } = useMediaQuery();
  const [velocity, setVelocity] = useState({ x: 0, y: 0 });
  const [playerWatchingVideo, setPlayerWatchingVideo] = useState<boolean>(false);
  const { closeSession, leaveConference, setIsConference } = useConference();
  const [isHideNotification, setIsHideNotification] = useState(false);
  const { startParticipantAudio, stopParticipantAudio } = useAudio();
  const { participants, kickParticipantHandler, participantsStatus } = useParticipants();
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);
  const [isHideAll, setIsHideAll] = useState<boolean>(false);
  const [isPlayerSeat, setIsPlayerSeat] = useState<boolean>(false);

  const [playCheerAnimation, setPlayCheerAnimation] = useState<boolean>(false);

  const closeUnionModalHandler = useCallback(() => {
    setIsOpenUnion(false);
  }, [isOpenUnion]);

  useEffect(() => {
    if (player && app !== null && isClickLogout) {
      if (player.ownerId) {
        const participantItem = participants.find((value) => value.info.externalId === player?.ownerId);
        if (participantItem && participantItem.id) {
          kickParticipantHandler(participantItem);
          setIsClickLogout(false);
        }
      }
    }
  }, [app, player, isClickLogout, participants]);

  useEffect(() => {
    setIsHideNotification(true);
  }, []);

  const changeFullScreenHandler = useCallback(() => {
    if (isHideNotification) setIsHideNotification(false);
  }, [isHideNotification]);

  const disableFullScreenHandler = useCallback(() => {
    if (!isHideNotification) setIsHideNotification(true);
  }, [isHideNotification]);

  useEffect(() => {
    if (player && app !== null && isClickAudio) {
      if (player.ownerId) {
        const participantItem = participants.find((value) => value.info.externalId === player?.ownerId);
        if (participantItem && participantItem.id) {
          const { isLocalAudio } = participantsStatus[participantItem.id];
          setIsCLickAudio(false);
          if (isLocalAudio) stopParticipantAudio(participantItem);
          else startParticipantAudio(participantItem);
        }
      }
    }
  }, [joinInfo, isClickAudio, player]);

  requestAnimationFrame(() => {
    if (VoxeetSDK.conference.current !== null) {
      if (velocity.x === 0 && velocity.y === 0) {
        sceneIntersections?.checkPlayerIsSeated();
        setIsPlayerSeat(sceneIntersections?.checkPlayerIsSeated() || false);
        const isWatch = sceneIntersections?.checkPlayerIsWatching();
        setPlayerWatchingVideo(isWatch || false);
      }
      const characterEntity = app?.sceneManager.currentScene?.threeScene.children.find((item) => item.name === 'CharacterEntity');
      if (!characterEntity) return;
      const position = characterEntity && characterEntity.position !== null
        ? characterEntity.position : { x: PlayerPosition.x, y: PlayerPosition.y, z: PlayerPosition.z };
      const rotation = characterEntity ? characterEntity.rotation : { z: 0, y: 0, x: 0 };
      const x = (position !== null && position.x) ? PlayerPosition.x + position.x : PlayerPosition.x;
      const { y } = PlayerPosition;
      const z = (position !== null && position.z) ? PlayerPosition.z + position.z : PlayerPosition.z;

      if (VoxeetSDK.session.participant && position !== null && joinInfo && isSharedVoice) {
        try {
          VoxeetSDK.conference.setSpatialPosition(VoxeetSDK.session.participant, { x, y, z });
        } catch (e) {
          console.log('sorry you are kicked');
        }
      }
      if (VoxeetSDK.session.participant && joinInfo && isSharedVoice) {
        try {
          VoxeetSDK.conference.setSpatialDirection(VoxeetSDK.session.participant, {
            x: 0,
            y: rotation.y * 60,
            z: 0,
          });
        } catch (e) {
          console.log('sorry you are kicked');
        }
      }
    }
  });

  useEffect(() => {
    if (!app) return;
    if (!sceneIntersections) return;
    if (velocity.x !== 0 || velocity.y !== 0) sceneIntersections.blockMove(true);
    sceneIntersections.moveCharacter(velocity.x, velocity.y);
    if (!joinInfo) return;
    if (isConference) sceneIntersections.setHost(isAdmin);
  }, [app, player, isClickAudio, playerWatchingVideo, isClickLogout, isConference, joinInfo, sceneIntersections, velocity]);

  useEffect(() => {
    if (sceneIntersections) {
      sceneIntersections.app.componentManager.getComponentsByType(PlayerComponent).forEach((component) => {
        if (component.isLogoutClick) {
          setIsClickLogout(true);
          component.isLogoutClick = false;
        }
        if (component.isOffAudioClick) {
          setIsCLickAudio(true);
          component.isOffAudioClick = false;
        }
      });
    }
  }, [isClickAudio, isClickLogout, sceneIntersections, player]);

  useEffect(() => {
    const clickHandler = () => {
      if (!sceneIntersections) return;
      const playerCurrent = sceneIntersections.getPlayer();
      setPlayer(playerCurrent);
    };

    if (app !== null) {
      document.addEventListener('click', clickHandler);
      sceneIntersections?.app?.renderer.xr.getController(0).addEventListener('selectstart', clickHandler);
      sceneIntersections?.app?.renderer.xr.getController(1).addEventListener('selectstart', clickHandler);
    }
    return () => {
      document.removeEventListener('click', clickHandler);
      sceneIntersections?.app?.renderer.xr.getController(0).removeEventListener('selectstart', clickHandler);
      sceneIntersections?.app?.renderer.xr.getController(1).removeEventListener('selectstart', clickHandler);
    };
  }, [app, sceneIntersections]);

  const closeChatModalHandler = useCallback(() => {
    setIsChatOpen(false);
  }, [isChatOpen]);

  const { toggleAudio, isAudio } = useAudio();

  const showSeatsHandler = useCallback((value: boolean) => {
    if (app !== null) sceneIntersections?.showHideSeats(value);
  }, [app, sceneIntersections?.scene]);

  useEffect(() => {
    if (isChatOpen || isOpenUnion) sceneIntersections?.blockScene(true);
    else sceneIntersections?.blockScene(false);
  }, [isChatOpen, isOpenUnion]);

  useEffect(() => {
    VoxeetSDK.conference.on('participantUpdated', () => {
      if (!VoxeetSDK.session.participant.info.name && VoxeetSDK.session.participant.status === 'Kicked') {
        try {
          closeSession()
            .then(() => {
              setIsOpenSnackbar(true);
              leaveChatHandler();
              leaveConference();
              setIsConference(false);
            });
        } catch (e) {
          console.log(e);
        }
      }
      setIsOpenSnackbar(false);
    });
  }, []);

  const toggleCameraHandler = useCallback(() => {
    if (app !== null) {
      setIsClickCamera(!isClickCamera);
      sceneIntersections?.toggleCamera();
    }
  }, [app, isClickCamera]);

  const joystickMoveHandler = useCallback((event: IJoystickUpdateEvent) => {
    if (event.x !== null && event.y !== null) {
      const x = MathUtils.mapLinear(event.x, -50, 50, -1, 1);
      const z = MathUtils.mapLinear(event.y, -50, 50, -1, 1);
      setVelocity({
        x,
        y: z * -1,
      });
      if (sceneIntersections) {
        setPlayCheerAnimation(false);
        sceneIntersections.happyCharacter(false);
      }
    }
  }, [sceneIntersections]);

  const throwStuffHandler = useCallback(() => {
    sceneIntersections?.throwStuff();
  }, [app, sceneIntersections]);

  const joystickStopHandler = useCallback(() => {
    setVelocity({
      x: 0,
      y: 0,
    });
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (isHideNotification) {
        setIsHideNotification(false);
        setIsDeletePopup(true);
      }
    }, 5000);
    return () => {
      clearTimeout(interval);
    };
  }, [isHideNotification]);

  const showAllButtonsHandler = useCallback(() => {
    setIsHideAll(!isHideAll);
  }, [isHideAll]);

  const happyHandler = useCallback(() => {
    if (sceneIntersections) {
      sceneIntersections.happyCharacter(!playCheerAnimation);
      setPlayCheerAnimation(!playCheerAnimation);
    }
  }, [sceneIntersections, playCheerAnimation]);

  useEffect(() => {
    if (isPlayerSeat && sceneIntersections) {
      sceneIntersections.happyCharacter(false);
      setPlayCheerAnimation(false);
    }
  }, [isPlayerSeat, sceneIntersections]);

  useEffect(() => {
    if (!playerWatchingVideo && isHideAll) setIsHideAll(false);
  }, [playerWatchingVideo]);

  if (!VoxeetSDK.session.participant && VoxeetSDK.conference.current === null) return <div />;

  return (
    <>
      {!isHideAll && <>
        <LeftSideComponent
          playerIsWatchVideo={playerWatchingVideo}
          device={device}
          onJoystickStop={joystickStopHandler}
          onJoystickMove={joystickMoveHandler}
          isCameraClick={isClickCamera}
          joinInfo={joinInfo}
          isAudio={isAudio}
          onShowSeats={showSeatsHandler}
          onToggleMicrophone={toggleAudio}
          onToggleCamera={toggleCameraHandler}
          isOpen={isChatOpen}
          onClickChatButton={() => {
            setIsChatOpen(!isChatOpen);
          }}
          isChatOpen={isChatOpen}
          onCloseChatModal={closeChatModalHandler}
          chatClient={chatClient}
          channelClient={channelClient}
          isDisconnect={isDisconnect}
        />
        <RightSideComponent
          onHappy={happyHandler}
          isSeat={isPlayerSeat}
          modalUserName={userView}
          onChangeAvatar={onChangeAvatar}
          onThrowStuff={throwStuffHandler}
          device={device}
          isConference={isConference}
          isAdmin={isAdmin}
          roomId={roomId}
          onCloseUnionModal={closeUnionModalHandler}
          isOpen={isOpenUnion}
          onUnionClick={() => {
            if (isConference) setIsOpenUnion(!isOpenUnion);
          }}
        />
      </>}
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message="you were kicked"
      />
      <Snackbar
        open={isHideNotification}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={<SnackbarNotification
          title="Enable full screen mode"
          onChangeFullScreen={changeFullScreenHandler}
          onHideNotistack={() => {
            setIsHideNotification(false);
            setIsDeletePopup(true);
          }}
        />}
      />
      {device === devices.mobile && <Snackbar
        classes={{
          root: classNames.videoSnackbar,
        }}
        open={playerWatchingVideo}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={
          <div style={{ display: 'flex', justifyContent: 'space-between', columnGap: 10 }}>
            <Button
              size="small"
              variant="text"
              id="snackbar"
              color="primary"
            >
              press to exit
            </Button>
            <Button
              size="small"
              onClick={showAllButtonsHandler}
              variant="text"
              color="primary"
            >
              {isHideAll ? 'show interface' : 'hide interface'}
            </Button>
          </div>
      }
      />}
      {(!isDeletePopup && !playerWatchingVideo) && <Snackbar
        classes={{
          root: classNames.rootSnackbar,
        }}
        open={!isHideNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={
          <Button
            fullWidth
            onClick={() => {
              disableFullScreenHandler();
              window.parent.dispatchEvent(new CustomEvent('fullscreenExit'));
            }}
            variant="text"
            color="success"
          >
            exit
          </Button>
}
      />}
    </>
  );
};

export default SportActions;
