import { Component } from '../../engine/Component';

export class PlayerComponent extends Component {
  public isOffAudioClick = false;

  public isOnAudioClick = false;

  public isLogoutClick = false;

  static get code(): string {
    return 'player';
  }
}
