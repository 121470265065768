import { VRM } from '@pixiv/three-vrm';
import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';
import { VrmIK } from '../services/VrmIK';
import { TPControllerComponent } from './TPController.component';
import { IKConfig } from '../services/VrmIK/types';
import { defaultIKConfig } from '../services/VrmIK/DefaultVrmConfig';

// TODO: add ik config
export type VrmIKComponentOptions = ComponentOptions & {
  data?: {
    vrm?: VRM;
    ikConfig?: IKConfig;
  };
};

export type AttachInfo = Record<string, THREE.Object3D | undefined>;

export default class VrmIKComponent extends EngineComponent {
  public vrm?: VRM;

  public ikSolver?: VrmIK;

  public transforms: Record<string, TransformControls> = {};

  public skeletonHelper: THREE.SkeletonHelper | null = null;

  public _onTransformDragging: (event: THREE.Event) => void;

  public debugEnabled = false;

  public enabled = true;

  public attached = false;

  public goalsPosition: Record<string, THREE.Vector3> = {};

  public ikConfig: IKConfig;

  constructor(options: VrmIKComponentOptions) {
    super(options);
    this.vrm = options.data?.vrm;
    const scene = this.entity.app.sceneManager.currentThreeScene;
    this.ikConfig = options.data?.ikConfig || defaultIKConfig;
    this.ikSolver = this.vrm && scene ? new VrmIK(this.vrm, scene, this.ikConfig) : undefined;
    this._onTransformDragging = this.onTransformDragging.bind(this);
    // this.enableDebug();
  }

  setVrm(vrm?: VRM) {
    if (!vrm) return;
    this.vrm = vrm;
    const scene = this.entity.app.sceneManager.currentThreeScene;
    this.ikSolver = this.vrm && scene ? new VrmIK(this.vrm, scene, this.ikConfig) : undefined;
  }

  get scene(): THREE.Scene | null {
    return this.entity.app.sceneManager.currentThreeScene;
  }

  public resetBones() {
    this.getAllBones().forEach((bone) => {
      bone.rotation.set(0, 0, 0);
    });
  }

  public attach(attachInfo: AttachInfo) {
    // this.resetBones();
    this.ikSolver?.ikChains.forEach((chain) => {
      if (!attachInfo[chain.name] || !chain.target) return;
      attachInfo[chain.name]?.add(chain.target);
      chain.target.position.set(0, 0, 0);
      chain.target.rotation.set(0, 0, 0);
    });
    this.attached = true;
  }

  public detach() {
    this.ikSolver?.ikChains.forEach((chain) => {
      if (!chain.target) return;
      this.scene?.add(chain.target);
      if (this.goalsPosition[chain.name]) {
        chain.target.position.copy(this.goalsPosition[chain.name]);
      }
    });
    this.attached = false;
  }

  public saveGoalsPosition() {
    if (!this.attached) return;
    this.ikSolver?.ikChains.forEach((chain) => {
      if (!chain.target) return;
      // chain.target.rotation.set(0, 0, 0);
      this.goalsPosition[chain.name] = chain.target.getWorldPosition(new THREE.Vector3());
      // chain.target.updateMatrixWorld(true);
    });
  }

  public getAllBones(): THREE.Object3D[] {
    if (!this.ikSolver) return [];
    const bones: THREE.Object3D[] = [];
    this.ikSolver.ikChains.forEach((chain) => {
      if (chain.effector) {
        bones.push(chain.effector.bone);
      }
      chain.joints.forEach((joint) => bones.push(joint.bone));
    });
    return bones;
  }

  getOrCreateTransformControl(chainName: string) {
    if (!this.transforms[chainName] && this.entity.app.camera) {
      this.transforms[chainName] = new TransformControls(this.entity.app.camera, this.entity.app.renderer.domElement);
    }
    return this.transforms[chainName];
  }

  toggleTransformsMode() {
    Object.keys(this.transforms).forEach((chainName) => {
      this.transforms[chainName].mode = this.transforms[chainName].mode === 'translate' ? 'rotate' : 'translate';
    });
  }

  onTransformDragging(event: THREE.Event) {
    this.entity.app.componentManager.getComponentsByType(TPControllerComponent).forEach((component) => {
      component.enabled = !event.value;
    });
  }

  public toggleDebug() {
    this.debugEnabled = !this.debugEnabled;
    if (this.debugEnabled) this.enableDebug();
    else this.disableDebug();
  }

  enableDebug() {
    if (!this.ikSolver) return;
    this.ikSolver.ikChains.forEach((chain) => {
      if (!chain.target) return;
      const transCtrl = this.getOrCreateTransformControl(chain.name);
      if (!transCtrl) return;
      transCtrl.size = 0.3;
      transCtrl.attach(chain.target);
      transCtrl.removeEventListener('dragging-changed', this._onTransformDragging);
      transCtrl.addEventListener('dragging-changed', this._onTransformDragging);
      if (this.scene) {
        this.scene.add(transCtrl);
      }
    });
    if (!this.skeletonHelper) {
      this.skeletonHelper = new THREE.SkeletonHelper(this.entity);
    }
    this.scene?.add(this.skeletonHelper);
  }

  disableDebug() {
    if (!this.ikSolver) return;
    this.ikSolver.ikChains.forEach((chain) => {
      const transCtrl = this.getOrCreateTransformControl(chain.name);
      if (!transCtrl) return;
      transCtrl.detach();
      transCtrl.removeEventListener('dragging-changed', this._onTransformDragging);
      if (this.scene) {
        this.scene.remove(transCtrl);
      }
      if (this.skeletonHelper) this.scene?.remove(this.skeletonHelper);
    });
  }

  public enable() {
    this.enabled = true;
  }

  public disable() {
    this.enabled = false;
  }
}
