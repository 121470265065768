import * as ThreeMeshUI from 'three-mesh-ui';
import * as THREE from 'three';
import * as Three from 'three';
import { addInteractivityToElement } from '../helpers/addInteractivityToElement';
import { setAbsolutePosition } from '../helpers/setAbsolutePosition';

export type WrapperParams = {
  id: string;
  width: number;
  height: number;
  fontFamily?: string;
  fontTexture?: string;
  justifyContent?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  contentDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  backgroundColor?: string;
  backgroundOpacity: number;
  absolutePosition?: Three.Vector2;
  borderRadius?: [number, number, number, number];
  autoLayout?: boolean;
  visible: boolean;
};

export function Wrapper(params: WrapperParams, children: ThreeMeshUI.Block[]): ThreeMeshUI.Block {
  const container = new ThreeMeshUI.Block({
    width: params.width,
    height: params.height,
    fontFamily: params.fontFamily,
    fontTexture: params.fontTexture,
    justifyContent: params.justifyContent,
    contentDirection: params.contentDirection,
    backgroundOpacity: params.backgroundOpacity,
    backgroundColor: new THREE.Color(0xffffff),
    offset: 0.1,
    borderRadius: params.borderRadius || [0, 0, 0, 0],
  });
  container.visible = params.visible;
  // todo: missing types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  container.autoLayout = params.autoLayout ?? true;

  if (params.absolutePosition) {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    container.autoLayout = false;
    container.addEventListener('added', () => {
      if (container.parent) setAbsolutePosition(container.parent, container, params.absolutePosition);
    });
  }

  addInteractivityToElement({
    id: params.id,
    element: container,
    defaultAttributes: { },
    hoveredAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
    activeAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
  });

  // @ts-ignore
  container.backgroundMaterial.depthTest = true;
  // @ts-ignore
  container.backgroundMaterial.depthWrite = false;

  container.userData.uiData = {
    id: params.id,
    interactive: true,
  };

  if (children.length) container.add(...children);

  return container;
}
