import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const ArrowUpIcon: FC<IconsType> = memo((props) => {
  const {
    height = 24,
    width = 24,
  } = props;

  /* eslint-disable-next-line react/style-prop-object */
  return (
    <svg
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 25 24"
      xmlSpace="preserve"
    >
      <path d="M19.5,20.3c-0.1,0-0.2,0-0.3-0.1C19.1,20.1,19,20,19,19.9c0-0.1,0-0.3,0.1-0.4c0,0,0-0.1,0-0.1c-0.4,0.4-1.1,0.5-1.6,0.2
	c-0.7-0.4-0.9-1.2-0.5-1.9c0.2-0.3,0.5-0.6,0.8-0.7c-0.4-0.4-1-0.6-1.5-0.7c-0.3-0.1-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.6-0.5
	c1.2,0.2,2.2,0.9,2.9,1.9l0.1,0.1c1,0.6,1.3,1.9,0.7,2.9C19.9,20.2,19.7,20.3,19.5,20.3z M18.3,17.9c-0.1,0-0.3,0.2-0.4,0.3
	c0,0,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0,0.3-0.1h0C18.4,18.3,18.4,18,18.3,17.9z M4.3,20.3c0,0-0.1,0-0.1,0
	c-0.1,0-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4l0.5-2.1C4.3,17.2,4.6,17,4.9,17c0.3,0.1,0.5,0.4,0.4,0.7l-0.3,1.1l1.3-0.5
	c-0.7-0.7-1.1-1.7-1.2-2.7L5,14.2c0-0.2,0-0.3,0.1-0.4s0.2-0.2,0.4-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4l0.1,1.5
	c0.1,0.9,0.5,1.7,1.2,2.3L9.8,17c-2-1.1-3.4-3-4-5.2c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.2
	c0.3,0,0.6,0.2,0.6,0.5c0.4,2.5,2.1,4.7,4.5,5.6l1.1-0.4c-1.2-0.6-2.2-1.4-3.1-2.3c-1-1-1.9-2.1-2.3-3.1C6.1,9,6.4,8.3,6.8,7.9
	c0.4-0.3,0.8-0.5,1.3-0.4c0.3,0,0.5,0.3,0.5,0.6c0,0.2-0.1,0.3-0.2,0.4C8.2,8.6,8.1,8.6,8,8.6c-0.2,0-0.3,0-0.4,0.1
	c-0.1,0.1-0.1,0.6,0.3,1.4c0.2,0.5,0.6,1,1,1.6c-0.1-0.1-0.1-0.3,0-0.4l0.4-0.9C9.9,8.9,10,7.4,9.7,6c0-0.2,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.1,0.2-0.2C9.5,5.4,9,5,9,4.5c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8c0,0.3-0.2,0.6-0.5,0.8c0.1,0,0.2,0,0.3,0.1
	c0.1,0.1,0.2,0.2,0.2,0.4c0.4,1.7,0.2,3.4-0.4,5l-0.4,0.9C9.8,12,9.5,12.1,9.2,12c0,0-0.1,0-0.1-0.1c0.3,0.3,0.6,0.7,0.9,1
	c0.8,0.9,1.8,1.6,2.9,2.1c0.8,0.4,1.3,0.4,1.4,0.3c0.1-0.1,0.1-0.4-0.1-1c-0.1-0.3,0-0.6,0.3-0.7c-0.5,0-0.9,0-1.3,0.1l-1,0.2
	c-0.3,0-0.6-0.2-0.6-0.5c-0.1-0.3,0.1-0.6,0.4-0.7l1-0.2c1.6-0.3,3.2-0.1,4.7,0.5l0,0c0.2,0.1,0.4,0.3,0.4,0.5c0,0,0,0,0,0
	c0.2-0.2,0.5-0.3,0.8-0.3c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.1
	c-0.1,0-0.1,0-0.2,0c-0.7-0.3-1.4-0.4-2.2-0.5c0.1,0.1,0.2,0.2,0.2,0.3c0.5,1.2,0.2,1.9-0.1,2.2c-0.2,0.2-0.4,0.3-0.7,0.4l0,0
	l-9.9,3.7C4.4,20.3,4.3,20.3,4.3,20.3z M12.5,12.6c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.3,0-0.6,0.3-0.8l5.1-2.4
	c0,0-0.1-0.1-0.1-0.1l-0.2-0.2c-0.2-0.2-0.2-0.6,0-0.8c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3,0,0.4,0.1c0-0.3,0.3-0.5,0.6-0.5
	S18.9,7.7,19,8c0.2-0.2,0.6-0.2,0.8,0v0c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.3-0.1,0.4c0.3,0,0.5,0.3,0.5,0.6s-0.2,0.5-0.5,0.6
	c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.6,0.2-0.8,0c0,0.3-0.3,0.5-0.6,0.5c-0.3,0-0.6-0.3-0.6-0.6v-0.3c0-0.1,0-0.3,0.1-0.4l-5.2,2.5
	C12.7,12.5,12.6,12.6,12.5,12.6z M18.4,9.8c0.1,0,0.2,0,0.2,0c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.2,0-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1,0-0.2,0c0.1,0.1,0.2,0.1,0.2,0.3
	C18.8,9.4,18.7,9.7,18.4,9.8z M10.7,12.3c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.6,0-0.8L13,8.9c0.2-0.2,0.6-0.2,0.8,0
	c-0.1-0.1-0.2-0.2-0.2-0.4V7.2c0-0.3,0.3-0.6,0.6-0.6h1.3c0.3,0,0.6,0.3,0.6,0.6v1.3c0,0.3-0.3,0.6-0.6,0.6h-1.3
	c-0.1,0-0.3-0.1-0.4-0.2c0,0,0,0,0,0l0,0c0.2,0.2,0.2,0.6,0,0.8L11,12.2C10.9,12.2,10.8,12.3,10.7,12.3z M14.7,8h0.2V7.8h-0.2V8z
	 M12.3,8c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1S12.9,8,12.3,8z M4.8,7.4C4.7,7.4,4.7,7.4,4.8,7.4
	c-0.2,0-0.4-0.1-0.5-0.2C4.2,7,4.2,6.9,4.2,6.7l0.1-0.6L3.9,5.6C3.8,5.6,3.7,5.4,3.7,5.3c0-0.1,0-0.3,0.1-0.4C3.9,4.8,4,4.7,4.2,4.7
	l0.6-0.1L5.1,4c0.1-0.1,0.1-0.2,0.2-0.2C5.6,3.6,5.9,3.7,6.1,4l0.3,0.6L7,4.7c0.2,0,0.4,0.2,0.5,0.4l0,0c0.1,0.2,0,0.4-0.1,0.6
	L6.9,6.1L7,6.7C7,6.8,7,7,6.9,7.1C6.9,7.2,6.7,7.3,6.6,7.3c-0.1,0-0.3,0-0.4,0L5.6,7L5,7.3C5,7.3,4.9,7.4,4.8,7.4z M5.4,5.6
	c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1C5.6,5.5,5.5,5.6,5.4,5.6z M17.3,6.4
	c-0.3,0-0.6-0.3-0.6-0.6V5.6h-0.2c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h0.2V4.3c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v0.2h0.2
	c0.3,0,0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6h-0.2v0.2C17.9,6.2,17.6,6.4,17.3,6.4z"
      />
    </svg>
  );
});

export default ArrowUpIcon;
