import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

class ProductsInCenter {
  constructor(scene, camera, renderer) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.loader = new GLTFLoader();
    this.anims = {};
  }

  generate() {
    let envMap;
    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    new RGBELoader()
      .load('./3d/hdr/hdr_env.hdr', (texture) => {
        envMap = pmremGenerator.fromEquirectangular(texture).texture;
        texture.dispose();
        pmremGenerator.dispose();
      });

    const products = this.scene.productsInCenterArr;

    for (const item in products) {
      this.loader.load(products[item][0],
        (gltf) => {
          const model = gltf.scene;
          this.scene.add(model);
          model.position.set(products[item][1][0], products[item][1][2], -products[item][1][1]);

          model.traverse((child) => {
            if (child.isMesh) {
              child.material.envMap = envMap;
              child.material.envMapIntensity = 1;
              child.material.needsUpdate = true;
              child.haveAnno = true;
              child.annoName = item;
              this.scene.rayCastGroup.push(child);
            }
          });
          this.anims[item] = {};
          this.anims[item].mixer = new THREE.AnimationMixer(model);
          this.anims[item].clips = gltf.animations;
          this.anims[item].clip = THREE.AnimationClip.findByName(this.anims[item].clips, 'bounce');
          this.anims[item].action = this.anims[item].mixer.clipAction(this.anims[item].clip);
          this.anims[item].action.play();
        });
    }
  }

  update(deltaTime) {
    Object.keys(this.anims).forEach((item) => {
      this.anims[item].mixer.update(deltaTime);
    });
  }

  // random(number){
  //     return Math.floor(Math.random()*number)+1;
  //   }

  // makeid() {
  // var text = "";
  // var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  // for (var i = 0; i < 5; i++)
  //     text += possible.charAt(Math.floor(Math.random() * possible.length));

  // return text;
  // }
}

export { ProductsInCenter };
