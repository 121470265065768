import { PerspectiveCamera, Scene } from 'three';
import { AbstractScene } from '../../engine/scene/AbstractScene';
import { Application } from '../../engine/Application';
import { CameraComponent } from '../../engine/components/Camera.component';

export class ContainerScene extends AbstractScene {
  public _camera: PerspectiveCamera | null = null;

  public _appCamera: PerspectiveCamera | null = null;

  public get threeScene(): Scene {
    return this._threeScene;
  }

  public set threeScene(scene: Scene) {
    this._threeScene = scene;
  }

  public get camera(): PerspectiveCamera | null {
    return this._camera;
  }

  public set camera(cam: PerspectiveCamera | null) {
    this._camera = cam;
  }

  public getCameraComponent(app: Application): CameraComponent {
    return app.componentManager.getComponentsByType(CameraComponent)[0];
  }

  load(app: Application): Promise<void> {
    const cameraComponent = this.getCameraComponent(app);
    this._appCamera = cameraComponent.threeCamera;
    if (this._camera) {
      cameraComponent.threeCamera = this._camera;
    }
    return Promise.resolve(undefined);
  }

  destroy(app: Application): Promise<void> {
    const cameraComponent = this.getCameraComponent(app);
    if (this._appCamera) {
      cameraComponent.threeCamera = this._appCamera;
    }
    return Promise.resolve(undefined);
  }

  render(app: Application, delta: number) {
    super.render(app, delta);
  }
}
