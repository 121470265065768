import { useContext } from 'react';
import { AudioContext } from '../../context/AudioProvider/AudioProvider';
import { AudioContextType } from '../../context/AudioProvider/AudioProviderType';

export interface UseConferenceResult {
  openSession: AudioContextType['openSession'];
  closeSession: AudioContextType['closeSession'];
  isConference: AudioContextType['isConference'];
  setIsConference: AudioContextType['setIsConference'];
  leaveConference: AudioContextType['leaveConference'];
}

const useConference = (): UseConferenceResult => {
  const {
    openSession,
    closeSession,
    isConference,
    setIsConference,
    leaveConference,
  } = useContext(AudioContext);

  return {
    closeSession,
    isConference,
    leaveConference,
    setIsConference,
    openSession,
  };
};

export default useConference;
