import EventEmitter from 'eventemitter3';
import { Message } from './MessagesPool';
import { MessagesWorkerEventTypes, WorkerMessage, WorkerMessageTypes } from './WorkerTypes';
import ReceiveMessagesPool from './ReceiveMessagesPool';
import SendMessagesPool from './SendMessagesPool';
import config from '../../../config/index';

export type MessagesWorkerOptions = {
  inWorker?: boolean;
};

export default class MessagesWorker {
  public receiveMessagesPool: ReceiveMessagesPool;

  public sendMessagesPool: SendMessagesPool;

  public inWorker = false;

  public startReceiveHandler: NodeJS.Timer | null = null;

  public startSendHandler: NodeJS.Timer | null = null;

  // public transport: TransportPlutoNeo | null = null;

  protected _events: EventEmitter<MessagesWorkerEventTypes> = new EventEmitter<MessagesWorkerEventTypes>();

  constructor({ inWorker } : MessagesWorkerOptions = {}) {
    this.receiveMessagesPool = new ReceiveMessagesPool();
    this.sendMessagesPool = new SendMessagesPool();
    this.inWorker = typeof inWorker !== 'undefined' ? inWorker : this.inWorker;
  }

  public get events(): EventEmitter<MessagesWorkerEventTypes> {
    return this._events;
  }

  public connect() {
    // TODO: work only websockets -- move it on worker
    // const dcConfig = new Pluto.Dc_config('chat');
    // // dcConfig.ordered = true;
    // const plutoConfig = new Pluto.Connection_config(
    //   { url: 'wss://server-v2.wondersouq.web3dev.group/' },
    //   dcConfig,
    // );
    //
    // Pluto.Client.create(plutoConfig).then((client) => {
    //   this.transport = new TransportPlutoNeo(client);
    //   console.log(client);
    // });
  }

  public runWorker() {
    if (this.inWorker) {
      onmessage = (e: MessageEvent<WorkerMessage>) => {
        this.onWorkerMessage(e);
      };
    }
  }

  public start(fixedReceiveDelta = Number(config.fixedReceiveDelta), fixSendDelta = Number(config.fixedSendDelta)) {
    // console.log(fixedReceiveDelta, fixSendDelta);
    this.startReceiveHandler = setInterval(() => {
      this.updateReceivePool();
      // this.postMessage({ type: WorkerMessageTypes.tick });
    }, fixedReceiveDelta * 1000);

    this.startSendHandler = setInterval(() => {
      this.updateSendPool();
    }, fixSendDelta * 1000);

    this.connect();
  }

  public stop() {
    if (this.startReceiveHandler) {
      clearInterval(this.startReceiveHandler);
    }
  }

  public postMessage(e: WorkerMessage) {
    return this.onWorkerMessage(e);
  }

  public onWorkerMessage(e: MessageEvent<WorkerMessage> | WorkerMessage) {
    const message = e instanceof MessageEvent<WorkerMessage> ? e.data : e;
    switch (message.type) {
      case WorkerMessageTypes.startWorker:
        return this.start();
      case WorkerMessageTypes.stopWorker:
        return this.stop();
      case WorkerMessageTypes.receiveMessage:
        return this.receiveMessagesPool.receiveMessage(message.data);
      case WorkerMessageTypes.sendMessage:
        return this.sendMessagesPool.sendMessage(message.data);
      default:
    }
  }

  public sendWorkerMessage(message: WorkerMessage) {
    if (this.inWorker) {
      postMessage(message);
    } else {
      this.events.emit('onMessage', message);
    }
  }

  public updateReceivePool() {
    const messages = this.receiveMessagesPool.getMessagesAtInterval();
    messages.push(...this.receiveMessagesPool.requiredMessages);
    messages.sort((m1, m2) => {
      return m1.sendTime - m2.sendTime;
    });
    messages.forEach((message) => {
      this.processMessage(message);
    });
    this.receiveMessagesPool.clear();
  }

  public updateSendPool() {
    const messages = this.sendMessagesPool.getMessagePool();
    if (messages && messages.length > 0) {
      this.broadcastMessagesBatch(messages);
    }
  }

  public processMessage(message: Message) {
    this.sendWorkerMessage({ type: WorkerMessageTypes.processMessage, data: message });
  }

  public broadcastMessagesBatch(messages: Message[]) {
    this.sendWorkerMessage({ type: WorkerMessageTypes.broadcastMessagesBatch, data: messages });
  }

  public terminate() {
    this.stop();
  }
}
