import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { CircleButton, Container, Icon } from './elements/index';
import { ButtonId } from './enum/ButtonId';
import VolumeHight from '../../../assets/image/volume-high.png';
import VolumeSlash from '../../../assets/image/volume-slash.png';
import Logout from '../../../assets/image/logout.png';
import { PlayerComponent } from '../../components/Player.component';
import { Wrapper } from './elements/Wrapper';
import { PanelId } from './enum/PanelId';
import Microphone from '../../../assets/image/microphone.png';
import Text from './elements/Text';

export function makeDashboardTemplate(component: PlayerComponent): ThreeMeshUI.Block {
  return Container({
    width: 480 + 806,
    height: 500,
    id: PanelId.MainContainer,
    justifyContent: 'center',
    contentDirection: 'row',
    backgroundOpacity: 0,
    visible: true,
  }, [
    Wrapper({
      id: PanelId.StaticPanel,
      width: 480 + 806,
      height: 252,
      justifyContent: 'center',
      absolutePosition: new Three.Vector2(50, 0),
      contentDirection: 'row',
      backgroundOpacity: 0,
      visible: false,
    }, [
      CircleButton({
        id: ButtonId.OnAudio,
        absolutePosition: new Three.Vector2(((480 + 806) / 2) - 200, 0),
        size: 215,
        backgroundColor: new Three.Color().setStyle('#ffffff'),
        icon: Icon({
          imageUrl: VolumeHight,
          width: 108,
          height: 108,
        }),
        visible: true,
      }, []),
      CircleButton({
        id: ButtonId.OffAudio,
        absolutePosition: new Three.Vector2(((480 + 806) / 2) - 200, 0),
        size: 215,
        backgroundColor: new Three.Color().setStyle('#ffffff'),
        icon: Icon({
          imageUrl: VolumeSlash,
          width: 108,
          height: 108,
        }),
        visible: false,
      }, []),
      CircleButton({
        id: ButtonId.Logout,
        absolutePosition: new Three.Vector2(((480 + 806) / 2) + 100, 0),
        size: 215,
        backgroundColor: new Three.Color().setStyle('#ffffff'),
        icon: Icon({
          imageUrl: Logout,
          width: 108,
          height: 108,
        }),
        visible: true,
      }, []),
    ]),
    Wrapper({
      id: PanelId.InfoPanel,
      absolutePosition: new Three.Vector2(50, 0),
      width: 480 + 806,
      height: 400,
      justifyContent: 'center',
      contentDirection: 'row',
      backgroundOpacity: 0,
      visible: false,
    }, [
      CircleButton({
        id: ButtonId.Microphone,
        absolutePosition: new Three.Vector2(((330 + 806) / 2), 0),
        size: 215,
        backgroundColor: new Three.Color().setStyle('#ffffff'),
        icon: Icon({
          imageUrl: Microphone,
          width: 108,
          height: 108,
        }),
        visible: false,
      }, []),
      Text({
        absolutePosition: new Three.Vector2(((100 + 806) / 2), 250),
        id: 'Text',
      }),
    ]),
  ]);
}
