import React, {
  FC, memo, MouseEvent, useEffect, useMemo, useState,
} from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import { Joystick } from 'react-joystick-component';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { Channel as ChannelType } from 'stream-chat/dist/types/channel';
import { useWindowSize } from 'react-use';
import classNames from './LeftSideComponent.module.css';
import IconButton from '../../../components/IconButton/IconButton';
import ActionPanel from '../../ActionPanel/ActionPanel';
import MicrophoneToggleButton from '../../../components/ToggleMircophoneButton/MircophoneToggleButton';
import useConference from '../../../hooks/audio/useConference';
import { devices } from '../../../types/devices';
import ChatModal from '../../ChatModal/ChatModal';

export interface PositionProps {
  top: string;
  left: string;
  right: string;
  bottom: string;
}

export interface LeftSideComponentProps {
  onClickChatButton: (event: MouseEvent<HTMLDivElement>) => void;
  onShowSeats: (value: boolean) => void;
  onToggleMicrophone: (participant: Participant) => void;
  isOpen: boolean;
  isCameraClick: boolean;
  isAudio: boolean;
  playerIsWatchVideo: boolean;
  onToggleCamera?: () => void;
  onJoystickMove: (event: IJoystickUpdateEvent) => void;
  onJoystickStop: (event: IJoystickUpdateEvent) => void;
  device: string;
  onCloseChatModal: () => void;
  isChatOpen: boolean;
  chatClient?: StreamChat<DefaultGenerics> | null;
  channelClient?: ChannelType<DefaultGenerics> | null;
  isDisconnect?: boolean;
  joinInfo: string;
}

const LeftSideComponent: FC<LeftSideComponentProps> = memo((props) => {
  const {
    onClickChatButton = () => undefined,
    onToggleMicrophone = () => undefined,
    isAudio,
    onToggleCamera = () => undefined,
    isOpen = false,
    isCameraClick,
    playerIsWatchVideo = false,
    onShowSeats = () => undefined,
    onJoystickMove = () => undefined,
    onJoystickStop = () => undefined,
    device,
    onCloseChatModal = () => undefined,
    isChatOpen = false,
    chatClient,
    channelClient,
    isDisconnect = true,
    joinInfo,
  } = props;

  const [isShowSeats, setIsShowSeats] = useState<boolean>(false);
  const { isConference } = useConference();

  const { width } = useWindowSize();

  const root = useMemo(() => {
    return [
      classNames[`root_${device}`],
    ].join(' ');
  }, [device, playerIsWatchVideo]);

  return (
    <>
      <div className={root}>
        <ActionPanel
          side={width < 560 ? 'center' : 'flex-end'}
          direction="row"
        >
          {device === devices.mobile && <div className={classNames.joystick}>
            <Joystick
              size={140}
              baseColor="rgba(255, 255, 255, 0.6)"
              stickColor="#2586FF"
              move={onJoystickMove}
              start={onJoystickMove}
              stop={onJoystickStop}
            />
          </div>}
          <IconButton
            size={device === devices.mobile ? 'Xs' : 'Md'}
            onCLick={onClickChatButton}
            icon="chat"
            isActive={isOpen}
          />
          <MicrophoneToggleButton
            joinInfo={joinInfo}
            size={device === devices.mobile ? 'Xs' : 'Md'}
            disabled={!isConference}
            onToggleAudio={onToggleMicrophone}
            participant={VoxeetSDK.session.participant}
            isHasAudio={isAudio}
          />
          <IconButton
            size={device === devices.mobile ? 'Xs' : 'Md'}
            icon="camera"
            isActive={isCameraClick}
            onCLick={onToggleCamera}
          />
          <IconButton
            size={device === devices.mobile ? 'Xs' : 'Md'}
            isActive={isShowSeats}
            onCLick={() => {
              setIsShowSeats(!isShowSeats);
              onShowSeats(!isShowSeats);
            }}
            icon="up"
          />
        </ActionPanel>
      </div>
      <ChatModal
        channelClient={channelClient}
        chatClient={chatClient}
        isOpen={isChatOpen}
        isDisconnect={isDisconnect}
        onClose={onCloseChatModal}
      />
    </>
  );
});

export default LeftSideComponent;
