import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import VRControl from 'three-mesh-ui/examples/utils/VRControl.js';
import FontJSON from 'three-mesh-ui/examples/assets/Roboto-msdf.json';
import FontImage from 'three-mesh-ui/examples/assets/Roboto-msdf.png';
import { EcommerceEvents } from './EcommerceEvents.js';
import wallEcomerceImg from '../../assets/json/wallEcomerceImg.json';

class WallOfEcommerce {
  constructor(scene, camera, renderer) {
    this.camera = camera;
    this.scene = scene;
    this.renderer = renderer;
    this.realCamObj = this.scene.realCamObj;

    this.ecommerceEvents = new EcommerceEvents(this.renderer);
    this.raycaster = new THREE.Raycaster();
    this.textureLoader = new THREE.TextureLoader();
    this.mouse = new THREE.Vector2();
    this.mouse.x = this.mouse.y = null;

    this.objsToTest = [];
    this.objsToTestButton = [];

    window.addEventListener('pointermove', (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('pointerdown', () => {
      this.selectState = true;
    });

    window.addEventListener('pointerup', () => {
      this.selectState = false;
    });

    window.addEventListener('touchstart', (event) => {
      this.selectState = true;
      this.mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('touchend', () => {
      this.selectState = false;
      this.mouse.x = null;
      this.mouse.y = null;
    });

    this.vrControl = VRControl(this.renderer, this.camera, this.scene);

    // scene.add( this.vrControl.controllerGrips[ 0 ], this.vrControl.controllers[ 0 ] );

    this.vrControl.controllers[0].addEventListener('selectstart', () => {
      this.selectState = true;
    });
    this.vrControl.controllers[0].addEventListener('selectend', () => {
      this.selectState = false;
    });

    this.containerGlobal = undefined;
  }

  generate() {
    console.log('go che');

    const container = new ThreeMeshUI.Block({
      height: 3.3,
      width: 5.2,
      justifyContent: 'center',
      contentDirection: 'row',
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.11,
      backgroundOpacity: 0,
    });
    this.containerGlobal = container;

    container.position.set(-11.65, 1.75, -6.45);
    // container.rotation.y = -0.55;
    this.scene.add(container);

    const ImgButtonOptions = {
      width: 1.2,
      height: 3.2,
      justifyContent: 'center',
      offset: 0.005,
      fontSize: 0.04,
      textAlign: 'center',
      margin: 0.05,
      borderRadius: 0.1,
      borderWidth: 0.015,
      borderColor: new THREE.Color(0x080808),
    };

    const selectImgButtonAttributes = {
      state: 'selected',
      attributes: {
      },
    };

    const optionButton = {
      width: 0.8,
      height: 0.2,
      justifyContent: 'center',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.12,
      offset: 0.01,
      margin: 0.01,
      borderWidth: 0,
      borderRadius: 0.1,
      backgroundOpacity: 0.6,
    };

    this.pageNavButtons = {};

    Object.keys(wallEcomerceImg).forEach((btn, i) => {
      this.pageNavButtons[`ImgButtonRow_${i}`] = new ThreeMeshUI.Block(ImgButtonOptions);
      const item = this.pageNavButtons[`ImgButtonRow_${i}`];
      item.name = `ImgButtonRow_${i}`;

      this.pageNavButtons[`ImgButtonRow_Button_${i}`] = new ThreeMeshUI.Block(optionButton);
      const itemButton = this.pageNavButtons[`ImgButtonRow_Button_${i}`];
      itemButton.name = `ImgButtonRow_Button_${i}`;
      itemButton.interacted = false;

      this.textureLoader.load(wallEcomerceImg[btn], (texture) => {
        item.set({ backgroundTexture: texture });
      });

      const hoveredImgButtonAttributes = {
        state: 'hovered',
        attributes: {
          borderColor: new THREE.Color(0x57A2FF),
        },
        onSet: () => {
          itemButton.visible = true;
        },
      };

      const idleImgButtonAttributes = {
        state: 'idle',
        attributes: {
          borderColor: new THREE.Color(0x080808),
        },
        onSet: () => {
          itemButton.visible = false;
        },
      };

      item.setupState(selectImgButtonAttributes);
      item.setupState(hoveredImgButtonAttributes);
      item.setupState(idleImgButtonAttributes);

      const selectStateButton = {
        state: 'selected',
        attributes: {
          offset: 0.001,
          backgroundColor: new THREE.Color(0x2586FF),
          fontColor: new THREE.Color(0xffffff),
        },
        onSet: () => {
          this.botButton(btn);
        },
      };

      const hoveredStateButton = {
        state: 'hovered',
        attributes: {
          offset: 0.015,
          backgroundColor: new THREE.Color(0x57A2FF),
          backgroundOpacity: 1,
          fontColor: new THREE.Color(0xffffff),
        },
        onSet: () => {
          console.log('pointer');
          this.renderer.domElement.style.cursor = 'pointer';
        },
      };

      const idleStateButton = {
        state: 'idle',
        attributes: {
          offset: 0.01,
          backgroundColor: new THREE.Color(0xffffff),
          backgroundOpacity: 0.6,
          fontColor: new THREE.Color(0x303030),
        },
        onSet: () => {
          console.log('default');
          this.renderer.domElement.style.cursor = 'default';
        },
      };

      itemButton.add(
        new ThreeMeshUI.Text({ content: 'Go to page' }),
      );

      itemButton.setupState(selectStateButton);
      itemButton.setupState(hoveredStateButton);
      itemButton.setupState(idleStateButton);

      item.add(itemButton);
      container.add(item);
      this.objsToTestButton.push(itemButton);
      this.objsToTest.push(item);
    });
  }

  updateButtons() {
    ThreeMeshUI.update();
    if (this.scene.playerWatchingVideo) return;
    // look at

    let intersect;

    if (this.renderer.xr.isPresenting) {
      this.vrControl.setFromController(0, this.raycaster.ray);

      intersect = this.raycast();

      // Position the little white dot at the end of the controller pointing ray
      // if ( intersect ) this.vrControl.setPointerAt( 1, intersect.point );
    } else if (this.mouse.x !== null && this.mouse.y !== null) {
      this.raycaster.setFromCamera(this.mouse, this.realCamObj);

      intersect = this.raycast();
    }

    if (intersect && intersect.object.isUI) {
      if (intersect.distance > 10) return;

      if (this.selectState) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersect.object.setState('selected');
      } else {
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersect.object.setState('hovered');
      }
    }

    this.objsToTest.forEach((obj) => {
      if ((!intersect || obj !== intersect.object) && obj.isUI) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        obj.setState('idle');
      }
    });

    let intersectButton;

    if (this.renderer.xr.isPresenting) {
      this.vrControl.setFromController(0, this.raycaster.ray);

      intersectButton = this.raycastButton();

      // Position the little white dot at the end of the controller pointing ray
      // if ( intersect ) this.vrControl.setPointerAt( 1, intersect.point );
    } else if (this.mouse.x !== null && this.mouse.y !== null) {
      this.raycaster.setFromCamera(this.mouse, this.realCamObj);

      intersectButton = this.raycastButton();
    }

    if (intersectButton && intersectButton.object.isUI) {
      if (intersectButton.distance > 10) return;

      if (this.selectState) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersectButton.object.setState('selected');
      } else {
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersectButton.object.setState('hovered');
      }
    }

    this.objsToTestButton.forEach((obj) => {
      if ((!intersectButton || obj !== intersectButton.object) && obj.isUI) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        obj.setState('idle');
      }
    });
  }

  raycast() {
    return this.objsToTest.reduce((closestIntersection, obj) => {
      const intersection = this.raycaster.intersectObject(obj, true);

      if (!intersection[0]) return closestIntersection;

      if (!closestIntersection || intersection[0].distance < closestIntersection.distance) {
        intersection[0].object = obj;

        return intersection[0];
      }

      return closestIntersection;
    }, null);
  }

  raycastButton() {
    return this.objsToTestButton.reduce((closestIntersection, obj) => {
      const intersection = this.raycaster.intersectObject(obj, true);

      if (!intersection[0]) return closestIntersection;

      if (!closestIntersection || intersection[0].distance < closestIntersection.distance) {
        intersection[0].object = obj;

        return intersection[0];
      }

      return closestIntersection;
    }, null);
  }

  botButton(btn) {
    this.ecommerceEvents.wallEvents(btn);
  }
}

export { WallOfEcommerce };
