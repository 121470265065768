import client from '../client';
import { GetTokenResponseType } from '../../../types/ResponseTypes';

const getDolbyToken = (): Promise<GetTokenResponseType> => {
  return client.post('/token', {
    grant_type: 'client_credentials',
    expires_in: 86400,
  }, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_BASIC_DOLBY || ''}`,
    },
  });
};

export default getDolbyToken;
