import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const CameraIcon: FC<IconsType> = memo((props) => {
  const {
    width = '34',
    height = '34',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.742 28.7869H8.894C4.47 28.7869 3 25.8469 3 22.8929V11.1049C3 6.68094 4.47 5.21094 8.894 5.21094H17.742C22.166 5.21094 23.636 6.68094 23.636 11.1049V22.8929C23.636 27.3169 22.152 28.7869 17.742 28.7869Z"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.528 24.1402L23.636 21.4102V12.5762L27.528 9.84618C29.432 8.51618 31 9.32818 31 11.6662V22.3342C31 24.6722 29.432 25.4842 27.528 24.1402Z"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3 15.5984C17.4597 15.5984 18.4 14.6582 18.4 13.4984C18.4 12.3386 17.4597 11.3984 16.3 11.3984C15.1402 11.3984 14.2 12.3386 14.2 13.4984C14.2 14.6582 15.1402 15.5984 16.3 15.5984Z"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
});

export default CameraIcon;
