import { Quaternion } from 'three';
import InterpolationBuffer from './InterpolationBuffer';

export default class QuaternionBuffer extends InterpolationBuffer<Quaternion> {
  interpolateBetween(t: number, v1: Quaternion, t1: number, v2: Quaternion, t2: number): Quaternion {
    // console.log('interpolateBetween');
    if (!v2) return v1;
    return new Quaternion().slerpQuaternions(v1, v2, (t - t1) / (t2 - t1));
  }

  getQuaternion(t: number, ts: number, position: Quaternion): Quaternion {
    // return this.buffer[0];
    const nexPosition = this.buffer[0];
    // console.log(nexPosition);
    return this.interpolateBetween(t, position, t - ts, nexPosition, t + ts);
  }

  interpolateFuture(t: number, v1: Quaternion, t1: number, v2: Quaternion, t2: number) {
    // console.log('interpolateFuture');
    if (!v2) return v1;
    return v2;
  }
  //
  // interpolatePast(t: number, v1: Vector3, t1: number, v2: Vector3, t2: number) {
  //   return this.interpolateBetween(t, v1, t1, v2, t2);
  // }
}
