import {
  EmojiPicker,
  ChatAutoComplete,
  useMessageInputContext,
} from 'stream-chat-react';
import classNames from './MessageInput.module.css';
import Icon from '../../../../components/Icon/Icon';

const MessageInput = () => {
  const {
    closeEmojiPicker,
    emojiPickerIsOpen,
    handleEmojiKeyDown,
    handleSubmit,
    openEmojiPicker,
  } = useMessageInputContext();

  return (
    <div
      className={classNames.root}
    >
      <div
        className={classNames.emoji}
        onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
        onKeyDown={handleEmojiKeyDown}
        role="button"
        tabIndex={0}
      >
        <Icon
          width={26}
          height={26}
          name="Emoji"
        />
      </div>
      <div className="str-chat__input-flat--textarea-wrapper">
        <div className={classNames.textAreaWrapper}>
          <ChatAutoComplete placeholder=" " />
        </div>
        <EmojiPicker small />
      </div>
      <div
        onClick={handleSubmit}
        className={classNames.buttonSend}
      >
        <Icon
          width={20}
          height={20}
          name="arrowUp"
        />
      </div>
    </div>
  );
};

export default MessageInput;
