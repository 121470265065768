import * as THREE from 'three';
import { Component } from '../../engine/Component';

export class PanelComponent extends Component {
  protected data: THREE.Object3D = new THREE.Object3D();

  static get code(): string {
    return 'panel';
  }
}
