import { FC, useEffect } from 'react';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import UserName from './UserName';
import UserActions from './UserActions';
import classNames from './UnionModal.module.css';
import { AudioContextType } from '../../context/AudioProvider/AudioProviderType';
import useParticipants from '../../hooks/audio/useParticipants';

export interface UserConfigProps {
  addIsSpeakingListener: AudioContextType['addIsSpeakingListener'];
  userName: string;
  participant: Participant;
  participantsStatus: AudioContextType['participantsStatus'];
  isAdmin: boolean;
  onKickParticipant: (item: Participant) => void;
}

const UserConfig: FC<UserConfigProps> = (props) => {
  const {
    isAdmin = false,
    userName,
    participant,
    participantsStatus,
    onKickParticipant = () => undefined,
    addIsSpeakingListener = () => undefined,
  } = props;

  const { isSpeaking } = participantsStatus[participant.id] || {};

  if (VoxeetSDK.conference.current !== null && VoxeetSDK.session.participant) addIsSpeakingListener(participant);

  return (
    <div className={classNames.userConfig}>
      <UserName name={userName} />
      <UserActions
        isSpeaking={isSpeaking}
        onKickParticipant={onKickParticipant}
        participant={participant}
        isAdmin={isAdmin}
      />
    </div>
  );
};

export default UserConfig;
