import * as Three from 'three';
import { transformPoint, getCentroid, getWorldPosition, setQuaternionFromDirection } from './utils';
import { IKConstraint } from './types';
import IKChain from "./IKChain";

const Y_AXIS = new Three.Vector3(0, 1, 0);

export type IKJointOptions = {
  bone: Three.Object3D;
  constraints?: IKConstraint[];
  up?: Three.Vector3;
};

/**
 * A class for a joint.
 */
class IKJoint {
  public constraints: IKConstraint[] = [];

  public bone: Three.Object3D;

  public distance = 0;

  public originalDirection = new Three.Vector3();

  public direction: Three.Vector3 = new Three.Vector3();

  public worldPosition: Three.Vector3 = new Three.Vector3();

  public isSubBase = false;

  public subBasePositions: Three.Vector3[] = [];

  public up: Three.Vector3 = Y_AXIS;

  public isIKJoint = true;

  constructor(options: IKJointOptions) {
    this.constraints = options.constraints || this.constraints;
    this.bone = options.bone;
    this.up = options.up || this.up;
    this.updateWorldPosition();
  }

  setIsSubBase() {
    this.isSubBase = true;
    this.subBasePositions = [];
  }

  /**
   * Consumes the stored sub base positions and apply it as this
   * joint's world position, clearing the sub base positions.
   */
  applySubBasePositions() {
    if (this.subBasePositions.length === 0) {
      return;
    }
    getCentroid(this.subBasePositions, this.worldPosition);
    this.subBasePositions.length = 0;
  }

  applyConstraints(chain: IKChain, method: 'apply' | 'applyLazy' = 'apply') {
    if (!this.constraints) {
      return;
    }

    let constraintApplied = false;
    for (let i = 0; i < this.constraints.length; i++) {
      const constraint = this.constraints[i];
      if (constraint && method in constraint) {
        const call = constraint[method]?.bind(constraint);
        const applied = call ? call(this, chain) : false;
        constraintApplied = constraintApplied || applied;
      }
    }
    return constraintApplied;
  }

  /**
   * Set the distance.
   */
  setDistance(distance: number) {
    this.distance = distance;
  }

  getDirection() {
    return this.direction;
  }

  setDirection(direction: Three.Vector3) {
    this.direction.copy(direction);
  }

  /**
   * Gets the distance.
   */
  getDistance() {
    return this.distance;
  }

  updateMatrixWorld() {
    this.bone.updateMatrixWorld(true);
  }

  getWorldPosition() {
    return this.worldPosition;
  }

  getWorldDirection(joint: IKJoint) {
    return new Three.Vector3().subVectors(this.getWorldPosition(), joint.getWorldPosition()).normalize();
  }

  updateWorldPosition() {
    getWorldPosition(this.bone, this.worldPosition);
  }

  setWorldPosition(position: Three.Vector3) {
    this.worldPosition.copy(position);
  }

  localToWorldDirection(direction: Three.Vector3) {
    if (this.bone.parent) {
      const parent = this.bone.parent.matrixWorld;
      direction.transformDirection(parent);
    }
    return direction;
  }

  worldToLocalDirection(direction: Three.Vector3) {
    if (this.bone.parent) {
      const inverseParent = new Three.Matrix4().copy(this.bone.parent.matrixWorld).invert();
      direction.transformDirection(inverseParent);
    }
    return direction;
  }

  applyWorldPosition() {
    const direction = new Three.Vector3().copy(this.direction);
    const position = new Three.Vector3().copy(this.getWorldPosition());

    const { parent } = this.bone;

    if (parent) {
      this.updateMatrixWorld();
      const inverseParent = new Three.Matrix4().copy(parent.matrixWorld).invert();
      transformPoint(position.clone(), inverseParent, position);
      this.bone.position.copy(position);

      this.updateMatrixWorld();

      this.worldToLocalDirection(direction);
      setQuaternionFromDirection(direction, this.up, this.bone.quaternion);
    } else {
      this.bone.position.copy(position);
    }

    // Update the world matrix so the next joint can properly transform
    // with this world matrix
    this.bone.updateMatrix();
    this.updateMatrixWorld();
  }

  getWorldDistance(joint: IKJoint | Three.Object3D) {
    return this.worldPosition.distanceTo((<IKJoint>joint).isIKJoint
      ? (<IKJoint>joint).getWorldPosition() : getWorldPosition(joint as Three.Object3D, new Three.Vector3()));
  }
}

export default IKJoint;
