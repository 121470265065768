import * as Three from 'three';
import { IKSystem } from './IKSystem';
import IKJoint from './IKJoint';

/**
 * Mesh for representing an IKJoint.
 */
class BoneHelper extends Three.Object3D {
  public boneMesh: Three.Object3D;

  public axesHelper: Three.AxesHelper;

  constructor(height: number, boneSize?: number, axesSize?: number) {
    super();

    // If our bone has 0 height (like an end effector),
    // use a dummy Object3D instead, otherwise the ConeBufferGeometry
    // will fall back to its default and not use 0 height.
    if (height !== 0) {
      const geo = new Three.ConeBufferGeometry(boneSize, height, 4);
      geo.applyMatrix4(new Three.Matrix4().makeRotationAxis(new Three.Vector3(1, 0, 0), Math.PI / 2));
      // geo.applyMatrix4(new Three.Matrix4().makeRotationAxis(new Three.Vector3(0, -1, 0), Math.PI / 2));
      this.boneMesh = new Three.Mesh(geo, new Three.MeshBasicMaterial({
        color: 0xff0000,
        wireframe: true,
        depthTest: false,
        depthWrite: false,
      }));
    } else {
      this.boneMesh = new Three.Object3D();
    }

    // Offset the bone so that its rotation point is at the base of the bone
    this.boneMesh.position.z = height / 2;
    this.add(this.boneMesh);

    this.axesHelper = new Three.AxesHelper(axesSize);
    this.add(this.axesHelper);
  }
}

export type IKHelperConfig = {
  color?: Three.Color;
  showBones?: boolean;
  boneSize?: number;
  showAxes?: boolean;
  axesSize?: number;
  wireframe?: boolean;
};

/**
 * Class for visualizing an IK system.
 */
class IKHelper extends Three.Object3D {
  public ik: IKSystem;

  public _meshes: Map<IKJoint, BoneHelper>;

  public _showBones = true;

  public _showAxes = true;

  public _wireframe = false;

  public _color = new Three.Color(1, 0, 0);

  constructor(ik: IKSystem, {
    color, showBones, boneSize, showAxes, axesSize, wireframe,
  }: IKHelperConfig = {}) {
    super();

    boneSize = boneSize || 0.05;
    axesSize = axesSize || 0.1;

    if (!(<IKSystem>ik).isIKSystem) {
      throw new Error('IKHelper must receive an IK instance.');
    }

    this.ik = ik;

    this._meshes = new Map();

    this.ik.chains.forEach((rootChain) => {
      const chainsToMeshify = [rootChain];
      while (chainsToMeshify.length) {
        const chain = chainsToMeshify.shift();
        if (chain) {
          for (let i = 0; i < chain.joints.length; i++) {
            const joint = chain.joints[i];
            const nextJoint = chain.joints[i + 1];
            const distance = nextJoint ? nextJoint.distance : 0;

            // If a sub base, don't make another bone
            if (chain.base === joint && chain !== rootChain) {
              // eslint-disable-next-line no-continue
              continue;
            }
            const mesh = new BoneHelper(distance, boneSize, axesSize);
            mesh.matrixAutoUpdate = false;
            this._meshes.set(joint, mesh);
            this.add(mesh);
          }
          chain.chains.forEach((subChains) => {
            subChains.forEach((subChain) => {
              chainsToMeshify.push(subChain);
            });
          });
        }
      }
    });

    /**
     * Whether this IKHelper's bones are visible or not.
     */
    this.showBones = showBones !== undefined ? showBones : true;

    /**
     * Whether this IKHelper's axes are visible or not.
     */
    this.showAxes = showAxes !== undefined ? showAxes : true;

    /**
     * Whether this IKHelper should be rendered as wireframes or not.
     */
    this.wireframe = wireframe !== undefined ? wireframe : true;

    /**
     * The color of this IKHelper's bones.
     */
    this.color = color || new Three.Color(0xff0077);
  }

  get showBones() { return this._showBones; }

  set showBones(showBones) {
    if (showBones === this._showBones) {
      return;
    }
    this._meshes.forEach((mesh, joint) => {
      if (showBones) {
        mesh.add(mesh.boneMesh);
      } else {
        mesh.remove(mesh.boneMesh);
      }
    });
    this._showBones = showBones;
  }

  get showAxes() { return this._showAxes; }

  set showAxes(showAxes) {
    if (showAxes === this._showAxes) {
      return;
    }
    this._meshes.forEach((mesh, joint) => {
      if (showAxes) {
        mesh.add(mesh.axesHelper);
      } else {
        mesh.remove(mesh.axesHelper);
      }
    });
    this._showAxes = showAxes;
  }

  get wireframe() { return this._wireframe; }

  set wireframe(wireframe) {
    if (wireframe === this._wireframe) {
      return;
    }
    this._meshes.forEach((mesh, joint) => {
      if ((mesh.boneMesh as Three.Mesh).material) {
        ((mesh.boneMesh as Three.Mesh).material as Three.MeshBasicMaterial).wireframe = wireframe;
      }
    });
    this._wireframe = wireframe;
  }

  get color() { return this._color; }

  set color(color) {
    if (this._color && this._color.equals(color)) {
      return;
    }
    color = (color && color.isColor) ? color : new Three.Color(color);
    this._meshes.forEach((mesh, joint) => {
      if ((mesh.boneMesh as Three.Mesh).material) {
        ((mesh.boneMesh as Three.Mesh).material as Three.MeshBasicMaterial).color = color;
      }
    });
    this._color = color;
  }

  updateMatrixWorld(force: boolean) {
    this._meshes.forEach((mesh, joint) => {
      mesh.matrix.copy(joint.bone.matrixWorld);
    });
    super.updateMatrixWorld(force);
  }
}

export default IKHelper;
