import * as Three from 'three';
import { Application } from '../Application';

export abstract class AbstractScene {
  protected _threeScene: Three.Scene = new Three.Scene();

  public get threeScene(): Three.Scene {
    return this._threeScene;
  }

  public abstract load(app: Application): Promise<void>;

  public abstract destroy(app: Application): Promise<void>;

  public render(app: Application, delta: number): void {
    if (app.camera && this.threeScene) {
      app.renderer.render(this.threeScene, app.camera);
    }
  }
}
