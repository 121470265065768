export const forDistRayCast = [
  'room1_bigscreen',
  'room2_bigscreen',
  'room3_tvscreen',
  'room4_bigscreen1',
  'room4_bigscreen2',
  'room4_tv1',
  'room2_wood',
  'room4_wood',
  'room4_concrete2',
  'room0_concrete2',
  'room0_black',
  'room4_wallcolor',
  'room3_walls', 'room1_walls',
];
