import { createElement, FC } from 'react';
import { IconsType } from '../Icons/IconsType';
import { Icons } from '../Icons/Icons';

export interface IconProps extends IconsType {
  name: string;
}

const Icon: FC<IconProps> = (props) => {
  const {
    width,
    height,
    color,
    name,
  } = props;

  const iconComponentName = Object.keys(Icons)
    .find((icon) => icon === `${name[0].toUpperCase()}${name.slice(1)}Icon`) || 'CloseIcon';
  return createElement(Icons[iconComponentName], { width, height, color });
};

export default Icon;
